import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { DxTreeListComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { District } from '../../dtos/district';
import { GlobalService } from '../../services/global.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';

@Component({
  selector: 'js-districts',
  templateUrl: './districts.component.html',
  styleUrls: ['./districts.component.scss']
})
export class DistrictsComponent implements OnInit, OnDestroy {
  treeGrid: DxTreeListComponent;
  selectedRowKeys: any[] = [];
  addingRow: any;
  treeListDataSource: any = {};
  loading = false;
  subscriptions: Subscription[] = [];
  popupVisible = false;
  helpPopupVisible = false;
  districts: District[];
  updateOptionLists = false;
  centralCompanyDistricts: District[];

  @ViewChild('treeList') treeList: DxTreeListComponent;

  constructor(private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private globalService: GlobalService) {
    this.onReorder = this.onReorder.bind(this);
  }

  ngOnInit() {
    this.getCentralCompanyDistricts();
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setUpDataSource() {
    this.treeListDataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.getDistricts(false).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.addDistrict(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.updateDistrict(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.deleteDistrict(encodeURIComponent(key)).subscribe({
              next: (res) => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      }
    });
  }

  cellPrepared(e) {
    if (e.column.command === 'edit') {
      const addLink = e.cellElement.querySelector('.dx-link-add');

      if (addLink) {
        addLink.remove();
      }
    }
  }

  editorPreparing(e) {
    if (e.dataField === 'districtParentId' && e.row.data.ID === 1) {
      e.editorOptions.disabled = true;
      e.editorOptions.value = null;
    }
  }

  initNewRow = (e) => {
    if (this.addingRow) {
      e.data = this.addingRow;
    }
  }

  addGroup = (e) => { // need to declare funtions in this manner so they still have access to the component context (i.e. 'this')
    this.addingRow = { districtParentId: e.row.key }; // can add in other fields here if coming back from a modal
    e.component.addRow(e.row.key);
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();

    let toNode = visibleRows[e.toIndex].data;

    if (e.dropInsideItem) {
      this.subscriptions.push(
        this.maintenanceService.moveDistrict(e.itemData.id, toNode.id, 1).subscribe({
          next: () => {
            e.component.refresh();
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );

    } else {
      let targetIndex = e.toIndex;
      let newDistrictParentId = toNode.districtParentId;
      let newOrderNumber = toNode.orderNumber;

      if (e.toIndex > e.fromIndex) {
        targetIndex++;
        if (visibleRows[targetIndex]) {
          toNode = visibleRows[targetIndex].data;
          if (e.itemData.districtParentId !== toNode.districtParentId) {
            // || (visibleRows[targetIndex + 1] && visibleRows[targetIndex + 1].districtParentId !== toNode.districtParentId)) {
            toNode = visibleRows[targetIndex].data;
            newDistrictParentId = toNode.districtParentId;
            newOrderNumber = toNode.orderNumber;
          }

        }
      }

      this.subscriptions.push(
        this.maintenanceService.moveDistrict(e.itemData.id, newDistrictParentId, newOrderNumber).subscribe({
          next: () => {
            this.treeList.instance.refresh();
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  toggleHelp() {
    this.helpPopupVisible = true;
  }

  getCentralCompanyDistricts() {
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.CentralCompanyActive)) {
      this.subscriptions.push(
        this.maintenanceService.getCentralCompanyDistricts().subscribe({
          next: (res) => {
            this.centralCompanyDistricts = res;
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }
}
