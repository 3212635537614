<div class="modal-header">
  <h2 class="modal-title">{{recipe.description}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  Enter the quantity for the items you want to add and click save
  <dx-data-grid #lookupDataGrid id="lookupDataGrid" [dataSource]="recipeData" [remoteOperations]="false" [height]="gridHeight"
    [showBorders]="true" [focusedRowEnabled]="false" [wordWrapEnabled]="true" [allowColumnReordering]="true"
    [rowAlternationEnabled]="true" [allowColumnResizing]="true" [hoverStateEnabled]="true"
    [(selectedRowKeys)]="selectedRowKeys" [scrolling]="{ mode: 'virtual', preloadEnabled: true }"
    (onSaving)="addRecipesFromListGo($event)">

    <dxo-editing mode="batch" [allowUpdating]="true" startEditAction="click">
    </dxo-editing>

    <dxo-keyboard-navigation [editOnKeyPress]="true" enterKeyAction="moveFocus" enterKeyDirection="column">
    </dxo-keyboard-navigation>

    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-group-panel [visible]="false"></dxo-group-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true" [width]="250"></dxo-search-panel>
    <dxo-grouping #expand [autoExpandAll]="true" [contextMenuEnabled]="false" expandMode="rowClick">
    </dxo-grouping>

    <dxi-column dataField="masterGroupCostCentre" dataType="string" caption="" [groupIndex]="0" [width]="200"
      groupCellTemplate="groupCellTemplate">
    </dxi-column>

    <dxi-column dataField="subGroupItemDesc" dataType="string" caption="" [groupIndex]="1" [width]="220"
      groupCellTemplate="groupCellTemplate">
    </dxi-column>

    <dxi-column dataField="recipeCode" caption="Code" [width]="140" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="description" [allowEditing]="false" cellTemplate="wrapTemplate"
      [minWidth]="400"></dxi-column>

    <dxi-column dataField="comment" [allowEditing]="false" cellTemplate="wrapTemplate"></dxi-column>

    <dxi-column dataField="quantity" [width]="100" [allowFiltering]="false"></dxi-column>

    <dxi-column dataField="unitOfMeasure" caption="Units" [width]="100" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="rate" dataType="number" [width]="100" [format]="{ type: 'fixedPoint', precision: 2 }"
      [allowEditing]="false">
    </dxi-column>

    <dxi-column dataField="scale" [visible]="false"></dxi-column>

    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>

    <div *dxTemplate="let cellInfo of 'wrapTemplate'">
      <div id="wrapComment" class='mx-0 px-0'>{{cellInfo.value}}</div>
    </div>
  </dx-data-grid>
</div>

<!-- <div class="modal-footer">
  <button class="btn btn-sm btn-primary" (click)="close()">Close</button>
</div> -->

<!-- loading spinner - DevExtreme -->
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [(visible)]="loading" [showIndicator]="true" [showPane]="true"
  [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
