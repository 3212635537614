import { environment } from './../../environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserTypeEnum } from '../dtos/user-type.enum';
import { Company } from '../dtos/company';
import { User } from '../dtos/user';
import { AuthService } from '../services/auth.service';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/felixApi/user.service';
import { NotificationService } from '../services/notification.service';
import { CompanyService } from '../services/felixApi/company.service';
import { LogService } from '../services/log.service';
import { AuthApiService } from '../services/felixApi/auth-api.service';
import { ConfigurationEnum } from '../dtos/configuration-enum';


@Component({
  selector: 'js-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  COMPONENT_NAME = 'welcome';
  userTypeEnum = UserTypeEnum;
  userCompanies: Company[];
  currComp: Company;
  currUser: User;
  currUserEmail: string;
  multipleCompanies = false;
  loading = true;
  innerWidth: number;
  compForm: UntypedFormGroup;
  subscriptions: Subscription[] = [];
  officeUser = false;
  currentVersion: string;
  isSelectionsAdmin: boolean;
  admin = false;
  trackingSystemActive: boolean;
  companySelectWidth: number;
  selectedCompanyId: number;
  isProduction: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private auth: AuthService,
    private authApi: AuthApiService,
    private globalService: GlobalService,
    private notiService: NotificationService,
    private authService: AuthService,
    private compService: CompanyService,
    private logService: LogService) {

    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(width => {
        this.setWidths();
      })
    );
  }

  ngOnInit() {
    this.setWidths();
    this.innerWidth = this.globalService.innerWidth;
    this.globalService.setAreaSelected('');

    this.currentVersion = this.authService.currentVersion;

    this.currComp = this.globalService.getCurrentCompany();
    this.selectedCompanyId = this.currComp?.id;
    this.currUserEmail = this.auth.getUserEmail();

    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.TrackingSystemActive) === 1) {
      this.trackingSystemActive = true;
    }

    if (!this.currUserEmail) {
      this.auth.signOut();
    } else {
      this.currUser = this.auth.getCurrentUser();
      if ((this.currComp && this.currComp.userTypeId === this.userTypeEnum.Admin) || (this.currUser && this.currUser.isSuperUser)) {
        this.admin = true;
        this.officeUser = true;
      } else if (this.currComp && this.currComp.userTypeId === this.userTypeEnum.Office) {
        this.officeUser = true;
      }

      this.getCompanies();
    }
  }

  companySelected(e) {
    this.selectedCompanyId = e.value;
    if (this.selectedCompanyId) {
      this.currComp = this.userCompanies.find(i => i.id === this.selectedCompanyId);
      this.compSelected();
    }
  }

  setWidths() {
  this.innerWidth = this.globalService.innerWidth;
  if (this.innerWidth > 400) {
    this.companySelectWidth = 360;
  } else {
    this.companySelectWidth = this.innerWidth - 40;
  }
  this.isProduction = environment.production;
}


  getCompanies() {
    this.userCompanies = this.compService.getCurrentCompanies();
    if (this.userCompanies) {
      this.populateCompanies();
    } else {
      this.subscriptions.push(
        this.authApi.getAuthorisedCompanies().subscribe({
          next: (companies) => {
            this.userCompanies = companies;
            this.populateCompanies();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.currUser = null;
            this.loading = false;
            setTimeout(() => {
              this.auth.signOut();
            }, 5000);
          }
        })
      );
    }
  }

  populateCompanies() {
    this.compService.setCurrentCompanies(this.userCompanies);
    this.loading = false;
    if (this.userCompanies.length === 1) {
      this.multipleCompanies = false;
      this.currComp = this.userCompanies[0];
      this.selectedCompanyId = this.currComp?.id;
      if (!this.auth.signedIn) {
        this.compSelected();
      }
    } else {
      this.multipleCompanies = true;
    }
  }

  compSelected() {
    this.loading = true;
    this.globalService.menuDisable.emit(true);
    this.globalService.setCurrentCompany(this.currComp);

    // get the user master - only have all user info once company is selected
    // only then do we fully sign in
    this.subscriptions.push(
      this.userService.getUser(this.currComp.userId).subscribe({
        next: (currUser) => {
          this.currUser = currUser;
          this.auth.setCurrentUser(this.currUser);
          this.auth.signIn();
          if (this.currComp.userTypeId === this.userTypeEnum.Admin) {
            this.admin = true;
          }
          this.writeLoginLog();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.currUser = null;
          this.globalService.menuDisable.emit(false);
          this.loading = false;
        }
      })
    );
  }

  writeLoginLog() {
    this.subscriptions.push(
      this.logService.loginLog(this.auth.getUserEmail(), true, 'Company ' + this.currComp.id.toString()).subscribe({
        next: () => {
          this.checkPermsAndRun();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.checkPermsAndRun();
        }
      })
    );
  }

  checkPermsAndRun() {
    this.subscriptions.push(
      this.compService.getCompanyConfigurations().subscribe({
        next: (companyConfigurations) => {
          this.globalService.companyConfigurations = companyConfigurations;

          if (this.currComp.userTypeId === this.userTypeEnum.Admin
            || this.auth.getCurrentUser().isSuperUser
            || this.authService.getSelectionsPermissions('Selections') === 'Admin'
            || this.authService.getSelectionsPermissions('Recipes') === 'Read'
            || this.authService.getSelectionsPermissions('Recipes') === 'Write'
            || this.authService.getSelectionsPermissions('Recipes') === 'Admin') {
            // this.admin = true;
            this.globalService.menuDisable.emit(false);

            this.globalService.setAreaSelected('recipes');
            this.router.navigateByUrl('/recipes');
          } else if (this.authService.getSelectionsPermissions('OptionLists') === 'Admin'
            || this.authService.getSelectionsPermissions('CallUps') === 'Write'
            || this.authService.getSelectionsPermissions('CallUps') === 'Admin') {
            // supervisors can have access to the vendor list - OptionLists Admin perm needed for update
            this.globalService.menuDisable.emit(false);

            this.globalService.setAreaSelected('maintenance');
            this.router.navigateByUrl('/maintenance');
          } else {
            this.notiService.showError('You have no permissions for this application. Please see your administrator');
            setTimeout(() => {
              this.auth.signOut();
            }, 5000);
          }
        },
        error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  compareComps(comp1: Company, comp2: Company) {
    if (!comp1 || !comp2) {
      return false;
    } else {
      return comp1.id === comp2.id;
    }
  }

  signOut() {
    this.loading = true;
    this.auth.signOut();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  launchAdminPortal() {
    window.open(environment.adminAppUrl);
  }

  launchTruthEngine() {
    window.open(environment.addendaAppUrl);
  }

  launchCosting() {
    window.open(environment.costingAppUrl);
  }

  launchTracking() {
    window.open(environment.trackingAppUrl);
  }

  launchConfig() {
    window.open(environment.configAppUrl);
  }

  clearCache() {
    localStorage.clear();
    this.notiService.showInfo('Cache cleared.');
    setTimeout(() => {
      this.auth.signOut();
    }, 5000);
  }
}
