import { AuthService } from './../services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MaintenanceHelperService } from './maintenance-helper.service';
import { Subscription } from 'rxjs';
import { CompanyService } from '../services/felixApi/company.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'js-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  sections: string[];
  priceFileAdmin: boolean;

  constructor(public helper: MaintenanceHelperService,
    private compService: CompanyService,
    private notiService: NotificationService,
    private authService: AuthService) { }

  ngOnInit() {
    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('PriceFile') === 'Admin') {
      this.priceFileAdmin = true;
    } else {
      this.helper.sections = ['Vendors'];
    }
    this.helper.selectedSection = 'Vendors';

    this.getCompanyConfigurations();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  tabSelected(e) {
    this.helper.selectedSection = e.itemData;
  }

  getCompanyConfigurations() {
    // this will run on refesh twice - once without the user Id set so we ignore
    if (this.authService.getUserId()) {
      this.subscriptions.push(
        this.compService.getCompanyConfigurations().subscribe({
          next: () => {
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }
}
