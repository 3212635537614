import { NotificationService } from './../../services/notification.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { Company } from '../../dtos/company';
import { AuthService } from '../../services/auth.service';
import { GlobalService } from '../../services/global.service';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { CompanyService } from '../../services/felixApi/company.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'js-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  subscriptions: Subscription[] = [];
  public isCollapsed = true;
  signedIn = false;
  currComp: Company;
  areaSelected = '';
  superUser = false;
  admin = false;
  disabledMenu = false;
  phoneSize: boolean;
  userTypeEnum = UserTypeEnum;
  companyConfiguration: CompanyConfiguration[];
  innerWidth: number;
  priceFileAdmin: boolean;
  recipesRead: boolean;
  priceFileRead: boolean;

  constructor(
    private globalService: GlobalService,
    private auth: AuthService,
    public authService: AuthService,
    private cd: ChangeDetectorRef,
    private compService: CompanyService,
    private notiService: NotificationService
  ) {
    this.subscribeToLoginChanges();
    this.subscribeToCompanyChanges();
    this.subscribeToAreaChanges();
    this.subscribeToMenuDisable();
    this.subscribeToInnerWidth();
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  menuPress(area) {
    this.globalService.setAreaSelected(area);
  }

  subscribeToAreaChanges() {
    this.subscriptions.push(
      this.globalService.areaChanged.subscribe(area => {
        this.areaSelected = area;
      })
    );
  }

  subscribeToLoginChanges() {
    this.subscriptions.push(
      this.auth.isLoggedInChanged.subscribe(
        (isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            this.signedIn = false;
            this.disabledMenu = true;
          } else {
            this.signedIn = true;
            this.superUser = this.authService.isSuperUser();
            this.getCompanyConfigurations();
            this.cd.detectChanges();  // update view
          }
        })
    );
  }

  subscribeToCompanyChanges() {
    this.subscriptions.push(
      this.globalService.companySelected.subscribe(selectedComp => {
        this.currComp = selectedComp;
        this.admin = this.authService.isAdminOrSuper();
        this.getCompanyConfigurations();
      })
    );
  }

  subscribeToMenuDisable() {
    this.subscriptions.push(
      this.globalService.menuDisable.subscribe(
        disable => {
          if (disable) {
            this.disabledMenu = true;
          } else {
            this.disabledMenu = false;
          }
        }
      ));
  }

  subscribeToInnerWidth() {
    this.subscriptions.push(
      this.globalService.innerWidthChanged.subscribe(
        width => {
          this.innerWidth = width;
        }
      ));
  }

  getCompanyConfigurations() {
    // this will run on refesh twice - once without the user Id set so we ignore
    if (this.auth.getUserId()) {
      this.subscriptions.push(
        this.compService.getCompanyConfigurations().subscribe({ next:
          (configs) => {
            this.globalService.companyConfigurations = configs;

            if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Recipes') !== 'none') {
              this.recipesRead = true;
            }

            if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('PriceFile') !== 'none') {
              this.priceFileRead = true;
            }

            if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('PriceFile') === 'Admin') {
              this.priceFileAdmin = true;
            }
          },
          error: (err) => {
            this.notiService.notify(err);
          }})
      );
    }
  }

  dropdownItems = [
    {
      text: 'Core',
      onClick: () => this.launchCore(),
      icon: 'assets//tlogos/main.png'
    },
    {
      text: 'Tracking',
      onClick: () => this.launchTracking(),
      icon: 'assets//tlogos/tracking.png'
    },
    {
      text: 'Costing',
      onClick: () => this.launchPurchaseOrders(),
      icon: 'assets//tlogos/costing.png'
    },
    {
      text: 'Config',
      onClick: () => this.launchConfig(),
      icon: 'assets//tlogos/config.png'
    },
    {
      visible: this.authService.isAdminOrSuper,
      text: 'Admin',
      onClick: () => this.launchAdminPortal(),
      icon: 'assets//tlogos/admin.png'
    }
  ];

  launchAdminPortal() {
    window.open(environment.adminAppUrl);
  }

  launchConfig() {
    window.open(environment.configAppUrl);
  }

  launchTracking() {
    window.open(environment.trackingAppUrl);
  }

  launchCore() {
    window.open(environment.addendaAppUrl);
  }

  launchPurchaseOrders() {
    window.open(environment.ordersAppUrl);
  }

}

