<div class="modal-header">
  <h2 class="modal-title">Find where used</h2>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dx-data-grid [dataSource]="dataSource" [showBorders]="true" [rowAlternationEnabled]="true" [wordWrapEnabled]="true"
    [(selectedRowKeys)]="selectedRowKeys" [ngStyle]="{'max-height': 'calc(' + gridMaxHeight + 'px)'}" (onExporting)="gridService.onExporting($event, 'Find')">

    <dxo-search-panel [visible]="true" [width]="200"></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxo-editing mode='row' [allowAdding]="false" [allowUpdating]="true" [allowDeleting]="false" [useIcons]="true"
      refreshMode='full'>
    </dxo-editing>

    <dxi-column dataField="recipeLineId" dataType="number" [visible]='false'> </dxi-column>
    <dxi-column dataField="recipeCode" dataType="string" caption="Code" [width]="150" sortOrder="asc"
      [allowEditing]="false"> </dxi-column>
    <dxi-column dataField="description" dataType="string" [allowEditing]="false"> </dxi-column>
    <dxi-column dataField="quantity" format="#,##0.00##" [width]="120"> </dxi-column>

    <dxi-column type="buttons" [width]="120">
      <dxi-button [visible]="isEditVisible" name="edit"></dxi-button>
      <dxi-button [visible]="isLockVisible" icon="lock"></dxi-button>
    </dxi-column>
  </dx-data-grid>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary" (click)="select()" [disabled]="!selectedRowKeys.length">Select</button>
</div>
