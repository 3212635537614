<div class="page">

  <!-- <js-loading-spinner *ngIf="loading"></js-loading-spinner> -->

  <dx-data-grid id="dataGrid" class="max-w-800 m-auto qtyGridHeight" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onExporting)="gridService.onExporting($event, 'Roundings')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="repaint">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-export [enabled]="true"></dxo-export>

    <div *dxTemplate="let data of 'refreshTemplate'">
      <div>
        <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
      </div>
    </div>

    <dxi-column dataField="description" minWidth="200">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="prevUnitOfMeasureId" caption="Units" [width]="110" [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="unitsOfMeasure" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="min" dataType="number"> </dxi-column>

    <dxi-column dataField="max" dataType="number"> </dxi-column>

    <dxi-column dataField="increment" dataType="number"> </dxi-column>

    <dxi-column *ngIf="centralCompanyQtySizeControls" dataField="centralCompanyId" dataType="number"
      caption="Central Company Rounding Item" [width]="140" [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="centralCompanyQtySizeControls" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>
  </dx-data-grid>

  <div class="max-w-800 m-auto notesClass">
    <div>Note:</div>
    <div class="ml-2">Recipes do not use rounding so that mini-bills cost correctly.</div>
    <div class="ml-2">Construction orders will use rounding.</div>
    <div class="ml-2">For items with lengths the rounding applies to the length.</div>
  </div>
</div>
