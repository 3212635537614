import { AuthService } from './../services/auth.service';
import { RecipeLineLength } from './../dtos/recipe-line-length';
import { Component, OnInit, Input, OnDestroy, OnChanges, ViewChild, Output, EventEmitter, HostListener } from '@angular/core';
import { EstimatingService } from '../services/felixApi/estimating.service';
import { NotificationService } from '../services/notification.service';
import { Subscription } from 'rxjs';
import { RecipeLine } from '../dtos/recipe-line';
import { GlobalService } from '../services/global.service';
import { PriceFileService } from '../services/felixApi/price-file.service';
import { RecipePriceFileItem } from '../dtos/recipe-pricefile-item';
import { Recipe } from '../dtos/recipe';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { PriceFileItemTypeEnum } from '../dtos/price-file-item-type.enum';
import { District } from '../dtos/district';
import { Phase } from '../dtos/phase';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver';
import { DxDataGridComponent } from 'devextreme-angular';
import mathString from 'math-string';
import { UnitOfMeasure } from '../dtos/unitOfMeasure';
import { AddMultipleItemsComponent } from './add-multiple-items/add-multiple-items.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubRecipeComponent } from './sub-recipe/sub-recipe.component';
import { Workbook } from 'exceljs';
import { ExplodeInMemoryComponent } from './explode-in-memory/explode-in-memory.component';
import { UploadRecipeLinesComponent } from './upload-recipe-lines/upload-recipe-lines.component';
import { GlobalVariablesService } from '../services/global-variables.service';
import { GridService } from '../services/grid.service';
import { UserService } from '../services/felixApi/user.service';
import { User } from '../dtos/user';
import CustomStore from 'devextreme/data/custom_store';

@Component({
  selector: 'js-recipe-lines',
  templateUrl: './recipe-lines.component.html',
  styleUrls: ['./recipe-lines.component.scss']
})
export class RecipeLinesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() recipe: Recipe;
  @Input() isSubRecipe: boolean;

  @Output() refresh: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild('recipeLineGrid') dataGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: CustomStore;
  recipeData: CustomStore;
  dropDownOptions: object;
  refreshMode: string;
  recipePriceFileItems: RecipePriceFileItem[];
  currentDistrict: District;
  priceFileItemTypeEnum = PriceFileItemTypeEnum;
  itemToExplode: number;
  popupVisible: boolean;
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: 'Default', description: 'Default', isShowOnPO: false, centralCompanyId: null }];
  rowKey: any;
  gridString: string;
  lengthPopupVisible = false;
  lengthsDataSource: CustomStore;
  recipeLineId: number;
  hasSizes: boolean;
  recipeLineQuantity: any;
  recipeLineDescription: any;
  unitOfMeasures: UnitOfMeasure[];
  descriptionWidth: number;
  showCalcSelling: boolean;
  recipeParentId: number;
  explodeRecipePopupVisible: boolean;
  explodeTitle: string;
  gridMaxHeight: number;
  isGrouped = true;
  recipesWrite = false;
  recipeDataActiveOnly: CustomStore;
  selectedLineKeys: number[] = [];
  deletePopupVisible: boolean;
  editMode = 'row';
  quantityStringWidth = 120;
  quantityForExplode: any;
  recipeItemId: number;
  ignoreEmptyRecipes: boolean;
  recalcSellingPricePopupVisible: boolean;
  loading: boolean;
  updateOptionLists: boolean;
  reCostingDate: Date;
  lengthKeyId = 0; // in case we add lengths
  users: User[];

  @HostListener('window:resize') onResize() {
    this.setWidths();
  }

  constructor(private estimatingService: EstimatingService,
    private authService: AuthService,
    private priceFileService: PriceFileService,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private modalService: NgbModal,
    private globalService: GlobalService,
    public globalVariablesService: GlobalVariablesService,
    private userService: UserService,
    public gridService: GridService) {
    this.setQtyCellValue = this.setQtyCellValue.bind(this);
    this.setRecipeCellValue = this.setRecipeCellValue.bind(this);
    this.onEditingStart = this.onEditingStart.bind(this);
    this.calculateLineTotal = this.calculateLineTotal.bind(this);
    this.addMultipleItems = this.addMultipleItems.bind(this);
    this.openRecipe = this.openRecipe.bind(this);
    this.getRate = this.getRate.bind(this);
    this.getLookupRate = this.getLookupRate.bind(this);

    this.refreshMode = 'full'; // ['full', 'reshape', 'repaint']
    this.dropDownOptions = { width: 900, minHeight: 540 };
  }

  ngOnInit() {
    this.users = this.userService.users;

    this.subscriptions.push(
      this.globalService.recipeLinesUpdated.subscribe(id => {
        if (id === this.recipe.id) {
          this.setUpDataSet();
        }
      })
    );

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Recipes') === 'Write'
      || this.authService.getSelectionsPermissions('Recipes') === 'Admin') {
      this.recipesWrite = true;
    }
  }

  ngOnChanges() {
    this.setWidths();
    this.setupData();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  setWidths() {
    this.descriptionWidth = window.innerWidth < 1900 ? 350 : window.innerWidth - 1550;
    if (!this.isGrouped) {
      this.descriptionWidth -= 302;
    }
    this.gridMaxHeight = window.innerHeight - 350;

    this.quantityStringWidth = window.innerWidth < 2000 ? 120 : window.innerWidth < 2400 ? 240 : 360;
  }

  setupData() {
    this.currentDistrict = this.maintenanceService.districts.find(i => i.id === this.recipe.districtId);
    this.phases = this.phases.concat(this.maintenanceService.phases);
    this.unitOfMeasures = this.maintenanceService.unitOfMeasures;
    this.getAllRecipeLines();
  }

  getAllRecipeLines() {
    if (this.estimatingService.recipeLines && this.estimatingService.recipeLines.length) {
      this.getAllPriceFileItemVendorRates();
    } else {
      this.subscriptions.push(
        this.estimatingService.getRecipeLines(true)
          .subscribe({
            next: () => {
              this.getAllPriceFileItemVendorRates();
            },
            error: (err) => {
              this.notiService.notify(err);
            }
          })
      );
    }
  }

  getAllPriceFileItemVendorRates() {
    // we create the dropdown options

    // set up entries for group headings
    this.estimatingService.recipeLines.filter(i => i.recipeId === this.recipe.id).forEach(recipeLine => {
      if (recipeLine.recipeItemId) {
        recipeLine.masterGroupCostCentre = '000000;RECIPES';
        const recipe = this.estimatingService.recipes.find(i => i.id === recipeLine.recipeItemId);
        if (recipe) {
          const recipeParent = this.estimatingService.recipeGroups.find(i => i.id === recipe.recipeParentId);
          if (recipeParent) {
            recipeLine.subGroupItemDesc = '000000;' + recipeParent.description; // sort aphabetically
          } else {
            recipeLine.subGroupItemDesc = '000000;Not Found';
          }
        } else {
          recipeLine.subGroupItemDesc = '000000;Not Found';
        }
      } else if (recipeLine.priceFileItemId) {
        const priceFileItem = this.priceFileService.priceFileItems.find(i => i.id === recipeLine.priceFileItemId);

        const subGroup = this.priceFileService.priceFileItemGroups.find(i => i.id === priceFileItem?.priceFileItemParentId);
        const subGroupDesc = subGroup?.priceFileCode ? subGroup.priceFileCode + ' - ' + subGroup.description : subGroup?.description;

        recipeLine.subGroupItemDesc = ('00000' + subGroup?.orderNumber.toString()).slice(-6) + ';' + subGroupDesc;

        const costCentre = this.priceFileService.priceFileItemGroups.find(i => i.id === subGroup?.priceFileItemParentId);
        const costCentreDesc = costCentre?.priceFileCode ? costCentre.priceFileCode + ' - ' + costCentre?.description
          : costCentre?.description;

        recipeLine.masterGroupCostCentre = 'I' + ('00000' + costCentre?.orderNumber.toString()).slice(-6) + ';' + costCentreDesc;
      } else {
        recipeLine.masterGroupCostCentre = '100000;Ad-Hoc';
        recipeLine.subGroupItemDesc = '000000;Items';
      }

      recipeLine.quantityString = recipeLine.quantityString && recipeLine.quantityString !== ''
        ? recipeLine.quantityString : recipeLine.quantity?.toString();
    });

    if (!this.maintenanceService.currentDistrict || this.maintenanceService.currentDistrict.id !== this.currentDistrict.id) {
      // if we are using a different district we need to recalc the data
      this.maintenanceService.currentDistrict = this.currentDistrict;
      const startTime = new Date().getTime();
      this.notiService.showInfo('Recalculating rates for ' + this.currentDistrict.description + ' price book for first use. Please wait...');
      this.globalVariablesService.classesLoadingSpinnerVisible = true;
      setTimeout(() => {
        this.recipePriceFileItems = this.estimatingService.getRecipeLinesForDistrict(this.currentDistrict);
        console.log('Time to recalc rates: ' + Math.round((new Date().getTime() - startTime) / 1000) + ' seconds');
        // store
        this.maintenanceService.recipePriceFileItems = this.recipePriceFileItems;
        this.createDataSet();
      }, 100);
    } else {
      this.recipePriceFileItems = this.maintenanceService.recipePriceFileItems;
      this.createDataSet();
    }
  }

  createDataSet() {
    this.recipeData = new CustomStore({
      key: 'recipeCode',
      loadMode: 'raw',
      load: () => this.recipePriceFileItems
    });

    this.recipeDataActiveOnly = new CustomStore({
      key: 'recipeCode',
      loadMode: 'raw',
      load: () => this.recipePriceFileItems.filter(i => i.isActive)
    });
    this.globalVariablesService.classesLoadingSpinnerVisible = false;
    this.refreshEmit(); // update the parent recipe if needed
    this.setUpDataSet();
  }

  setUpDataSet() {
    this.globalVariablesService.classesLoadingSpinnerVisible = false;
    this.dataSource = new CustomStore({
      key: 'id',
      load: () => this.estimatingService.getRecipeLinesForRecipe(this.recipe.id, this.currentDistrict),
      insert: async (values) => {
        values.recipeId = this.recipe.id;
        // get any lengths
        if (values['recipeLineLengths'] && values['recipeLineLengths'].length) {
          values.quantity = 0;
          values['recipeLineLengths'].forEach(recipeLineLength => {
            values.quantity += (recipeLineLength.quantity * recipeLineLength.length);
          });
          values.quantityString = values.quantity.toString();
        }
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.estimatingService.addRecipeLine(this.recipe.id, values).subscribe({
              next: (res) => {
                this.insertRecipeLine(res.id, values);
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.estimatingService.updateRecipeLine(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                this.updateRecipeLine(key, values);
                res.recipeCode = values.recipeCode;
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.estimatingService.deleteRecipeLine(encodeURIComponent(key)).subscribe({
              next: () => {
                this.deleteRecipeLine(key);
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  onToolbarPreparing(e) {
    if (this.recipesWrite) {
      e.toolbarOptions.items.unshift(
        {
          location: 'before',
          widget: 'dxButton',
          options: {
            text: this.editMode === 'batch' ? 'Row Edit' : 'Batch Edit',
            onClick: this.batchMode.bind(this)
          }
        },
        {
          location: 'before',
          widget: 'dxButton',
          options: {
            text: 'Re-Calc Selling Price',
            onClick: this.recalcSellingPrice.bind(this)
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Add Multiple Items',
            onClick: this.addMultipleItems.bind(this)
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            text: 'Group/Un-Group',
            onClick: this.groupUnGroup.bind(this)
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'share',
            hint: 'explode',
            onClick: this.explodeRecipe.bind(this)
          }
        },
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'upload',
            hint: 'Upload',
            onClick: this.uploadRecipeLines.bind(this)
          }
        });
    }
  }

  updateRecipeLine(id: number, values) {
    this.estimatingService.recipeLines.forEach(recipeLine => {
      if (recipeLine.id === id) {
        if (values.recipeItemId) {
          recipeLine.recipeItemId = values.recipeItemId;
          recipeLine.masterGroupCostCentre = '000000;RECIPES';
          const recipe = this.estimatingService.recipes.find(i => i.id === values.recipeItemId);
          recipeLine.subGroupItemDesc = '000000;'
            + this.estimatingService.recipeGroups.find(i => i.id === recipe.recipeParentId)?.description;
        }
        if (values.recipeCode) {
          recipeLine.recipeCode = values.recipeCode;
        }
        if (values.description) {
          recipeLine.description = values.description?.trim();
        }
        if (values.unitOfMeasureId !== undefined) {
          recipeLine.unitOfMeasureId = values.unitOfMeasureId;
        }
        if (values.quantity !== undefined) {
          recipeLine.quantity = values.quantity;
        }
        if (values.rate !== undefined) {
          recipeLine.rate = values.rate;
        }
        if (values.marginOverridePercent !== undefined) {
          recipeLine.marginOverridePercent = values.marginOverridePercent;
        }
        if (values.priceFileItemId !== undefined) {
          recipeLine.priceFileItemId = values.priceFileItemId;

          if (values.priceFileItemId) {
            const priceFileItem = this.priceFileService.priceFileItems.find(i => i.id === values.priceFileItemId);

            const itemGroup = this.priceFileService.priceFileItemGroups.find(i => i.id === priceFileItem?.priceFileItemParentId);
            const itemGroupDesc = itemGroup?.priceFileCode ?
              itemGroup.priceFileCode + ' - ' + itemGroup.description : itemGroup?.description;
            recipeLine.subGroupItemDesc = ('00000' + itemGroup?.orderNumber.toString()).slice(-6) + ';' + itemGroupDesc;

            const costCentre = this.priceFileService.priceFileItemGroups.find(i => i.id === itemGroup?.priceFileItemParentId);
            const costCentreDesc = costCentre?.priceFileCode ? costCentre.priceFileCode + ' - ' + costCentre?.description
              : costCentre?.description;
            recipeLine.masterGroupCostCentre = 'I'
              + ('00000' + costCentre?.orderNumber.toString()).slice(-6) + ';' + costCentreDesc; // 'I' to get items after recipes
          }
        }
        if (values.isQuoted !== undefined) {
          recipeLine.isQuoted = values.isQuoted;
        }
        if (values.isToBeChecked !== undefined) {
          recipeLine.isToBeChecked = values.isToBeChecked;
        }
        if (values.phaseId !== undefined) {
          recipeLine.phaseId = values.phaseId;
        }
        if (values.addendumCode !== undefined) {
          recipeLine.addendumCode = values.addendumCode;
        }
        if (values.quantityString !== undefined) {
          recipeLine.quantityString = values.quantityString;
        }
        if (values.comment !== undefined) {
          recipeLine.comment = values.comment ? values.comment.trim() : values.comment;
        }
      }
    });
    this.dataSource.load();
    this.refreshEmit(); // update the parent recipe
  }

  insertRecipeLine(id: number, values) {
    let masterGroupCostCentre = '100000;Ad-Hoc';
    let subGroupItemDesc = '000000;Items';

    if (values.recipeItemId) {
      masterGroupCostCentre = '000000;RECIPES';
      const recipe = this.estimatingService.recipes.find(i => i.id === values.recipeItemId);
      subGroupItemDesc = '000000;' + this.estimatingService.recipeGroups.find(i => i.id === recipe.recipeParentId)?.description;
    } else if (values.priceFileItemId) {
      const priceFileItem = this.priceFileService.priceFileItems.find(i => i.id === values.priceFileItemId);

      const itemGroup = this.priceFileService.priceFileItemGroups.find(i => i.id === priceFileItem?.priceFileItemParentId);
      const itemGroupDesc = itemGroup?.priceFileCode ? itemGroup.priceFileCode + ' - ' + itemGroup.description : itemGroup?.description;
      subGroupItemDesc = ('00000' + itemGroup?.orderNumber.toString()).slice(-6) + ';' + itemGroupDesc;

      const costCentre = this.priceFileService.priceFileItemGroups.find(i => i.id === itemGroup?.priceFileItemParentId);
      const costCentreDesc = costCentre?.priceFileCode ? costCentre.priceFileCode + ' - ' + costCentre?.description
        : costCentre?.description;
      masterGroupCostCentre = 'I'
        + ('00000' + costCentre?.orderNumber.toString()).slice(-6) + ';' + costCentreDesc; // 'I' to get items after recipes

      // insert any lengths
      if (values['recipeLineLengths'] && values['recipeLineLengths'].length) {
        values['recipeLineLengths'].forEach(async recipeLineLength => {
          await this.insertRecipeLineLengths(id, recipeLineLength);
        });
      }
    }

    const newRecipeLine = {
      id: id,
      recipeId: this.recipe.id,
      recipeItemId: values.recipeItemId,
      recipeCode: values.recipeCode,
      description: values.description?.trim(),
      unitOfMeasureId: values.unitOfMeasureId,
      quantity: values.quantity,
      rate: values.rate,
      marginOverridePercent: values.marginOverridePercent,
      priceFileItemId: values.priceFileItemId,
      isQuoted: values.isQuoted,
      phaseId: values.phaseId,
      addendumCode: values.addendumCode,
      quantityString: values.quantityString,
      comment: values.comment ? values.comment.trim() : values.comment,
      isToBeChecked: values.isToBeChecked,
      masterGroupCostCentre: masterGroupCostCentre,
      subGroupItemDesc: subGroupItemDesc,
      costCentreId: null,
      modifiedDate: new Date(),
      modifiedUserId: null
    };

    this.estimatingService.recipeLines.push(newRecipeLine);

    const items = this.estimatingService.allRecipeLinesMap.get(this.recipe.id);
    if (items) {
      items.push(newRecipeLine);
    } else {
      this.estimatingService.allRecipeLinesMap.set(this.recipe.id, [newRecipeLine]);
    }

    this.refreshEmit(); // update the parent recipe
  }

  async insertRecipeLineLengths(recipeLineId: number, recipeLineLength: RecipeLineLength) {
    return new Promise((resolve, reject) =>
      this.subscriptions.push(
        this.estimatingService.addRecipeLineLength(
          { recipeLineId: recipeLineId, quantity: recipeLineLength.quantity, length: recipeLineLength.length }
        ).subscribe({
          next: (res) => {
            this.recipeLineQuantity = res.recipeLineQuantity;
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      )
    );
  }

  deleteRecipeLine(id: number) {
    const rowIndex = this.estimatingService.recipeLines.findIndex(i => i.id === id);
    const recipeLine = this.estimatingService.recipeLines[rowIndex];
    this.estimatingService.recipeLines.splice(rowIndex, 1);
    // take out of Map
    const items = this.estimatingService.allRecipeLinesMap.get(recipeLine.recipeId);
    if (items) {
      const itemIndex = items.findIndex(i => i.id === id);
      items.splice(itemIndex, 1);
    }
    this.refreshEmit(); // update the parent recipe
  }

  onSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0]);
      e.component.close();
    }
  }

  onEditingStart(e) {
    this.rowKey = e.key;
    this.recipeLineId = e.data.id;
    this.recipeLineQuantity = e.data.quantity;
    this.recipeLineDescription = e.data.description;
    this.hasSizes = false;

    // do we have lengths
    if (e.data.priceFileItemId) {
      const priceFileItem = this.priceFileService.priceFileItems.find(i => i.id === e.data.priceFileItemId);
      if (priceFileItem && priceFileItem.hasSizes) {
        // read the lengths
        this.hasSizes = true;
      }
    }
  }

  onInitNewRow(e) {
    this.rowKey = -1;
    e.data.phaseId = 0; // default
    e.data.isToBeChecked = false;
  }

  setRecipeCellValue(rowData, value) {
    if (value) {
      rowData.recipeItemId = value.recipeId;
      rowData.priceFileItemId = value.priceFileItemId;
      rowData.recipeCode = value.recipeCode;
      rowData.description = value.description;
      rowData.unitOfMeasureId = this.unitOfMeasures.find(i => i.description === value.unitOfMeasure)?.id;
      rowData.rate = value.rate;

      if (!rowData.priceFileItemId || this.rowKey === -1) {
        rowData.phaseId = 0;
        rowData.isQuoted = false;
        rowData.addendumCode = '';
      }

      this.recipeLineId = this.rowKey;
      // this.recipeLineQuantity = 0;
      this.recipeLineDescription = value.description;
      this.hasSizes = false;

      // do we have lengths
      if (value.priceFileItemId) {
        const priceFileItem = this.priceFileService.priceFileItems.find(i => i.id === value.priceFileItemId);
        if (priceFileItem && priceFileItem.hasSizes) {
          // read the lengths
          this.hasSizes = true;
        }
      }
    }
  }

  onEditorPreparing(e: any) {
    if (e.parentType === 'dataRow' && e.dataField === 'quantity') {
      e.editorOptions.disabled = true;
    }
    if (e.parentType === 'dataRow' && e.dataField === 'unitOfMeasure') {
      e.editorOptions.disabled = e.row.data && e.row.data.recipeCode;
    }
    if (e.parentType === 'dataRow' && e.dataField === 'description') {
      e.editorName = 'dxTextArea';
    }
  }

  getRate(recipeLine: RecipeLine) {
    if (recipeLine.rate) {
      return recipeLine.rate;
    }
    let rate = recipeLine.rate;
    if (recipeLine.recipeItemId) {
      rate = this.estimatingService.calcRecipeRate(recipeLine.recipeItemId, this.currentDistrict, true);
    } else if (recipeLine.priceFileItemId) {
      rate = this.priceFileService.getDistrictPreferredRate(this.currentDistrict, recipeLine.priceFileItemId, this.estimatingService.currentCostingDateString);
    }
    if (recipeLine.recipeCode && recipeLine.recipeCode !== '' && rate === null) {
      return 'Invalid';
    }
    recipeLine.rate = rate;
    return rate;
  }

  getLookupRate(recipePriceFileItem: RecipePriceFileItem) {
    if (recipePriceFileItem.rate) {
      return recipePriceFileItem.rate;
    }
    let rate = recipePriceFileItem.rate;
    if (recipePriceFileItem.recipeId) {
      rate = this.estimatingService.calcRecipeRate(recipePriceFileItem.recipeId, this.currentDistrict, true);
    } else if (recipePriceFileItem.priceFileItemId) {
      rate = this.priceFileService.getDistrictPreferredRate(this.currentDistrict, recipePriceFileItem.priceFileItemId, this.estimatingService.currentCostingDateString);
    }
    if (recipePriceFileItem.recipeCode && recipePriceFileItem.recipeCode !== '' && rate === null) {
      return 'Invalid';
    }
    recipePriceFileItem.rate = rate;
    return rate;
  }

  onCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'rate' && e.data.rate === null && e.data.recipeCode && e.data.recipeCode !== '') {
      e.cellElement.style.color = 'red';
    }
    if (e.rowType === 'data' && e.column.dataField === 'quantityString' && e.data.quantityString) {
      try {
        const calcCheck = mathString(this.sanitizeQty(e.data.quantityString));
        if (isNaN(+calcCheck)) {
          e.cellElement.classList.add('redWhite');
        }
      } catch (err) {
        e.cellElement.classList.add('redWhite');
      }
    }
  }

  getGroupTitle(cellInfo) {
    return cellInfo.data.key?.split(';')[1];
  }

  calculateGrouptitle(data) {
    return data.masterGroupCostCentre.split(';')[1];
  }

  calculateSubGrouptitle(data) {
    return data.subGroupItemDesc.split(';')[1];
  }

  calcGroupSortValue(data) {
    return data.masterGroupCostCentre.split(';')[0];
  }

  calculateSubGroupSortValue(data) {
    return data.subGroupItemDesc.split(';')[0];
  }

  isExplodeIconVisible = (e) => {
    if (e.row.data.id) {
      const foundLines = this.estimatingService.recipeLines.find(i => i.recipeId === e.row.data.recipeItemId);

      if (foundLines) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  isOpenIconVisible = (e) => {
    if (e.row.data.recipeItemId) {
      return true;
    }
    return false;
  }

  explodeAsk = (e) => {
    this.popupVisible = true;
    this.quantityForExplode = e.row.data.quantity;
    this.itemToExplode = e.row.data.id;
    this.recipeItemId = e.row.data.recipeItemId;
  }

  explode = () => {
    this.globalVariablesService.classesLoadingSpinnerVisible = true;
    this.popupVisible = false;

    this.subscriptions.push(
      this.estimatingService.explodeRecipeLine(this.itemToExplode)
        .subscribe({
          next: () => {
            this.estimatingService.recipeLines = [];
            this.getAllRecipeLines();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.globalVariablesService.classesLoadingSpinnerVisible = false;
          }
        })
    );
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Recipe',
      { pageSetup: { paperSize: 9, orientation: 'landscape', showGridLines: true } });

    worksheet.columns = [
      { width: 20 }, { width: 60 }, { width: 15 }, { width: 15 }, { width: 15 }, { width: 15 },
      { width: 15 }, { width: 15 }, { width: 15 }, { width: 10 }, { width: 25 }
    ];
    worksheet.headerFooter.oddFooter = '&L' + this.recipe.description + '&CPage &P of &N';
    worksheet.headerFooter.evenFooter = '&L' + this.recipe.description + '&CPage &P of &N';

    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      keepColumnWidths: false,
      // topLeftCell: { row: 2, column: 2 },
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.rowType === 'group') {
          this.gridString = gridCell.value;
          excelCell.value = this.gridString?.split(';')[1];

          if (gridCell.groupIndex === 0) {
            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'BEDFE6' } };
            excelCell.alignment = { wrapText: false };
          }
          if (gridCell.groupIndex === 1) {
            excelCell.alignment = { wrapText: false };
          }
        }
      }
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Recipe ' + this.recipe.description + '.xlsx');
      });
    });
    e.cancel = true;
  }

  editLengths(cellInfo) {
    this.lengthPopupVisible = true;

    if (!this.recipeLineId || this.recipeLineId <= 0) {
      // we haven't added the line yet
      if (!cellInfo.data.recipeLineLengths) {
        cellInfo.data.recipeLineLengths = [];
      }

      this.lengthsDataSource = new CustomStore({
        key: 'id',
        load: () => cellInfo.data.recipeLineLengths,
        insert: async (values: RecipeLineLength) => {
          return new Promise((resolve, reject) => {
            const recipeLineLength = cellInfo.data.recipeLineLengths.find(i => i.length === values.length);
            if (recipeLineLength) {
              return reject('Length already exists');
            } else {
              this.lengthKeyId++;
              values.id = this.lengthKeyId; // set a temp key
              cellInfo.data.recipeLineLengths.push(values);
              return resolve(values);
            }
          });
        },
        update: async (key, values) => {
          return new Promise((resolve, reject) => {
            const recipeLineLength = cellInfo.data.recipeLineLengths.find(i => i.id === key);
            if (recipeLineLength) {
              if (values['length']) {
                // cannot repeat
                const existingRecipeLineLength = cellInfo.data.recipeLineLengths.find(i => i.length === values['length'] && i.id !== key);
                if (existingRecipeLineLength) {
                  return reject('Length already exists');
                }
                recipeLineLength.length = values['length'];
              }
              if (values['quantity']) {
                recipeLineLength.quantity = values['quantity'];
              }
              return resolve(recipeLineLength);
            } else {
              return reject('Length not found');
            }
          });
        },
        remove: async (key) => {
          return new Promise((resolve, reject) => {
            const recipeLineLengthIndex = cellInfo.data.recipeLineLengths.findIndex(i => i.id === key);
            if (recipeLineLengthIndex >= 0) {
              cellInfo.data.recipeLineLengths.splice(recipeLineLengthIndex, 1);
              return resolve();
            } else {
              return reject('Length not found');
            }
          });
        }
      });
    } else {
      // line exists
      this.lengthsDataSource = new CustomStore({
        key: 'id',
        load: async () => {
          return new Promise((resolve, reject) =>
            this.subscriptions.push(
              this.estimatingService.getRecipeLineLengths(this.recipeLineId).subscribe({
                next: (res) => {
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            ));
        },
        insert: async (values: RecipeLineLength) => {
          return new Promise((resolve, reject) =>
            this.subscriptions.push(
              this.estimatingService.addRecipeLineLength(
                { recipeLineId: this.recipeLineId, quantity: values.quantity, length: values.length }
              ).subscribe({
                next: (res) => {
                  this.recipeLineQuantity = res.recipeLineQuantity;
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            ));
        },
        update: async (key, values) => {
          return new Promise((resolve, reject) =>
            this.subscriptions.push(
              this.estimatingService.updateRecipeLineLength(encodeURIComponent(key), values).subscribe({
                next: (res) => {
                  this.recipeLineQuantity = res.recipeLineQuantity;
                  return resolve(res);
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            ));
        },
        remove: async (key) => {
          return new Promise((resolve, reject) =>
            this.subscriptions.push(
              this.estimatingService.deleteRecipeLineLength(encodeURIComponent(key)).subscribe({
                next: (res) => {
                  this.recipeLineQuantity = res.recipeLineQuantity;
                  return resolve();
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            ));
        }
      });
    }
  }

  closeLengthPopup() {
    this.lengthPopupVisible = false;
  }

  hidingLengthPopup(e) {
    const recipeLine = this.estimatingService.recipeLines.find(i => i.id === this.recipeLineId);
    recipeLine.quantity = this.recipeLineQuantity;
    recipeLine.quantityString = this.recipeLineQuantity?.toString();

    const rowIndex = this.dataGrid.instance.getRowIndexByKey(this.recipeLineId);
    this.dataGrid.instance.cellValue(rowIndex, 'quantityString', this.recipeLineQuantity?.toString());
    this.dataGrid.instance.cellValue(rowIndex, 'quantity', this.recipeLineQuantity);

    this.dataGrid.instance.saveEditData();
  }

  setEditedQtyValue(valueChangedEventArg, cellInfo) {
    cellInfo.setValue(valueChangedEventArg.value);
  }

  sanitizeQty(quantityString): string {
    if (quantityString) {
      quantityString = quantityString.replace(/(^\s+|\s+$)/g, '');
      quantityString = quantityString.replace(/=/g, '');
      if (quantityString.search(' ') >= 0) {
        quantityString = quantityString.replace(/\s/g, '');
      }
    }
    return quantityString;
  }

  calcQtyFromString(quantityString): number {
    let validQty = true;
    let quantity = 0;
    let quantityStringNew = '';

    if (quantityString) {
      try {
        quantityStringNew = mathString(quantityString);
      } catch (err) {
        validQty = false;
        quantity = 0;
      }

      if (isNaN(quantity)) {
        validQty = false;
        quantity = 0;
      }
    }

    if (validQty) {
      return +quantityStringNew;
    } else {
      return null;
    }
  }

  setQtyCellValue(rowData, value) {
    if (value) {
      rowData.quantityString = value;
      rowData.quantity = this.calcQtyFromString(this.sanitizeQty(value));
    }
  }

  calculateLineTotal = (rowData) => {
    if (rowData) {
      let rate = rowData.rate;
      if (rowData.recipeItemId) {
        rate = this.estimatingService.calcRecipeRate(rowData.recipeItemId, this.currentDistrict, true);
      } else if (rowData.priceFileItemId) {
        rate = this.priceFileService.getDistrictPreferredRate(this.currentDistrict, rowData.priceFileItemId, this.estimatingService.currentCostingDateString);
      }

      let qty = !rowData.quantity || isNaN(rowData.quantity) ? 0 : rowData.quantity;
      rate = !rate || isNaN(rate) ? 0 : rate;

      const unitOfMeasure = this.maintenanceService.unitOfMeasures.find(i => i.id === rowData.unitOfMeasureId);
      if (unitOfMeasure && unitOfMeasure.costIsPer) {
        qty /= unitOfMeasure.costIsPer;
      }

      return qty * rate; // this.utilsService.roundEven(qty * rate);
    } else {
      return 0;
    }
  }

  addMultipleItems() {
    this.priceFileService.recipeAndItems = this.recipePriceFileItems;

    const modalRef = this.modalService.open(AddMultipleItemsComponent, { windowClass: 'modal-1200' });
    modalRef.componentInstance.recipe = this.recipe;
    modalRef.componentInstance.currentDistrict = this.currentDistrict;

    modalRef.result.then(() => {
      this.estimatingService.recipeLines = null;
      this.globalVariablesService.classesLoadingSpinnerVisible = true;
      this.getAllRecipeLines();
    }, () => {
    });
  }

  explodeRecipe = () => {
    this.explodeTitle = 'Explode ' + this.recipe.recipeCode + '?';
    this.explodeRecipePopupVisible = true;
    this.ignoreEmptyRecipes = false;
  }

  explodeRecipeGo = () => {
    this.globalVariablesService.classesLoadingSpinnerVisible = true;
    this.explodeRecipePopupVisible = false;

    this.subscriptions.push(
      this.estimatingService.explodeRecipe(this.recipe.id, this.ignoreEmptyRecipes)
        .subscribe({
          next: (explodeResult) => {
            if (explodeResult !== '') {
              this.notiService.showWarning(explodeResult);
            }
            this.estimatingService.recipeLines = [];
            this.getAllRecipeLines();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.globalVariablesService.classesLoadingSpinnerVisible = false;
          }
        })
    );
  }

  recalcSellingPrice() {
    if (this.recipe) {
      this.recalcSellingPricePopupVisible = true;
      this.updateOptionLists = true;
      this.reCostingDate = this.estimatingService.costingDate;
    }
  }

  recalcSellingPriceGo() {
    this.loading = true;
    this.recalcSellingPricePopupVisible = false;

    this.subscriptions.push(
      this.estimatingService.recostRecipes(this.updateOptionLists, this.reCostingDate, this.recipeParentId, this.recipe.id)
        .subscribe({
          next: () => {
            this.subscriptions.push(
              this.estimatingService.getRecipeSelling(this.recipe.id)
                .subscribe({
                  next: (res) => {
                    // take out the old selling rates
                    this.estimatingService.recipeSellingRates = this.estimatingService.recipeSellingRates
                      .filter(i => i.recipeId !== this.recipe.id);

                    // add the new selling rates
                    this.estimatingService.recipeSellingRates = this.estimatingService.recipeSellingRates.concat(res);

                    const recipeSelling = this.estimatingService.recipeSellingRates
                      .find(i => i.recipeId === this.recipe.id
                        && i.districtId === this.recipe.districtId);

                    if (recipeSelling) {
                      this.recipe.sellingPrice = recipeSelling.rate;
                      this.recipe.sellingPriceLastUpdated = recipeSelling.effectiveDate;
                    } else {
                      this.recipe.sellingPrice = null;
                      this.recipe.sellingPriceLastUpdated = null;
                    }
                    this.loading = false;
                    this.refreshEmit();
                  }, error: (err) => {
                    this.notiService.notify(err);
                    this.loading = false;
                  }
                })
            );
          }, error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  batchMode() {
    if (this.editMode === 'batch') {
      this.editMode = 'row';
    } else {
      this.editMode = 'batch';
    }
  }

  explodeRecipeInMemory = () => {
    this.explodeRecipePopupVisible = false;
    this.priceFileService.recipeAndItems = this.recipePriceFileItems;

    const modalRef = this.modalService.open(ExplodeInMemoryComponent, { windowClass: 'modal-1500' });
    modalRef.componentInstance.recipe = this.recipe;
    modalRef.result.then(() => { });
  }

  openRecipe(e) {
    this.openRecipeGo(e.row.data.recipeItemId);
  }

  openRecipe2() {
    this.popupVisible = false;
    this.openRecipeGo(this.recipeItemId);
  }

  openRecipeGo(recipeItemId: number) {
    const modalRef = this.modalService.open(SubRecipeComponent, { windowClass: 'modal-1500' });
    modalRef.componentInstance.recipeId = recipeItemId;
    modalRef.componentInstance.isSubRecipe = true;

    modalRef.result.then(() => {
      // tell other component for the same recipe to refresh
      this.globalService.recipeLinesUpdatedEmit(recipeItemId);
    }, () => {
      this.globalService.recipeLinesUpdatedEmit(recipeItemId);
    });
  }

  groupUnGroup() {
    if (this.isGrouped === true) {
      if (window.innerWidth < 2000) {
        this.notiService.showInfo('Screen not wide enough to un-group.');
      } else {
        this.globalVariablesService.classesLoadingSpinnerVisible = true
        setTimeout(() => {
          this.isGrouped = false;
          this.setWidths();
          this.globalVariablesService.classesLoadingSpinnerVisible = false;
          setTimeout(() => {
            this.dataGrid.instance.clearSorting();
            this.dataGrid.instance.columnOption(0, 'sortOrder', 'asc');
            this.dataGrid.instance.columnOption(0, 'sortIndex', 0);
            this.dataGrid.instance.columnOption(1, 'sortOrder', 'asc');
            this.dataGrid.instance.columnOption(1, 'sortIndex', 1);
            this.dataGrid.instance.columnOption(3, 'sortOrder', 'asc');
            this.dataGrid.instance.columnOption(3, 'sortIndex', 2);
          }, 250); // wait for grid
        }, 250); // wait for grid
      }
    } else {
      this.globalVariablesService.classesLoadingSpinnerVisible = true
      setTimeout(() => {
        this.isGrouped = true;
        this.setWidths();
        this.globalVariablesService.classesLoadingSpinnerVisible = false;
        this.dataGrid.instance.refresh();
      }, 250); // wait for grid
    }
  }

  uploadRecipeLines() {
    const modalRef = this.modalService.open(UploadRecipeLinesComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.recipe = this.recipe;

    modalRef.result.then(() => {
      this.estimatingService.recipes = null;
      this.estimatingService.recipeLines = null;
      this.globalVariablesService.classesLoadingSpinnerVisible = true;
      this.getAllRecipes();
    }, () => {
    });
  }

  getAllRecipes() {
    // read all recipes
    this.subscriptions.push(
      this.estimatingService.getRecipes(true)
        .subscribe({
          next: () => {
            this.getAllRecipeLines();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.globalVariablesService.classesLoadingSpinnerVisible = false;
          }
        })
    );
  }

  refreshEmit() {
    if (!this.isSubRecipe) {
      this.refresh.emit(true);
    }
  }
}
