<div class="modal-header">
  <h5 class="modal-title">{{recipe.recipeCode}}: {{recipe.description}}</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <js-recipe-lines [recipe]="recipe" (refresh)="refresh()"></js-recipe-lines>
</div>
