import { NotificationService } from './../../services/notification.service';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';
import { CompanyMarginService } from '../../services/felixApi/company-margin.service';
import { GlobalService } from '../../services/global.service';
import { GridService } from '../../services/grid.service';
import { CompanyMargin } from '../../dtos/company-margin';
import { Subscription } from 'rxjs';
import { ConfigurationEnum } from '../../dtos/configuration-enum';

@Component({
  selector: 'js-company-margins',
  templateUrl: './company-margins.component.html',
  styleUrls: ['./company-margins.component.scss']
})
export class CompanyMarginsComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: any = {};
  loading = false;

  marginTypes = [{ id: 1, description: 'Company Margin' }, { id: 2, description: 'Insurances/Fees' }];
  centralCompanyMargins: CompanyMargin[];

  constructor(
    private companyMarginService: CompanyMarginService,
    private globalService: GlobalService,
    public gridService: GridService,
    private notiService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.getCentralCompanyMargins();
    this.setUpDataSource();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  refresh() {
    // this.informationTypeService.clearCaches();
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.companyMarginService.getAllCompanyMargins(false, false).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      insert: async (values: CompanyMargin) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.companyMarginService.addCompanyMargin(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.companyMarginService.updateCompanyMargin(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.companyMarginService.deleteCompanyMargin(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });
  }

  initNewRow = (e) => {
    e.data.isActive = true;
    e.data.marginTypeId = 1;
  }

  getCentralCompanyMargins() {
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.CentralCompanyActive)) {
      this.subscriptions.push(
        this.companyMarginService.getCentralCompanyMargins().subscribe({
          next: (res) => {
            this.centralCompanyMargins = res;
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }
}
