<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <!-- not iPad -->
  <dx-data-grid *ngIf="!loading && !isIPad" class="m-auto tabbed-grid-height" [dataSource]="dataSource"
    [allowColumnReordering]="true" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event)"
    (onInitNewRow)="onInitNewRow($event)" (onExporting)="gridService.onExporting($event, 'Vendors')">

    <dxo-scrolling mode="standard" rowRenderingMode="virtual" [preloadEnabled]="true"></dxo-scrolling>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="canEdit" [allowDeleting]="canEdit" [useIcons]="true"
      refreshMode="repaint">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="false" expandMode="rowClick"></dxo-grouping>

    <div *dxTemplate="let data of 'refreshTemplate'">
      <div>
        <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
      </div>
    </div>

    <dxi-column dataField="id" [visible]="false"></dxi-column>

    <dxi-column dataField="vendorCode" [fixed]="true" [width]="100" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="vendorName" [fixed]="true" [minWidth]="150">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="vendorName2" [minWidth]="90"> </dxi-column>

    <dxi-column dataField="vendorAddress" [minWidth]="100"></dxi-column>

    <dxi-column dataField="vendorContact" [minWidth]="80"></dxi-column>

    <dxi-column dataField="phoneNumber" [minWidth]="80"></dxi-column>

    <dxi-column dataField="email" [minWidth]="100"></dxi-column>

    <dxi-column dataField="vendorABN" [minWidth]="60"></dxi-column>

    <dxi-column dataField="callUpEmail" caption="Call Up Email (if Different)" [minWidth]="100"></dxi-column>

    <dxi-column dataField="smsPhoneNumber" caption="Call-Up SMS Phone Number" [width]="100"></dxi-column>

    <dxi-column dataField="ordersEmail" caption="Orders Email (if Different)" [minWidth]="100"></dxi-column>

    <dxi-column dataField="incomeProtectionExpiry" dataType="date" [width]="100" format="d/M/yy" alignment="center"
      [editorOptions]="{ showClearButton: true }"></dxi-column>

    <dxi-column dataField="publicLiabilityExpiry" dataType="date" [width]="100" format="d/M/yy" alignment="center"
      [editorOptions]="{ showClearButton: true }"></dxi-column>

    <dxi-column dataField="workCoverExpiry" dataType="date" [width]="100" format="d/M/yy" alignment="center"
      [editorOptions]="{ showClearButton: true }"></dxi-column>

    <dxi-column dataField="renegotiationDate" dataType="date" [width]="100" format="d/M/yy" alignment="center">
    </dxi-column>

    <dxi-column dataField="comment" [minWidth]="100"></dxi-column>

    <dxi-column dataField="callUpSubjectAppendix" caption="Call-Up Email Subject Appendix" [minWidth]="100">
    </dxi-column>

    <dxi-column dataField="isCallUpShowSupervisor" caption="Show Supervisor on Call Up/PO?" dataType="boolean"
      [width]="100">
    </dxi-column>

    <dxi-column dataField="markOrdersSentWhenRaised" dataType="boolean" [width]="90"> </dxi-column>

    <dxi-column dataField="canAttachOrdersToCallUps" caption="Can Attach to Call-Ups" dataType="boolean" [width]="90">
    </dxi-column>

    <dxi-column dataField="isActive" caption="Active?" dataType="boolean" [width]="90"> </dxi-column>

    <dxi-column dataField="hideFromOrders" caption="Hide Vendor from Orders" dataType="boolean" [width]="95">
    </dxi-column>

    <dxi-column dataField="overwriteOrderWithCallUpVendor" caption="If Call-Up Vendor Different to PO for this Vendor"
      dataType="number" [width]="120">
      <dxo-lookup [dataSource]="overwriteOrderWithCallUpVendorTypes" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="emailSalutation" caption="Order Email Salutation (Hi ...)" [width]="95"></dxi-column>

    <dxi-column dataField="vendorSetupComplete" dataType="boolean" [width]="100"> </dxi-column>

    <dxi-column dataField="safetyManagementPlanCompleted" dataType="date" [width]="100" format="d/M/yy"
      alignment="center" [editorOptions]="{ showClearButton: true }"></dxi-column>

    <dxi-column dataField="introductionComplete" caption="Safety Induction Booklet" dataType="date" [width]="100"
      format="d/M/yy" alignment="center" [editorOptions]="{ showClearButton: true }"></dxi-column>

    <dxi-column dataField="jsaSwmsExpiryDate" caption="JSA/SWMS Expiry" dataType="date" [width]="100" format="d/M/yy"
      alignment="center" [editorOptions]="{ showClearButton: true }"></dxi-column>

    <dxi-column dataField="whiteCardExpiry" dataType="date" [visible]="false"></dxi-column>

    <dxi-column dataField="hasWhiteCard" dataType="boolean" [calculateCellValue]="calculateHasWhiteCard" [width]="90"
      [setCellValue]="setWhiteCardExpiry"></dxi-column>

    <dxi-column dataField="vendorGroupId" caption="Vendor Group" dataType="number" width="130"
      [editorOptions]="{ showClearButton: true }" [allowEditing]="false">
      <dxo-lookup [dataSource]="vendorGroups" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="lastInvoice" caption="Last Invoice" dataType="date" [width]="95" format="d/M/yy"
      alignment="center" [allowEditing]="false"></dxi-column>

    <dxi-column dataField="quoteRequestEmail" caption="Quote Request Email (if Different)"
      [minWidth]="100"></dxi-column>
  </dx-data-grid>


  <!-- iPad or smaller -->
  <dx-data-grid *ngIf="!loading && isIPad" class="m-auto tabbed-grid-height" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="false" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" [columnHidingEnabled]="true">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="false" [allowUpdating]="!isPhone && canEdit"
      [allowDeleting]="!isPhone && canEdit" [useIcons]="true" refreshMode="repaint">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>

    <dxi-column dataField="vendorCode" [width]="90" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="vendorName" [width]="200">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="vendorContact" [width]="120"></dxi-column>

    <dxi-column dataField="phoneNumber" [width]="120"></dxi-column>

    <dxi-column dataField="email" [width]="250"></dxi-column>

    <dxi-column dataField="callUpEmail" caption="Call Up Email (if Different)" [width]="250">
    </dxi-column>

    <dxi-column dataField="ordersEmail" caption="Orders Email (if Different)" [width]="250">
    </dxi-column>

    <dxi-column dataField="comment" [width]="200"></dxi-column>

    <dxi-column dataField="callUpSubjectAppendix" caption="Call-Up Email Subject Appendix" [width]="200">
    </dxi-column>

    <dxi-column dataField="isCallUpShowSupervisor" caption="Show Supervisor on Call-Up/PO?" dataType="boolean"
      [width]="100">
    </dxi-column>

    <dxi-column dataField="vendorSetupComplete" dataType="boolean" [width]="100"> </dxi-column>

    <dxi-column dataField="safetyManagementPlanCompleted" dataType="date" [width]="100" format="d/M/yy"
      alignment="center" [editorOptions]="{ showClearButton: true }"></dxi-column>

    <dxi-column dataField="introductionComplete" caption="Safety Induction Booklet" dataType="date" [width]="100"
      format="d/M/yy" alignment="center" [editorOptions]="{ showClearButton: true }"></dxi-column>

    <dxi-column dataField="workCoverExpiry" dataType="date" [width]="100" format="d/M/yy" alignment="center"
      [editorOptions]="{ showClearButton: true }"></dxi-column>

    <dxi-column dataField="jsaSwmsExpiryDate" caption="JSA/SWMS Expiry" dataType="date" [width]="100" format="d/M/yy"
      alignment="center" [editorOptions]="{ showClearButton: true }"></dxi-column>

    <dxi-column dataField="whiteCardExpiry" dataType="date" [visible]="false"></dxi-column>

    <dxi-column dataField="hasWhiteCard" dataType="boolean" [calculateCellValue]="calculateHasWhiteCard" [width]="90"
      [setCellValue]="setWhiteCardExpiry"></dxi-column>

    <dxi-column dataField="isActive" caption="Active?" dataType="boolean" [width]="70">
    </dxi-column>
  </dx-data-grid>

  <dx-popup class="popup" [width]="400" [height]="350" [showTitle]="true" title="Load from Excel" [dragEnabled]="false"
    [hideOnOutsideClick]="false" [(visible)]="popupVisible">
    <div *dxTemplate="let data of 'content'">
      <div id="fileuploader-container">
        <dx-file-uploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false" accept="*"
          [(value)]="value" uploadMode="useForm" [allowedFileExtensions]="['.xls', '.xlsx']">
        </dx-file-uploader>
      </div>
      <br>
      <dx-button id="button" text="Load" type="success" (onClick)="uploadClick()">
      </dx-button>
    </div>
  </dx-popup>

</div>
