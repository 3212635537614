<div class="page">

  <dx-data-grid id="dataGrid" class="max-w-700 m-auto tabbed-grid-height" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onInitNewRow)="onInitNewRow($event)" (onExporting)="gridService.onExporting($event, 'Units')">

    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="reshape">
    </dxo-editing>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-export [enabled]="true"></dxo-export>

    <dxi-column dataField="description" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="costIsPer" dataType="number" width="90">
      <dxi-validation-rule type="range" [min]="0" message="Must be geater than zero"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="isDoNotRecost" dataType="boolean" caption="Do NOT Recost" width="120"> </dxi-column>

    <dxi-column *ngIf="centralCompanyUnits" dataField="centralCompanyId" dataType="number"
      caption="Central Company Unit" [width]="180" [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="centralCompanyUnits" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>
  </dx-data-grid>
</div>
