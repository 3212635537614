import { Recipe } from './../../dtos/recipe';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EstimatingService } from '../../services/felixApi/estimating.service';

@Component({
  selector: 'js-sub-recipe',
  templateUrl: './sub-recipe.component.html',
  styleUrls: ['./sub-recipe.component.scss']
})
export class SubRecipeComponent implements OnInit {
  @Input() recipe: Recipe;

  constructor(private activeModal: NgbActiveModal,
    private estimatingService: EstimatingService
  ) {
  }

  ngOnInit() {
    if (!this.estimatingService.costingDate) {
      this.estimatingService.costingDate = new Date();

      this.estimatingService.currentCostingDateString = this.estimatingService.costingDate.getFullYear() + '-'
        + ('0' + (this.estimatingService.costingDate.getMonth() + 1)).toString().slice(-2) + '-'
        + ('0' + this.estimatingService.costingDate.getDate()).slice(-2);
    }
  }

  refresh() { }

  close() {
    this.activeModal.close();
  }
}
