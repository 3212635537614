<nav *ngIf="signedIn && currComp" class="navbar navbar-expand-md navbar-light navbarclass">

  <dx-drop-down-button #dropdownButton [items]="dropdownItems" [useSelectMode]="false" [dropDownOptions]="{ width: 140}"
    icon="assets/menuBar.png" class="menuDropDown">

    <div *dxTemplate="let item of 'item'">
      <img [src]="item.icon" class="dropDownLogo">
      <span class="dropdownOptionText">{{ item.text }}</span>
    </div>
  </dx-drop-down-button>
  <div>
    <!-- <dx-button id="threedots" icon="overflow"> </dx-button> -->
    <img class="navbar-brand logo" src="../../../assets/logoTransparent.png" [routerLink]="['/welcome']" />
    <div class="title">
      Estimating
    </div>
  </div>

  <span *ngIf="currComp && !(innerWidth < 768)" class="cust-divider-vertical"></span>

  <button class="navbar-toggler d-lg-none" type="button" (click)="isCollapsed = !isCollapsed"
    [attr.aria-expanded]="!isCollapsed" aria-controls="navbarsExampleDefault" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarsExampleDefault" [ngbCollapse]="isCollapsed">
    <ul class="navbar-nav mr-auto mt-2 mt-lg-0" style="margin-top: 0!important;">

      <a *ngIf="recipesRead" class="nav-item active nav-link tab" [routerLink]="['/recipes']" [class.disabled]="disabledMenu"
        [class.selected]="areaSelected === 'recipes'" (click)="isCollapsed = true; menuPress('recipes')">
        Recipes
      </a>

      <a *ngIf="priceFileRead" class="nav-item active nav-link tab" [routerLink]="['/price-file-items']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'price-file-items'"
        (click)="isCollapsed = true; menuPress('price-file-items')">
        Price-File
      </a>

      <a *ngIf="priceFileAdmin" class="nav-item active nav-link tab" [routerLink]="['/price-file-groups']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'price-file-groups'"
        (click)="isCollapsed = true; menuPress('price-file-groups')">
        Cost-Centres/Sub-Groups
      </a>

      <a class="nav-item active nav-link tab" [routerLink]="['/maintenance']"
        [class.disabled]="disabledMenu" [class.selected]="areaSelected === 'maintenance'"
        (click)="isCollapsed = true; menuPress('maintenance')">
        Set-Up
      </a>

    </ul>
  </div>

  <div *ngIf="innerWidth > 450">
    <a class="currComp" [routerLink]="['/welcome']">{{currComp.companyShortName}}</a>
  </div>
</nav>
