import { District } from './../../dtos/district';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of, forkJoin } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { Vendor } from '../../dtos/vendor';
import { PDFReports } from '../../dtos/pdf-report';
import { Recipe } from '../../dtos/recipe';
import { RecipeLine } from '../../dtos/recipe-line';
import { RecipeRate } from '../../dtos/recipeRate';
import { PriceFileService } from './price-file.service';
import { RecipeSelling } from '../../dtos/recipeSelling';
import { RecipeTypeEnum } from '../../dtos/recipe-type.enum';
import { MaintenanceService } from './maintenance.service';
import { RecipeLineLength } from '../../dtos/recipe-line-length';
import { PriceFileItem } from '../../dtos/price-file-item';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { RecipePriceFileItem } from '../../dtos/recipe-pricefile-item';
import { CompanyService } from './company.service';
import { CompanyMarginService } from './company-margin.service';
import { MarginTypeEnum } from '../../dtos/margin-type.enum';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class EstimatingService {
  loadingCostCentres: boolean;
  recipes: Recipe[] = [];
  recipeLines: RecipeLine[] = [];
  costingDate: Date;
  recipeRates: RecipeRate[] = [];
  cachCompanyRecipeRates: string;
  currentCostingDateString: string;
  recipeSellingRates: RecipeSelling[] = [];
  cachCompanyRecipeSellingRates: string;
  recipeGroups: Recipe[] = [];
  recipeGroupsFiltered: Recipe[] = [];
  globalRecipeSearchString: any;
  recipesCompany: string;
  recipeLinesCompany: string;

  // globalRecipeSearchStringChanged = new EventEmitter<string>();

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private priceFileService: PriceFileService,
    private maintenanceService: MaintenanceService,
    private companyService: CompanyService,
    private globalService: GlobalService,
    private companyMarginService: CompanyMarginService,
    private userService: UserService
  ) { }




  getRecipeData(useCache: boolean): Observable<PriceFileItem[]> {
    return forkJoin(
      [
        this.priceFileService.getPriceFileItemGroups(useCache),
        this.maintenanceService.getPhases(useCache),
        this.maintenanceService.getDistricts(useCache),
        this.maintenanceService.getUnitOfMeasures(useCache),
        this.maintenanceService.getQtySizeControls(useCache),
        this.companyService.getCurrentGST(),
        this.getRecipes(useCache),
        this.getRecipeLines(useCache),
        this.getRecipeRates(useCache),
        this.getRecipeSellingRates(useCache),
        this.companyMarginService.getCompanyMargins(MarginTypeEnum.companyMargin, true),
        this.userService.getCurrCompUsers()
      ]
    )
      .pipe(map(
        ([priceFileItems]) => {
          return priceFileItems;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getVendors(): Observable<Vendor[]> {
    return this._http.get<Vendor[]>(environment.apiUrl +
      '/vendors', this.httpService.getHttpOptions()).pipe(
        catchError(this.handleError));
  }

  addManualOrder(dataRecord: any): Observable<PDFReports> {
    const url = environment.apiUrl + '/manualorders';
    return this._http.post<PDFReports>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  getRecipeGroups(): Observable<Recipe[]> {
    if (this.recipeGroups && this.recipeGroups.length) {
      if (this.globalRecipeSearchString && this.globalRecipeSearchString.trim() !== '') {
        // find recipes with this description
        const filteredRecipes = this.recipes
          .filter(i => i.description.toUpperCase().search(this.globalRecipeSearchString.toUpperCase().trim()) >= 0
            || i.recipeCode.toUpperCase().search(this.globalRecipeSearchString.toUpperCase().trim()) >= 0);
        this.recipeGroupsFiltered = [];
        filteredRecipes.forEach(recipe => {
          this.addRecipeToFilteredList(recipe.recipeParentId);
        });
        return of(this.recipeGroupsFiltered);
      } else {
        return of(this.recipeGroups);
      }
    } else {
      let url = environment.apiUrl + '/recipes?includeRates=false&includeInactive=true';
      url += '&recipeTypeId=' + RecipeTypeEnum.Group;

      return this._http.get<Recipe[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.recipeGroups = res;
          this.recipeGroups.sort((a, b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0));
        }),
        catchError(this.handleError));
    }
  }

  addRecipeToFilteredList(parentId: number) {
    if (parentId) {
      const recipeParent = this.recipeGroupsFiltered.find(i => i.id === parentId);
      if (!recipeParent) {
        const recipeToAdd = this.recipeGroups.find(i => i.id === parentId);
        if (recipeToAdd) {
          this.recipeGroupsFiltered.push(recipeToAdd);
          this.addRecipeToFilteredList(recipeToAdd.recipeParentId);
        } else {
          console.log('Recipe Group not found: ' + parentId);
        }
      }
    }
  }

  getRecipesAndItems(useCache: boolean): Observable<Recipe[]> {
    return forkJoin(
      [
        this.getRecipes(useCache),
        this.priceFileService.getPriceFileItems(useCache)
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getRecipes(useCache: boolean = false): Observable<Recipe[]> {
    if (useCache && this.recipes && this.recipes.length
      && this.recipesCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.recipes);
    } else {
      let url = environment.apiUrl + '/recipes?includeRates=false&includeInactive=true';
      url += '&recipeTypeId=' + RecipeTypeEnum.Recipe;

      return this._http.get<Recipe[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.recipes = res;
          this.recipesCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getRecipeSelling(recipeId: number): Observable<RecipeSelling[]> {
    const url = environment.apiUrl + '/recipes/' + recipeId + '/selling';
    return this._http.get<RecipeSelling[]>(url, this.httpService.getHttpOptions());
  }

  addRecipe(dataRecord: any): Observable<Recipe> {
    const url = environment.apiUrl + '/recipes';
    return this._http.post<Recipe>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateRecipe(id: string, itm: any): Observable<Recipe> {
    const url = environment.apiUrl + '/recipes/' + id;
    return this._http.patch<Recipe>(url, JSON.stringify(itm, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  deleteRecipe(id: string, deleteLines: boolean) {
    const url = environment.apiUrl + '/recipes/' + id + '?deleteLines=' + deleteLines;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  copyRecipe(recipeId: number, newCode: string): Observable<Recipe> {
    const url = environment.apiUrl + '/recipes/' + recipeId + '/copy';
    return this._http.post<Recipe>(url, JSON.stringify({ newCode: newCode }), this.httpService.getHttpOptions());
  }

  moveRecipe(id: number, parentId: number, orderNo: number) {
    let url = environment.apiUrl + '/recipes/move/' + id;
    if (parent) {
      url += '?recipeParentId=' + parentId + '&orderNumber=' + orderNo;
    } else {
      url += '?&orderNumber=' + orderNo;
    }

    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }

  setMarkUps(markupId: number, selectedIds: number[]): Observable<Recipe> {
    const url = environment.apiUrl + '/recipes/update-markup?markupId=' + markupId;
    return this._http.patch<Recipe>(url, JSON.stringify({ ids: selectedIds }), this.httpService.getHttpOptions());
  }

  setActiveNotActive(setActive: boolean, selectedIds: number[]): Observable<Recipe> {
    const url = environment.apiUrl + '/recipes/update-active?setActive=' + setActive;
    return this._http.patch<Recipe>(url, JSON.stringify({ ids: selectedIds }), this.httpService.getHttpOptions());
  }

  recostRecipes(updateOptionLists: boolean, costingDate: Date, recipeParentID: number, recipeId: number) {
    let url = environment.apiUrl + '/recipes/recost';
    if (updateOptionLists) {
      url += '?updateOptionLists=true';
    }
    return this._http.patch(url,
      JSON.stringify({ costingDate: costingDate, recipeParentID: recipeParentID, recipeId: recipeId }, this.httpService.jsonReplacer),
      this.httpService.getHttpOptions());
  }

  findAndReplaceItem(oldPriceFileItemId: number, newPriceFileItemId: number, oldRecipeId: number, newRecipeId: number,
     recipeParentId: number, updateLockedRecipes: boolean,
     explodeAddedRecipe: boolean, deleteZeroQtyItemsAfterExplode: boolean) {
    let url = environment.apiUrl + '/recipe-lines/replace-item?updateLockedRecipes=' + updateLockedRecipes;
    if (oldPriceFileItemId) {
      url += '&oldPriceFileItemId=' + oldPriceFileItemId;
    }
    if (newPriceFileItemId) {
      url += '&newPriceFileItemId=' + newPriceFileItemId;
    }
    if (oldRecipeId) {
      url += '&oldRecipeId=' + oldRecipeId;
    }
    if (newRecipeId) {
      url += '&newRecipeId=' + newRecipeId;
    }
    if (recipeParentId) {
      url += '&recipeParentId=' + recipeParentId;
    }
    if (explodeAddedRecipe) {
      url += '&explodeAddedRecipe=' + explodeAddedRecipe;
    }
    if (deleteZeroQtyItemsAfterExplode) {
      url += '&deleteZeroQtyItemsAfterExplode=' + deleteZeroQtyItemsAfterExplode;
    }

    return this._http.patch(url, JSON.stringify({}), this.httpService.getHttpOptions());
  }

  postUploadtemplate(recipeParentId: number, xlFile, deleteExisting: boolean) {
    const options = this.httpService.getHttpFileOptions();
    return this._http.post(environment.apiUrl
      + '/recipe-load/excel/' + recipeParentId + '?deleteExisting=' + deleteExisting, xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  postUploadLinestemplate(xlFile, recipeParentId: number, skipMissingRecipes: boolean, ignoreZeroQuantityItems: boolean) {
    let url = environment.apiUrl + '/recipe-line/excel-upload';
    url += '?skipMissingRecipes=' + skipMissingRecipes;
    url += '&ignoreZeroQuantityItems=' + ignoreZeroQuantityItems;

    if (recipeParentId) {
      url += '&recipeParentId=' + recipeParentId;
    }

    return this._http.post(url, xlFile, this.httpService.getHttpFileOptions())
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  postUploadTakeOff(xlFile, recipeId: number, deleteExistingLines: boolean) {
    const options = this.httpService.getHttpFileOptions();
    const url = environment.apiUrl + '/recipe-line/cad-upload?recipeId=' + recipeId + '&deleteExistingLines=' + deleteExistingLines;
    return this._http.post(url, xlFile, options)
      .pipe(
        catchError(this.handleError.bind(this)));
  }

  getRecipeLines(useCache: boolean): Observable<RecipeLine[]> {
    if (useCache && this.recipeLines && this.recipeLines.length
      && this.recipeLinesCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.recipeLines);
    }
    const url = environment.apiUrl + '/recipes/all-lines';
    return this._http.get<RecipeLine[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.recipeLines = res;
        this.recipeLinesCompany = this.globalService.getCurrentCompanyId();
        // set up entries for group headings
        // res.forEach(recipeLine => {
        //   if (recipeLine.recipeItemId) {
        //     recipeLine.masterGroupCostCentre = '000000;RECIPES';
        //     const recipe = this.recipes.find(i => i.id === recipeLine.recipeItemId);
        //     console.log(recipe);
        //     const recipeParent = this.recipeGroups.find(i => i.id === recipe?.recipeParentId);
        //     if (recipeParent) {
        //       console.log(recipeParent);
        //       recipeLine.subGroupItemDesc = ('00000' + recipeParent.orderNumber.toString()).slice(-6) + ';' + recipeParent.description;
        //     } else {
        //       recipeLine.subGroupItemDesc = '000000;Not Found';
        //     }
        //   } else if (recipeLine.priceFileItemId) {
        //     const priceFileItem = this.priceFileService.priceFileItems.find(i => i.id === recipeLine.priceFileItemId);
        //     const subGroup = this.priceFileService.priceFileItemGroups.find(i => i.id === priceFileItem?.priceFileItemParentId);
        //     recipeLine.subGroupItemDesc = ('00000' + subGroup?.orderNumber.toString()).slice(-6) + ';' + subGroup?.description;
        //     const masterGroup = this.priceFileService.priceFileItemGroups.find(i => i.id === subGroup?.priceFileItemParentId);
        //     recipeLine.masterGroupCostCentre = 'I'
        //       + ('00000' + masterGroup?.orderNumber.toString()).slice(-6) + ';' + masterGroup?.description;
        //   } else {
        //     recipeLine.masterGroupCostCentre = '100000;Ad-Hoc';
        //     recipeLine.subGroupItemDesc = '00000;Items';
        //   }
        // });
      }),
      catchError(this.handleError));
  }

  addRecipeLine(recipeParentId: number, dataRecord: any): Observable<RecipeLine> {
    const url = environment.apiUrl + '/recipes/' + recipeParentId + '/lines';
    return this._http.post<RecipeLine>(url, JSON.stringify(dataRecord, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  updateRecipeLine(id: string, itm: any): Observable<RecipeLine> {
    const url = environment.apiUrl + '/recipe-lines/' + id;
    return this._http.patch<RecipeLine>(url, JSON.stringify(itm, this.httpService.jsonReplacer), this.httpService.getHttpOptions());
  }

  deleteRecipeLine(id: string) {
    const url = environment.apiUrl + '/recipe-lines/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  getRecipeRates(useCache: boolean): Observable<RecipeRate[]> {
    return of(this.recipeRates); // not used
    // if (useCache && this.recipeRates && this.recipeRates.length
    //   && this.cachCompanyRecipeRates === this.globalService.getCurrentCompanyId()) {
    //   return of(this.recipeRates);
    // } else {
    //   const url = environment.apiUrl + '/recipes/all-rates';
    //   return this._http.get<RecipeRate[]>(url, this.httpService.getHttpOptions()).pipe(
    //     tap(res => {
    //       this.recipeRates = res;
    //       this.cachCompanyRecipeRates = this.globalService.getCurrentCompanyId();

    //       // // sort so we can get the first one matching the effective date
    //       // this.recipeRates
    //       //   .sort(this.globalService.sortBy('recipeId', this.globalService.sortBy('effectiveDate', false, false)));
    //     }),
    //     catchError(this.handleError));
    // }
  }

  getRecipeSellingRates(useCache: boolean): Observable<RecipeSelling[]> {
    if (useCache && this.recipeSellingRates && this.recipeSellingRates.length
      && this.cachCompanyRecipeSellingRates === this.globalService.getCurrentCompanyId()) {
      return of(this.recipeSellingRates);
    } else {
      const url = environment.apiUrl + '/recipes/all-selling-rates';
      return this._http.get<RecipeSelling[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.recipeSellingRates = res; this.cachCompanyRecipeSellingRates = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  explodeRecipeLine(recipeLineId: number) {
    const url = environment.apiUrl + '/recipe-lines/' + recipeLineId + '/explode';
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  explodeRecipe(recipeId: number, ignoreEmptyRecipes: boolean): Observable<string> {
    let url = environment.apiUrl + '/recipe/' + recipeId + '/explode';

    if (ignoreEmptyRecipes) {
      url += '?ignoreEmptyRecipes=true';
    }
    return this._http.delete<string>(url, this.httpService.getHttpOptions());
  }


  explodeRecipeInMemory(recipeId: number): Observable<RecipeLine[]> {
    const url = environment.apiUrl + '/recipe/' + recipeId + '/explode-in-memory';
    return this._http.get<RecipeLine[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  // Length records
  getRecipeLineLengths(recipeLineId: number): Observable<RecipeLineLength[]> {
    const url = environment.apiUrl + '/recipe-line/' + recipeLineId + '/lengths';
    return this._http.get<RecipeLineLength[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  addRecipeLineLength(dataRecord: any): Observable<RecipeLineLength> {
    const url = environment.apiUrl + '/recipe-line-lengths';
    return this._http.post<RecipeLineLength>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateRecipeLineLength(recipeLineLengthId: string, itm: any): Observable<RecipeLineLength> {
    const url = environment.apiUrl + '/recipe-line-lengths/' + recipeLineLengthId;
    return this._http.patch<RecipeLineLength>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteRecipeLineLength(recipeLineLengthId: string): Observable<RecipeLineLength> {
    const url = environment.apiUrl + '/recipe-line-lengths/' + recipeLineLengthId;
    return this._http.delete<RecipeLineLength>(url, this.httpService.getHttpOptions());
  }


  getPriceFileItemsData(useCache: boolean): Observable<PriceFileItem[]> {
    return forkJoin(
      [
        this.priceFileService.getPriceFileItemGroups(useCache),
        this.priceFileService.getPriceFileItems(useCache),
        this.getRecipeLines(useCache),
        this.getRecipes(true),
        this.maintenanceService.getPhases(useCache),
        this.userService.getCurrCompUsers(),
      ]
    )
      .pipe(map(
        ([result]) => {
          return result;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }


  addMultipleRecipeLines(recipeId: number, selectedItems: any) {
    const url = environment.apiUrl + '/recipe-lines/' + recipeId + '/multiple-items';
    return this._http.post(url, JSON.stringify({ 'selectedItems': selectedItems }), this.httpService.getHttpOptions());
  }


  calcRecipeRate(recipeId: number, district: District, useScaling: boolean, recost: boolean = false): number {
    let rate = 0;
    let itemRate = 0;
    let validRate = true;

    const recipe = this.recipes.find(i => i.id === recipeId);
    if (!recipe || !recipe.isActive) {
      return null;
    }

    if (!recost && recipe.rate && recipe.districtCostedId === district.id) {
      return recipe.rate;
    }

    const recipeLinesForRecipe = this.recipeLines.filter(w => w.recipeId === recipeId);

    if (recipeLinesForRecipe && recipeLinesForRecipe.length) {
      // we calc the price
      recipeLinesForRecipe.forEach(recipeLine => {
        if (validRate) {
          if (recipeLine.priceFileItemId) {
            // we find the rate for the item
            if (recipeLine.quantity != null && recipeLine.quantity !== 0) {
              itemRate = this.priceFileService.getDistrictPreferredRate(district,
                recipeLine.priceFileItemId, this.currentCostingDateString);
              if (itemRate === null) {
                validRate = false;
              } else if (recipeLine.unitOfMeasureId) {
                const unitOfMeasure = this.maintenanceService.unitOfMeasures.find(i => i.id === recipeLine.unitOfMeasureId);
                if (unitOfMeasure && unitOfMeasure.costIsPer) {
                  itemRate /= unitOfMeasure.costIsPer;
                }
              }
            }
          } else if (recipeLine.recipeItemId) {
            // we have a sub - recipe
            itemRate = this.calcRecipeRate(recipeLine.recipeItemId, district, false);
            if (itemRate === null) {
              validRate = false;
            } else {
              // we get the scaling - divided by...
              const lineRecipe = this.recipes.find(i => i.id === recipeLine.recipeItemId);
              if (lineRecipe) {
                itemRate = lineRecipe.scale ? (itemRate / lineRecipe.scale) : itemRate;
              }
              const unitOfMeasure = this.maintenanceService.unitOfMeasures.find(i => i.description === lineRecipe.unitOfMeasure);
              if (unitOfMeasure && unitOfMeasure.costIsPer) {
                itemRate /= unitOfMeasure.costIsPer;
              }
            }
          } else {
            // we already have the rate so we use that
            itemRate = recipeLine.rate;
            if (recipeLine.unitOfMeasureId) {
              const unitOfMeasure = this.maintenanceService.unitOfMeasures.find(i => i.id === recipeLine.unitOfMeasureId);
              if (unitOfMeasure && unitOfMeasure.costIsPer) {
                itemRate /= unitOfMeasure.costIsPer;
              }
            }
          }

          // now calc
          if (validRate && itemRate && recipeLine.quantity) {
            rate += itemRate * recipeLine.quantity;
          }
        }
      });
    }

    if (validRate) {
      if (useScaling) {
        // we get the scaling - divided by...
        if (recipe) {
          rate = recipe.scale ? (rate / recipe.scale) : rate;
        }
      }

      recipe.districtCostedId === district.id // so we don't have to recost
      return rate; // Math.round((rate + Number.EPSILON) * 100) / 100;
    } else {
      return null;
    }
  }

  getRecipeLinesForDistrict(district: District, calcRate: boolean = true): RecipePriceFileItem[] {
    let recipePriceFileItems: RecipePriceFileItem[] = [];

    this.recipes.forEach(recipe => {
      const recipeParent = this.recipeGroups.find(i => i.id === recipe.recipeParentId);

      if (!recipeParent) {
        let check = true; // allows us to catch where the data is wrong
      }

      recipePriceFileItems.push(this.createRecipePriceFileItem(recipe, calcRate, district));
    });

    // we get the items with appropriate rates
    this.priceFileService.priceFileItems.forEach(item => {
      const units = this.maintenanceService.unitOfMeasures.find(i => i.id === item.unitOfMeasureId);
      const districtRate = calcRate ? this.priceFileService
        .getDistrictPreferredRate(district, item.id, this.currentCostingDateString) : 0;

      // const itemGroup = this.priceFileService.priceFileItemGroups.find(i => i.id === item.priceFileItemParentId);
      // const itemGroupDesc = itemGroup?.priceFileCode ? itemGroup.priceFileCode + ' - ' + itemGroup.description : itemGroup?.description;

      // const costCentre = this.priceFileService.priceFileItemGroups.find(i => i.id === itemGroup?.priceFileItemParentId);
      // const costCentreDesc = costCentre?.priceFileCode ? costCentre.priceFileCode + ' - ' + costCentre?.description
      //   : costCentre?.description;

      recipePriceFileItems.push({
        recipeCode: item.priceFileCode,
        recipeId: null,
        priceFileItemId: item.id,
        description: item.description,
        comment: item.comment,
        unitOfMeasure: units ? units.description : '',
        rate: districtRate,
        masterGroupCostCentre: item.costCentre, // 'I' + ('00000' + costCentre?.orderNumber.toString()).slice(-6) + ';' + costCentreDesc,
        subGroupItemDesc: item.itemGroup, // ('00000' + itemGroup.orderNumber.toString()).slice(-6) + ';' + itemGroupDesc,
        quantity: null,
        isActive: item.isActive //itemGroup.isActive && costCentre.isActive && item.isActive
      });
    });

    return recipePriceFileItems;
  }

  createRecipePriceFileItem(recipe: Recipe, calcRate: boolean, district: District): RecipePriceFileItem {
    const recipeParent = this.recipeGroups.find(i => i.id === recipe.recipeParentId);
    return {
      recipeCode: recipe.recipeCode,
      recipeId: recipe.id,
      priceFileItemId: null,
      description: recipe.description,
      comment: '',
      unitOfMeasure: recipe.unitOfMeasure,
      rate: calcRate ? recipe.isActive && recipe.rate && recipe.districtCostedId == district.id ? recipe.rate : this.calcRecipeRate(recipe.id, district, true) : 0,
      masterGroupCostCentre: '000000;RECIPES',
      subGroupItemDesc: '000000;' + recipeParent?.description,
      quantity: null,
      isActive: recipe.isActive
    };
  }

  getRecipeLinesForRecipe(id: number, currentDistrict: District): RecipeLine[] {
    // deep copy the array as we want to change rates for the district
    const myRecipeLines = this.recipeLines.filter(recipeLine => recipeLine.recipeId === id).map(a => ({ ...a }));

    myRecipeLines.forEach(recipeLine => {
      if (recipeLine.recipeItemId) {
        // we deep calc the rate for this recipe
        recipeLine.rate = this.calcRecipeRate(recipeLine.recipeItemId, currentDistrict, true);

        // get the units of measure
        const recipe = this.recipes.find(i => i.id === recipeLine.recipeItemId);
        recipeLine.unitOfMeasureId = this.maintenanceService.unitOfMeasures.find(i => i.description === recipe.unitOfMeasure)?.id;

        recipeLine.recipeCode = recipe.recipeCode;
      } else if (recipeLine.priceFileItemId) {
        // we get the rate for this item for the date and district
        recipeLine.rate = this.priceFileService
          .getDistrictPreferredRate(currentDistrict, recipeLine.priceFileItemId, this.currentCostingDateString);

        // get the units of measure
        const priceFileItem = this.priceFileService.priceFileItems.find(i => i.id === recipeLine.priceFileItemId);
        const units = this.maintenanceService.unitOfMeasures.find(i => i.id === priceFileItem?.unitOfMeasureId);
        recipeLine.unitOfMeasureId = units?.id;
        recipeLine.recipeCode = priceFileItem?.priceFileCode;
      } else {
        // we leave the rate as is as must be a manual item
      }
    });
    return myRecipeLines;
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
