import { Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { CompanyMargin } from '../../dtos/company-margin';
import { UtilsService } from '../utils.service';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyMarginService {

  // cache of all types for margins tab
  cache: CompanyMargin[];
  cachCompany: string;
  // set by recipe-classes component and used by recipes component. specific type of margins.
  companyMarginsForRecipes: CompanyMargin[];
  companyMarginsCompany: string;

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private utilsService: UtilsService
  ) { }

  getAllCompanyMargins(useCache: boolean = true, activeOnly = false) {
    if (useCache && this.cache && this.cache.length && this.cachCompany === this.globalService.getCurrentCompanyId()) {
      return of(this.cache);
    } else {
      const marginType = 0;
      const url = environment.apiUrl + '/company-margins?marginTypeId=' + marginType + '&activeOnly=' + activeOnly;
      return this.http.get<CompanyMargin[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.cache = res; this.cachCompany = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getCompanyMargins(marginTypeId: number, activeOnly = false) {
    const url = environment.apiUrl + '/company-margins?marginTypeId=' + marginTypeId + '&activeOnly=' + activeOnly;
    return this.http.get<CompanyMargin[]>(url, this.httpService.getHttpOptions()).pipe(
      tap(res => {
        this.companyMarginsForRecipes = res;
        this.companyMarginsCompany = this.globalService.getCurrentCompanyId();
      }),
      catchError(this.handleError));
  }

  addCompanyMargin(companyMargin: CompanyMargin) {
    const url = environment.apiUrl + '/company-margins';
    return this.http.post<CompanyMargin>(url, JSON.stringify(companyMargin), this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
          this.cache.push(res);
        })
      );
  }

  updateCompanyMargin(id: string, updatedRecord: any) {
    const url = environment.apiUrl + '/company-margins/' + id;
    return this.http.patch(url, JSON.stringify(updatedRecord), this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
          this.utilsService.editCache(res, this.cache);
        })
      );
  }

  deleteCompanyMargin(id: string) {
    const url = environment.apiUrl + '/company-margins/' + id;
    return this.http.delete(url, this.httpService.getHttpOptions())
      .pipe(
        tap(res => {
          this.utilsService.deleteFromCache(id, this.cache);
        })
      );
  }

  getCentralCompanyMargins(): Observable<CompanyMargin[]> {
    const url = environment.apiUrl + '/company-margins/central-company';

    return this.http.get<CompanyMargin[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
