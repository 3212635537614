import { AuthService } from './../services/auth.service';
import { PriceFileService } from './../services/felixApi/price-file.service';
import { Component, OnInit, OnDestroy, Input, OnChanges, ViewChild, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { EstimatingService } from '../services/felixApi/estimating.service';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { Recipe } from '../dtos/recipe';
import { RecipeTypeEnum } from '../dtos/recipe-type.enum';
import { NotificationService } from '../services/notification.service';
import { GlobalService } from '../services/global.service';
import { CompanyMarginService } from '../services/felixApi/company-margin.service';
import { CompanyMargin } from '../dtos/company-margin';
import { District } from '../dtos/district';
import { RecipeCostingVendorType } from '../dtos/recipeCostingVendorType';
import { RecipeRate } from '../dtos/recipeRate';
import { RecipeCostingVendorTypeEnum } from '../dtos/recipeCostingVendorType-enum';
import { UnitOfMeasure } from '../dtos/unitOfMeasure';
import { CompanyService } from '../services/felixApi/company.service';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { debounceTime } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FindWhereUsedComponent } from './find-where-used/find-where-used.component';
import { RecipeToolboxComponent } from './recipe-toolbox/recipe-toolbox.component';
import { CopyRecipeComponent } from './copy-recipe/copy-recipe.component';
import { GridService } from '../services/grid.service';
import { UserService } from '../services/felixApi/user.service';
import { User } from '../dtos/user';

@Component({
  selector: 'js-recipes',
  templateUrl: './recipes.component.html',
  styleUrls: ['./recipes.component.scss']
})

export class RecipesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() recipeParentId: number;
  @Input() refreshRecipeComponent: boolean;

  @Output() refreshFromFind: EventEmitter<Recipe> = new EventEmitter<Recipe>();

  @ViewChild('recipesGrid') recipesGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: any;
  recipeTypeEnum = RecipeTypeEnum;
  loading = true;
  popupVisible = false;
  uploadPopupHeight: number;
  files: any[] = [];
  deleteExisting = false;
  filteredRecipes: Recipe[];
  companyMargins: CompanyMargin[];
  marginDropDownOptions: any;
  marginCleared = false;
  districts: District[];
  recipeCostingVendorTypes: RecipeCostingVendorType[] = [
    { id: 1, description: 'Preferred' }
  ];
  myRecipes: Recipe[];
  searchText: string;
  reCostingDate: Date;
  updateOptionLists = false;
  selectedRowKeys: any[] = [];
  excelLoadTypes = [
    { id: 1, description: 'Recipe Headers' },
    { id: 2, description: 'Recipe Lines' }
  ];
  excelLoadTypeId: number;
  unitOfMeasures: UnitOfMeasure[];
  recipeToDeleteId: number;
  deletePopupVisible: boolean;
  deleteLines: boolean;
  recipeToDeleteDesc: string;
  skipMissingRecipes = false;
  loadToCurrentClassOnly = false;
  ignoreZeroQuantityItems = false;
  recipesWrite: boolean;
  recipesAdmin: boolean;
  descWidth: number;
  recipeDistrictSelected: number;
  users: User[];

  constructor(
    private estimatingService: EstimatingService,
    private authService: AuthService,
    private maintenanceService: MaintenanceService,
    private notiService: NotificationService,
    private companyMarginService: CompanyMarginService,
    private priceFileService: PriceFileService,
    private globalService: GlobalService,
    private companyService: CompanyService,
    private modalService: NgbModal,
    public gridService: GridService,
    private userService: UserService
  ) {
    this.onMarginValueChanged = this.onMarginValueChanged.bind(this);
    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.initNewRow = this.initNewRow.bind(this);
    this.onReorder = this.onReorder.bind(this);
    this.calculateSellingPriceNow = this.calculateSellingPriceNow.bind(this);
    this.copyRecipe = this.copyRecipe.bind(this);
    this.onLoadTypeChanged = this.onLoadTypeChanged.bind(this);
    this.onRecipeCodeClick = this.onRecipeCodeClick.bind(this);
    this.marginDropDownOptions = { width: 300 };
  }


  ngOnInit(): void {
    this.companyMargins = this.companyMarginService.companyMarginsForRecipes;
    this.unitOfMeasures = this.maintenanceService.unitOfMeasures;
    this.districts = this.maintenanceService.districts;
    this.users = this.userService.users;

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Recipes') === 'Write') {
      this.recipesWrite = true;
    }

    if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Recipes') === 'Admin') {
      this.recipesWrite = true;
      this.recipesAdmin = true;
    }

    this.setWidth();

    this.subscriptions.push(
      this.globalService.innerWidthChanged.pipe(debounceTime(100)).subscribe(() => {
        this.setWidth();
      })
    );
  }

  setWidth() {
    this.descWidth = window.innerWidth < 1707 ? 65 : window.innerWidth - 1642;

    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 100); // wait for grid
  }

  ngOnChanges(): void {
    this.getAllRecipeLines();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getAllRecipeLines() {
    // read all recipes
    this.subscriptions.push(
      this.estimatingService.getRecipeLines(true)
        .subscribe({
          next: (recipeLines) => {
            this.estimatingService.recipeLines = recipeLines;
            this.getAllRecipes();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getAllRecipes() {
    // read all recipes
    this.subscriptions.push(
      this.estimatingService.getRecipes(true)
        .subscribe({
          next: () => {
            this.dataSource = null;
            this.getAllRecipeSellingRates();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getAllRecipeSellingRates() {
    // read all recipe rates
    this.subscriptions.push(
      this.estimatingService.getRecipeSellingRates(true)
        .subscribe({
          next: () => {
            this.setUpDataSet();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  getRecipesForParent(): Recipe[] {
    this.myRecipes = this.estimatingService.recipes.filter(recipe => recipe.recipeParentId === this.recipeParentId);
    let district = this.maintenanceService.districts.find(i => i.districtParentId === null); // initialise

    this.myRecipes.forEach(recipe => {
      if (!recipe.rate || district.id !== recipe.districtId) {
        // we deep calc the rate for this recipe
        if (district.id !== recipe.districtId) {
          district = this.maintenanceService.districts.find(i => i.id === recipe.districtId);
        }

        recipe.rate = this.estimatingService.getRecipeRate(recipe.id, district, false, false, recipe);
      }

      if (recipe.rate) {
        recipe.rate = Math.round(recipe.rate * 100) / 100;
      }

      const recipeSelling = this.estimatingService.recipeSellingRates
        .find(i => i.recipeId === recipe.id
          && i.districtId === recipe.districtId);

      if (recipeSelling) {
        recipe.sellingPrice = recipeSelling.rate;
        recipe.sellingPriceLastUpdated = recipeSelling.effectiveDate;
      } else {
        recipe.sellingPrice = null;
        recipe.sellingPriceLastUpdated = null;
      }
    });

    this.searchText = this.estimatingService.globalRecipeSearchString;
    this.estimatingService.globalRecipeSearchString = '';
    return this.myRecipes;
  }

  updateRecipe(id: number, values, recipeRateId: number) {
    // update master list
    const recipe = this.estimatingService.recipes.find(i => i.id === id);
    this.updateRecipeValues(recipe, values, recipeRateId);
  }

  updateRecipeValues(recipe: Recipe, values, recipeRateId: number) {
    if (recipe) {
      if (values.recipeCode !== undefined) {
        recipe.recipeCode = values.recipeCode;
      }
      if (values.description !== undefined) {
        recipe.description = values.description;
      }
      if (values.unitOfMeasure !== undefined) {
        recipe.unitOfMeasure = values.unitOfMeasure;
      }
      if (values.rate !== undefined) {
        recipe.rate = values.rate;

        // we need to update the recipeRate record
        this.updateRecipeRate(recipe, values.rate, recipeRateId);
      }
      if (values.scale !== undefined) {
        recipe.scale = values.scale;
      }
      if (values.companyMarginId !== undefined) {
        recipe.companyMarginId = values.companyMarginId;
      }
      if (values.isGSTFree !== undefined) {
        recipe.isGSTFree = values.isGSTFree;
      }
      if (values.sellingPrice !== undefined) {
        recipe.sellingPrice = values.sellingPrice;
      }
      if (values.sellingPriceLastUpdated !== undefined) {
        recipe.sellingPriceLastUpdated = values.sellingPriceLastUpdated;
      }
      if (values.districtId !== undefined) {
        recipe.districtId = values.districtId;
      }
      if (values.costingDate !== undefined) {
        recipe.costingDate = values.costingDate;
      }
      if (values.recipeCostingVendorTypeId !== undefined) {
        recipe.recipeCostingVendorTypeId = values.recipeCostingVendorTypeId;
      }
      if (values.isActive !== undefined) {
        recipe.isActive = values.isActive;
      }
      if (values.isLocked !== undefined) {
        recipe.isLocked = values.isLocked;
      }
    }
  }

  updateRecipeRate(recipe: Recipe, rate: number, recipeRateId: number) {
    const recipeRate = this.estimatingService.recipeRates
      .find(i => i.recipeId === recipe.id && i.districtId === recipe.districtId
        && i.effectiveDate
        && i.effectiveDate.toString().slice(0, 10) === this.estimatingService.currentCostingDateString);

    if (recipeRate) {
      // update it
      recipeRate.rate = rate;
    } else {
      // insert it
      this.insertRecipeRate(recipe, rate, recipeRateId);
    }
  }

  insertRecipeRate(recipe: Recipe, rate: number, recipeRateId: number) {
    if (recipeRateId) {
      const newRecipeRate: RecipeRate = {
        id: recipeRateId,
        recipeId: recipe.id,
        districtId: recipe.districtId,
        effectiveDate: this.estimatingService.currentCostingDateString,
        rate: rate,
      };

      this.estimatingService.recipeRates = this.estimatingService.recipeRates.concat(newRecipeRate);

      // sort so we can get the first one matching the effective date
      this.estimatingService.recipeRates
        .sort(this.globalService.sortBy('recipeId', this.globalService.sortBy('effectiveDate', false, false)));
    }
  }

  insertRecipe(recipe: Recipe, values) {
    const newRecipe: Recipe = {
      id: recipe.id,
      recipeParentId: this.recipeParentId,
      recipeTypeId: this.recipeTypeEnum.Recipe,
      orderNumber: 1,
      recipeCode: values.recipeCode,
      description: values.description,
      unitOfMeasure: values.unitOfMeasure,
      rate: values.rate,
      scale: values.scale,
      rateLastUpdatedDate: new Date(),
      isActive: values.isActive,
      children: [],
      companyMarginId: values.companyMarginId,
      isGSTFree: false,
      sellingPrice: null,
      sellingPriceLastUpdated: null,
      districtId: values.districtId,
      costingDate: values.costingDate,
      recipeCostingVendorTypeId: values.recipeCostingVendorTypeId,
      recipeRateId: recipe.recipeRateId,
      isAdminOnly: false,
      isLocked: values.isLocked,
      districtCostedId: null,
      modifiedDate: recipe.modifiedDate,
      modifiedUserId: recipe.modifiedUserId
    };

    this.estimatingService.recipes.push(newRecipe);
    if (this.maintenanceService.recipePriceFileItems) {
      const recipeParent = this.estimatingService.recipeGroups
        .find(i => i.id === recipe.recipeParentId);
      this.maintenanceService.recipePriceFileItems
        .push(this.estimatingService
          .createRecipePriceFileItem(newRecipe, false, this.districts.find(i => i.id === newRecipe.districtId), recipeParent));
    }
    this.insertRecipeRate(newRecipe, newRecipe.rate, newRecipe.recipeRateId);
  }

  setUpDataSet() {
    this.loading = false;
    this.dataSource = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.getRecipesForParent(),
      insert: async (values) => {
        values.recipeParentId = this.recipeParentId;
        values.recipeTypeId = 2;
        values.recipeCode = values.recipeCode.toUpperCase();
        values.costingDate = this.estimatingService.costingDate;
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.estimatingService.addRecipe(values).subscribe({
              next: (res) => {
                this.insertRecipe(res, values);
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      update: async (key, values) => {
        if (values.recipeCode) {
          values.recipeCode = values.recipeCode.toUpperCase();
        }
        if (values.rate) {
          values.costingDate = this.estimatingService.costingDate;
        }
        if (this.marginCleared) {
          values.companyMarginId = null;
          this.marginCleared = false;
        }
        return new Promise((resolve, reject) => {
          this.subscriptions.push(
            this.estimatingService.updateRecipe(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                this.updateRecipe(key, values, res.recipeRateId);
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          );
        });
      }
    });
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          width: 140,
          text: 'Load From Excel',
          onClick: this.loadFromExcel.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          text: 'Reset Layout',
          onClick: this.clearStatePersistanceGo.bind(this)
        }
      },
      {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'toolbox',
          onClick: this.openToolBox.bind(this)
        }
      });
  }

  loadFromExcel() {
    this.popupVisible = true;
    this.excelLoadTypeId = 1;
    this.uploadPopupHeight = 400;
    this.files = [];
  }

  onLoadTypeChanged(e) {
    if (e.selectedItem.id === 2) {
      this.uploadPopupHeight = 450;
    } else {
      this.uploadPopupHeight = 400;
    }
  }

  clearStatePersistanceGo() {
    this.loading = true;
    localStorage.removeItem('recipes-grid');
    setTimeout(() => {
      this.loading = false;
    }, 250);
  }

  uploadClick() {
    // load the file
    if (this.files && this.files.length) {
      if (this.excelLoadTypeId === 1) {
        this.loading = true;
        this.popupVisible = false;
        const formData: FormData = new FormData();
        formData.append('image', this.files[0], this.files[0].name);

        this.subscriptions.push(
          this.estimatingService.postUploadtemplate(this.recipeParentId, formData, this.deleteExisting).subscribe({
            next: () => {
              this.estimatingService.recipes = null;
              this.estimatingService.recipeRates = [];
              this.files = [];
              this.getAllRecipes();
            }, error: (err) => {
              this.notiService.notify(err);
              this.files = [];
              this.loading = false;
            }
          })
        );
      } else {
        this.loading = true;
        this.popupVisible = false;
        const formData: FormData = new FormData();
        formData.append('image', this.files[0], this.files[0].name);

        if (this.excelLoadTypeId === 2) {
          this.subscriptions.push(
            this.estimatingService
              .postUploadLinestemplate(formData,
                this.loadToCurrentClassOnly ? this.recipeParentId : null,
                this.skipMissingRecipes,
                this.ignoreZeroQuantityItems)
              .subscribe({
                next: () => {
                  this.estimatingService.recipeLines = [];
                  this.files = [];
                  this.getAllRecipeLines();
                }, error: (err) => {
                  this.notiService.notify(err);
                  this.files = [];
                  this.loading = false;
                }
              })
          );
        }
      }
    }
  }

  setMarginIdCellValue(rowData, value) {
    if (value) {
      rowData.companyMarginId = value.id;
      rowData.margin = value.margin;
    }
  }

  onSelectionChanged(cellInfo, e, event) {
    if (event.selectedRowKeys.length > 0) {
      cellInfo.setValue(event.selectedRowsData[0]);
      e.component.close();
    }
  }

  onMarginValueChanged(event, cellInfo) {
    if (!event.value) {
      this.marginCleared = true;
      cellInfo.setValue(null);
      cellInfo.data.companyMarginId = null;
    } else {
      this.marginCleared = false;
    }
  }

  onEditorPreparing(e: any) {
    // if locked no changes are allowed
    if (e.parentType === 'dataRow' && e.row.data.isLocked) {
      if (e.dataField === 'isLocked') {
        return;
      }
      e.editorOptions.disabled = true;;
    }

    if (e.parentType !== 'dataRow' || e.dataField !== 'rate') {
      return;
    }

    // if we have lines the rate is read only
    const recipeLine = this.estimatingService.recipeLines.find(i => i.recipeId === e.row.data.id);
    if (recipeLine) {
      e.editorOptions.disabled = true;
    }
  }

  getRate(recipe: Recipe) {
    if (!recipe.isActive) {
      return 'Inactive';
    }
    if (recipe.rate === null) {
      return 'Invalid';
    }
    return Math.round(recipe.rate * 100) / 100;
  }

  onRateCellPrepared(e) {
    if (e.rowType === 'data' && e.column.dataField === 'rate' && e.data.rate === null) {
      e.cellElement.style.color = 'red';
    }
    if (e.rowType === 'data' && e.column.dataField === 'isActive' && !e.data.isActive) {
      e.cellElement.style.backgroundColor = 'orange';
    }
    if (e.rowType === 'data' && e.column.dataField === 'isLocked' && e.data.isLocked) {
      e.cellElement.style.backgroundColor = 'orange';
    }
  }

  initNewRow(e) {
    e.data.isActive = true;
    e.data.isLocked = false;
    e.data.recipeCostingVendorTypeId = RecipeCostingVendorTypeEnum.Preferred;
    e.data.districtId = this.districts.find(i => i.districtParentId === null)?.id;
  }

  refresh(recipeId: number) {
    const recipe = this.estimatingService.recipes.find(i => i.id === recipeId);

    let district = this.maintenanceService.districts.find(i => i.districtParentId === null); // initialise

    if (district.id !== recipe.districtId) {
      district = this.maintenanceService.districts.find(i => i.id === recipe.districtId);
    }

    const newRate = this.estimatingService.getRecipeRate(recipe.id, district, false, true, recipe);

    const rowIndex = this.recipesGrid.instance.getRowIndexByKey(recipeId);

    if (recipe.rate !== newRate) {
      recipe.rate = newRate;
      this.recipesGrid.instance.cellValue(rowIndex, 'rate', recipe.rate);

      // we may need to to update the line rates
      this.maintenanceService.recipePriceFileItems
        .filter(i => i.recipeId === recipe.id)
        .forEach(line => {
          line.rate = newRate;
        });
    }
    this.recipesGrid.instance.cellValue(rowIndex, 'sellingPrice', recipe.sellingPrice);
    this.recipesGrid.instance.cellValue(rowIndex, 'sellingPriceLastUpdated', recipe.sellingPriceLastUpdated);
  }

  openToolBox() {
    // this.showToolbox = true;
    const modalRef = this.modalService.open(RecipeToolboxComponent);
    modalRef.componentInstance.recipeParentId = this.recipeParentId;

    modalRef.result.then(() => {
      this.estimatingService.recipeLines = null;
      this.estimatingService.recipes = null;
      this.loading = true;
      this.getAllRecipeLines();
    }, () => {
    });
  }

  downloadSample() {
    this.notiService.showInfo('Please wait for download to complete');
    this.subscriptions.push(
      this.priceFileService.getSampleExcelUpload(this.excelLoadTypeId + 20).subscribe({
        next: (res) => {
          saveAs(this.base64ToArrayBuffer(res.pdfReport), res.pdfReportName);
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/vnd.ms-excel' });
  }

  deleteAsk = (e) => {
    this.recipeToDeleteDesc = 'Delete ' + e.row.data.recipeCode + '?';
    this.recipeToDeleteId = e.row.data.id;
    this.deleteLines = false;
    this.deletePopupVisible = true;
  }

  deleteGo = () => {
    this.loading = true;
    this.deletePopupVisible = false;

    this.subscriptions.push(
      this.estimatingService.deleteRecipe(this.recipeToDeleteId.toString(), this.deleteLines)
        .subscribe({
          next: () => {
            const rowIndex = this.estimatingService.recipes.findIndex(i => i.id === this.recipeToDeleteId);
            this.estimatingService.recipes.splice(rowIndex, 1);

            if (this.maintenanceService.recipePriceFileItems) {
              const rowIndex2 = this.maintenanceService.recipePriceFileItems.findIndex(i => i.recipeId === this.recipeToDeleteId);
              if (rowIndex2 > -1) {
                this.maintenanceService.recipePriceFileItems.splice(rowIndex2, 1);
              }
            }
            this.setUpDataSet();
          },
          error: (err) => {
            this.notiService.notify(err);
            this.loading = false;
          }
        })
    );
  }

  onReorder() {
    this.notiService.showInfo('Drag used to move a recipe to a new class. Recipes are ordered by the code.');
  }

  calculateSellingPriceNow(data) {
    if (!data.rate || !data.companyMarginId) {
      return null;
    }

    const marginRec = this.companyMargins.find(i => i.id === data.companyMarginId && i.isActive);
    if (!marginRec) {
      return null;
    }

    var newRate = data.rate * (1 + marginRec.margin / 100) * (1 + this.companyService.globalGSTRate / 100);
    if (data.scale) {
      newRate = newRate / data.scale;
    }

    if (marginRec.roundUpToNext) {
      // round up to next increment but also check for 3 decimal places
      newRate = Math.ceil(Math.round(newRate * 1000) / 1000 / marginRec.roundUpToNext) * marginRec.roundUpToNext;
    }

    return newRate;
  }

  onRecipeCodeClick(e) {
    if (e.component.isRowExpanded(e.row.key)) {
      e.component.collapseRow(e.row.key);
    } else {
      if (this.recipeDistrictSelected !== e.row.data.districtId) {
        // collapse as we need to recalc the rates on change of district
        e.component.collapseAll(-1);
        this.recipeDistrictSelected = e.row.data.districtId;
      }
      e.component.expandRow(e.row.key);
    }
  }

  onRowExpanding(e) {
    if (!e.component.isRowExpanded(e.key)) {
      const recipe = this.estimatingService.recipes.find(i => i.id === e.key);
      if (this.recipeDistrictSelected !== recipe.districtId) {
        // collapse as we need to recalc the rates on change of district
        e.component.collapseAll(-1);
        this.recipeDistrictSelected = recipe.districtId;
      }
    }
  }

  findRecipe = (e) => {
    const modalRef = this.modalService.open(FindWhereUsedComponent, { windowClass: 'modal-edit' });
    modalRef.componentInstance.recipe = e.row.data;

    modalRef.result.then((foundRecipe) => {
      this.refreshFromFind.emit(foundRecipe);
    }, () => {
    });
  }

  copyRecipe(e) {
    const modalRef = this.modalService.open(CopyRecipeComponent);
    modalRef.componentInstance.recipe = e.row.data;

    modalRef.result.then((newRecipe) => {
      this.loading = true;
      this.estimatingService.recipeLines = [];
      this.estimatingService.recipes.push(newRecipe);
      this.getAllRecipeLines();
    }, () => {
    });
  }
}
