export class QtySizeControl {
    public id: number;
    public description: string;
    public isLengthConversion: boolean;
    public prevUnitOfMeasureId: number;
    public conversionRatio: number;
    public newUnitOfMeasureId: number;
    public min: number;
    public max: number;
    public increment: number;
    public centralCompanyId: number;

    constructor(id: number, desc: string) {
        this.description = desc;
        this.id = id;
    }
}
