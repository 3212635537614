<div class="page">
  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid #priceFileItemGrid *ngIf="!loading" id="priceFileItemGrid" [dataSource]="dataSource"
    [allowColumnResizing]="true" [allowColumnReordering]="true" [showBorders]="true" [rowAlternationEnabled]="true"
    [remoteOperations]="false" [repaintChangesOnly]="false" (onContentReady)="onContentReady($event)"
    [wordWrapEnabled]="true" (onToolbarPreparing)="onToolbarPreparing($event, 'toolbarTemplate')"
    (onRowPrepared)="onRowPrepared($event)" class="pricefilegrid-height" (onEditorPreparing)="onEditorPreparing($event)"
    (onSaving)="onSaving($event)" dateSerializationFormat="yyyy-MM-dd"
    (onExporting)="gridService.onExporting($event, 'Price-File')">

    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>

    <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
    <dxo-sorting mode='multiple'></dxo-sorting>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-export [enabled]="priceFileWrite"></dxo-export>
    <dxo-editing [mode]="editMode" [allowAdding]="false" [allowUpdating]="priceFileWrite"
      [allowDeleting]="priceFileWrite" [useIcons]="true" refreshMode='full' [newRowPosition]="newRowPosition"
      [(changes)]="changes" [(editRowKey)]="editRowKey">
    </dxo-editing>
    <dxo-scrolling mode="standard" rowRenderingMode="virtual"></dxo-scrolling>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping #expand [autoExpandAll]="false" [contextMenuEnabled]="true" expandMode="rowClick"></dxo-grouping>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="pricefilegrid"></dxo-state-storing>

    <dxi-column dataField="priceFileGroupId" dataType="number" caption="Cost Centre"
      groupCellTemplate="groupCellTemplate" [groupIndex]="0" editCellTemplate="costCentreDropDownBoxEditor"
      [calculateGroupValue]="calculateGroupValue" [setCellValue]="setMasterGroupValue">
      <dxo-lookup [dataSource]="foundItemMasterGroups" displayExpr="description" valueExpr="id"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="priceFileItemParentId" dataType="number" caption="Sub Group"
      groupCellTemplate="subGroupCellTemplate" [groupIndex]="1" [calculateGroupValue]="calculateSubGroupValue"
      editCellTemplate="subGroupDropDownBoxEditor">
      <dxo-lookup [dataSource]="getItemSubGroups" displayExpr="description" valueExpr="id"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="groupOrderNumber" dataType="number" [visible]="false"> </dxi-column>
    <dxi-column dataField="subGroupOrderNumber" dataType="number" [visible]="false"> </dxi-column>
    <dxi-column dataField="priceFileItemId" dataType="number" [visible]="false"> </dxi-column>

    <dxi-column dataField="priceFileCode" dataType="string" caption="Code" cssClass="priceFileCode" [width]="120"
      minWidth="50" sortOrder='asc'>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="description" dataType="string" [width]="350" minWidth="50"
      cellTemplate="descriptionTemplate">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="comment" dataType="string" caption="Item Comment" cellTemplate="descriptionTemplate">
    </dxi-column>

    <dxi-column dataField="unitOfMeasureId" dataType="number" caption="Units"
      [editorOptions]="{ showClearButton: true, wrapItemText: true }">
      <dxo-lookup [dataSource]="unitsOfMeasure" displayExpr="description" valueExpr="id"></dxo-lookup>
    </dxi-column>

    <dxi-column dataField="hasSizes" caption="Lengths" dataType="boolean">
    </dxi-column>

    <dxi-column dataField="isActive" caption="Active" dataType="boolean" cellTemplate="activeTemplate">
    </dxi-column>

    <dxi-column caption="Price Book">
      <dxi-column dataField="priceFileBookId" dataType="number" [visible]="false"> </dxi-column>

      <dxi-column dataField="districtId" dataType="number" caption="Book" sortOrder='asc'
        [editorOptions]="{ wrapItemText: true }">
        <dxo-lookup [dataSource]="districts" displayExpr="description" valueExpr="id"></dxo-lookup>
        <dxi-validation-rule type="required"></dxi-validation-rule>
      </dxi-column>

      <dxi-column dataField="preferredVendorId" dataType="number" caption="Book Preferred"
        [editorOptions]="{ wrapItemText: true }">
        <dxo-lookup [dataSource]="getItemVendors" displayExpr="vendorName" valueExpr="priceFileVendorId"></dxo-lookup>
      </dxi-column>

      <dxi-column caption="Vendor">
        <dxi-column dataField="priceFileVendorId" dataType="number" [visible]="false"> </dxi-column>

        <dxi-column dataField="vendorId" dataType="number" caption="Vendor" sortOrder='asc'
          [editorOptions]="{ wrapItemText: true }">
          <dxo-lookup [dataSource]="vendors" displayExpr="vendorName" valueExpr="id"></dxo-lookup>
          <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <dxi-column dataField="renegotiationDate" dataType="date" format="d/M/yy" [visible]="false"
          [calculateCellValue]="calculateRenegotiationDate" [allowEditing]="false"></dxi-column>

        <dxi-column dataField="vendorItemDescription" dataType="string" caption="Vendor's Description (if different)"
          cellTemplate="descriptionTemplate">
        </dxi-column>

        <dxi-column dataField="productCode" dataType="string">
        </dxi-column>

        <dxi-column dataField="qtySizeControlId" dataType="number" caption="Rounding"
          [editorOptions]="{ showClearButton: true, wrapItemText: true }">
          <dxo-lookup [dataSource]="qtySizeControls" displayExpr="description" valueExpr="id">
          </dxo-lookup>
        </dxi-column>

        <dxi-column caption="Rate">
          <dxi-column dataField="priceFileRateId" dataType="number" [visible]="false"> </dxi-column>

          <dxi-column dataField="effectiveDate" dataType="date" caption="Date" format="d/M/yy" sortOrder='asc'
            alignment="center" [editorOptions]="{ calendarOptions: { showTodayButton: true } }">
            <dxi-validation-rule type="required"></dxi-validation-rule>
          </dxi-column>

          <dxi-column dataField="rate" dataType="number" caption="Rate" format="#,##0.00"
            [editorOptions]="{ format: '#,##0.00' }">
          </dxi-column>

          <dxi-column dataField="estimatingExtra" dataType="number" [width]="80" format="#,##0.00"
            [editorOptions]="{ format: '#,##0.00' }">
          </dxi-column>

          <dxi-column dataField="expiryDate" dataType="date" caption="Valid Until" format="d/M/yy" alignment="center"
            [editorOptions]="{ calendarOptions: { showTodayButton: true } }">
          </dxi-column>

          <dxi-column dataField="modifiedDate" dataType="date" format="d/M/yy" [visible]="false" [allowEditing]="false">
          </dxi-column>

          <dxi-column dataField="modifiedUserId" dataType="number" caption="Modified By" [visible]="false"
            [allowEditing]="false">
            <dxo-lookup [dataSource]="users" displayExpr="fullName" valueExpr="id">
            </dxo-lookup>
          </dxi-column>
        </dxi-column>
      </dxi-column>
    </dxi-column>

    <dxi-column type="buttons" [width]="120">
      <dxi-button *ngIf="priceFileWrite" name="edit"></dxi-button>
      <dxi-button *ngIf="priceFileWrite" [visible]="isDeleteVisible" name="delete"></dxi-button>
      <dxi-button *ngIf="priceFileWrite" icon="add" [onClick]="addItem" [visible]="isAddButtonVisible"></dxi-button>
      <dxi-button [visible]="isRecipesIconVisible" icon="folder" [onClick]="showRecipes"></dxi-button>
    </dxi-column>


    <div *dxTemplate="let cell of 'descriptionTemplate'">
      <!-- <dx-text-area [(value)]="cell.value"></dx-text-area> -->
      <div style="white-space: pre-wrap;">{{cell.text}}</div>
    </div>

    <div *dxTemplate="let cell of 'activeTemplate'">
      <i *ngIf="cell.value" class="dx-icon-todo"></i>
      <i *ngIf="!cell.value" class="dx-icon-close red"></i>
    </div>

    <div *dxTemplate="let cellInfo of 'costCentreDropDownBoxEditor'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="costCentreDataSource"
        [(value)]="cellInfo.value" displayExpr="description" valueExpr="id" contentTemplate="contentTemplate">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="costCentreDataSource" [remoteOperations]="true" [height]="250"
            [selectedRowKeys]="[cellInfo.value]" [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value"
            [hoverStateEnabled]="true"
            (onSelectionChanged)="onCostCentreSelectionChanged($event.selectedRowKeys, cellInfo, e.component)">

            <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-paging [enabled]="false"></dxo-paging>

            <dxi-column dataField="priceFileCode" caption="Code" [width]="100"></dxi-column>
            <dxi-column dataField="description"></dxi-column>
            <dxi-column dataField="orderNumber" sortOrder="asc" [visible]="false"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let cellInfo of 'subGroupDropDownBoxEditor'">
      <dx-drop-down-box [dropDownOptions]="dropDownOptions" [dataSource]="priceFileItemGroups"
        [(value)]="cellInfo.value" displayExpr="description" valueExpr="id" contentTemplate="contentTemplate"
        (onOpened)="getFilteredSubGroups(cellInfo)">
        <div *dxTemplate="let e of 'contentTemplate'">
          <dx-data-grid [dataSource]="subGroupsForCostCentre" [remoteOperations]="true" [height]="250" keyExpr="id"
            [selectedRowKeys]="[cellInfo.value]" [focusedRowEnabled]="true" [focusedRowKey]="cellInfo.value"
            [hoverStateEnabled]="true"
            (onSelectionChanged)="onSubGroupSelectionChanged($event.selectedRowKeys, cellInfo, e.component)">

            <dxo-search-panel [visible]="true" [width]="130"></dxo-search-panel>
            <dxo-selection mode="single"></dxo-selection>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-paging [enabled]="false"></dxo-paging>

            <dxi-column dataField="priceFileCode" caption="Code" [width]="100"></dxi-column>
            <dxi-column dataField="description"></dxi-column>
            <dxi-column dataField="orderNumber" sortOrder="asc" [visible]="false"></dxi-column>
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>


    <div *dxTemplate="let data of 'groupCellTemplate'">
      {{ getGroupTitle(data) }}
    </div>

    <div *dxTemplate="let data of 'subGroupCellTemplate'">
      {{ getSubGroupTitle(data) }}
    </div>

    <div *dxTemplate="let data of 'toolbarTemplate'">
      <div>
        <dx-drop-down-button text="Tools" [dropDownOptions]="{ width: 230 }" [items]="toolOptions"
          (onItemClick)="onToolOptionClick($event)">
        </dx-drop-down-button>
      </div>
    </div>
  </dx-data-grid>

  <div *ngIf="!loading"><span class="highlight-note">Lines highlighted in yellow are used in recipes</span></div>

  <!-- load popup -->
  <dx-popup class="popup" [width]="450" [height]="555" [showTitle]="true" title="Load from Excel" [dragEnabled]="false"
    [hideOnOutsideClick]="false" [(visible)]="popupVisible">
    <div *dxTemplate="let data of 'content'">
      <div class="w400">
        <dx-select-box [dataSource]="loadTypes" displayExpr="description" valueExpr="id" [(value)]="loadTypeId"
          placeholder="Choose Load Type...">
        </dx-select-box>
      </div>

      <div *ngIf="loadTypeId > 1 && loadTypeId !== 5" class="w400">
        <dx-select-box [dataSource]="districts" displayExpr="description" valueExpr="id" [(value)]='districtId'
          placeholder="Choose Price Book...">
        </dx-select-box>
      </div>

      <div *ngIf="loadTypeId === 3 || loadTypeId === 4" class="w400">
        <dx-select-box [dataSource]="vendors" displayExpr="vendorName" valueExpr="id" [(value)]='vendorId'
          placeholder="Choose Vendor..." [searchMode]="searchModeOption" [searchExpr]="searchExprOption"
          [searchTimeout]="searchTimeoutOption" [minSearchLength]="minSearchLengthOption" [searchEnabled]="true">
        </dx-select-box>
      </div>

      <div *ngIf="loadTypeId === 3" class="w400">
        <dx-date-box [(value)]="priceLoadDate" type="date" [calendarOptions]="{ showTodayButton: true }">
        </dx-date-box>
      </div>

      <div id="fileuploader-pricefile">
        <dx-file-uploader selectButtonText="Select file" labelText="or drop file here" [multiple]="false" accept="*"
          [(value)]="fileValue" uploadMode="useForm" [allowedFileExtensions]="['.xls', '.xlsx']">
        </dx-file-uploader>
      </div>

      <div *ngIf="loadTypeId === 4" class="w400">
        <dx-check-box [(value)]="updateDescription" text="Update the description?"> </dx-check-box>
      </div>

      <div *ngIf="loadTypeId === 4" class="w400">
        <dx-check-box [(value)]="updateUnits" text="Update the units?"> </dx-check-box>
      </div>

      <dx-button [disabled]="!loadTypeId" id="sample-button" text="Sample" type="default" stylingMode="outlined"
        (onClick)="downloadSample()">
      </dx-button>
      <dx-button [disabled]="!loadTypeId || (loadTypeId === 3 && !priceLoadDate)" id="pricefile-button" text="Load"
        type="default" (onClick)="uploadClick()">
      </dx-button>
    </div>
  </dx-popup>

  <dx-popup class="popup" [width]="250" [height]="180" [showTitle]="true" title="Reset Layout" [dragEnabled]="false"
    [hideOnOutsideClick]="false" [(visible)]="resetPopupVisible">
    <div *dxTemplate="let data of 'content'">
      <dx-button id="reload-button" text="Clear & Reload" type="success" (onClick)="clearStatePersistanceGo()">
      </dx-button>
    </div>
  </dx-popup>

  <!-- export price file popup -->
  <dx-popup class="popup" [width]="250" [height]="275" [showTitle]="true" title="Export" [dragEnabled]="false"
    [hideOnOutsideClick]="false" [(visible)]="exportPopupVisible">
    <div *dxTemplate="let data of 'content'">

      <js-loading-spinner *ngIf="downloading"></js-loading-spinner>

      <div *ngIf="!downloading" class="dx-field">
        <dx-check-box [(value)]="activeOnly" text="Export active items only?">
        </dx-check-box>
      </div>

      <div *ngIf="!downloading" class="dx-field">
        <dx-check-box [(value)]="currentPricesOnly" text="Export current prices only?">
        </dx-check-box>
      </div>

      <div *ngIf="!downloading" class="dx-field">
        <dx-check-box [(value)]="preferredVendorsOnly" text="Export preferred vendors only?">
        </dx-check-box>
      </div>

      <dx-button *ngIf="!downloading" id="go-button" text="Export" type="default" (onClick)="exportToExcelGo()">
      </dx-button>
    </div>
  </dx-popup>
</div>



<!-- recipes pop-up -->
<dx-popup class="popup" [width]="950" [height]="'90%'" [showTitle]="true" [title]='currentRowDesc' [dragEnabled]="true"
  [hideOnOutsideClick]="true" [(visible)]="recipesPopupVisible" [shading]="false">
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid [dataSource]="recipesDataSource" [height]="'100%'" [showBorders]="true" [allowColumnResizing]="true"
      [wordWrapEnabled]="true" (onExporting)="gridService.onExporting($event, 'Price-File-Recipes')">
      <dxo-scrolling mode="standard"></dxo-scrolling>
      <dxo-export [enabled]="true"></dxo-export>
      <dxo-paging [enabled]="false"></dxo-paging>
      <dxo-filter-panel [visible]="true"></dxo-filter-panel>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-header-filter [visible]="true"></dxo-header-filter>

      <dxo-editing mode='row' [allowAdding]="false" [allowUpdating]="priceFileWrite" [allowDeleting]="false"
        [useIcons]="true" refreshMode='full'>
      </dxo-editing>

      <dxi-column dataField="recipeCode" dataType="string" caption="Recipe" [width]="120" sortOrder='asc'
        [allowEditing]="false"> </dxi-column>
      <dxi-column dataField="description" [allowEditing]="false"> </dxi-column>
      <dxi-column dataField="isActive" caption="Active" dataType="boolean" [allowEditing]="false" [width]="90"
        cellTemplate="activeTemplate">
      </dxi-column>

      <dxi-column dataField="phaseId" caption="Phase" dataType="number" [width]="90">
        <dxo-lookup [dataSource]="phases" displayExpr="phaseCode" valueExpr="id"> </dxo-lookup>
      </dxi-column>

      <dxi-column dataField="quantityString" [visible]="false"> </dxi-column>
      <dxi-column dataField="quantity" format="#,##0.00##" [width]="100"> </dxi-column>

      <dxi-column type="buttons" [width]="120">
        <dxi-button [visible]="isEditVisible" name="edit"></dxi-button>
        <dxi-button [visible]="isLockVisible" icon="lock"></dxi-button>
        <dxi-button hint="open recipe" icon="folder" [visible]="isOpenVisible" [onClick]="openRecipe">
        </dxi-button>
      </dxi-column>

      <div *dxTemplate="let cell of 'activeTemplate'">
        <i *ngIf="cell.value" class="dx-icon-todo"></i>
        <i *ngIf="!cell.value" class="dx-icon-close red"></i>
      </div>
    </dx-data-grid>
  </div>
</dx-popup>



<!-- confirm pop-up -->
<dx-popup class="popup" [width]="440" [height]="160" [showTitle]="true" title='Confirm?' [dragEnabled]="true"
  [hideOnOutsideClick]="false" [(visible)]="confirmPopupVisible" [shading]="false">
  <div *dxTemplate="let data of 'content'">
    <div>Updating a date or rate. Ensure you do NOT need a new record?</div>
    <br>
    <dx-button text="Cancel" type="danger" (onClick)="confirmCancelled()"> </dx-button>
    <dx-button class="float-right" text="Confirm" type="success" (onClick)="confirmClick()"> </dx-button>
  </div>
</dx-popup>
