<div class="page">

  <js-loading-spinner *ngIf="loading"></js-loading-spinner>

  <dx-data-grid *ngIf="!loading" id="recipeGrid" class="max-w-1000 m-auto tabbed-grid-height" [dataSource]="dataSource"
    [allowColumnReordering]="false" [allowColumnResizing]="true" [repaintChangesOnly]="true" [showBorders]="true"
    [rowAlternationEnabled]="true" [wordWrapEnabled]="true" (onInitNewRow)="initNewRow($event)"
    (onToolbarPreparing)="gridService.addRefreshBtnToToolbar($event)" (onExporting)="gridService.onExporting($event, 'Margins')">

    <dxo-search-panel [visible]="true" location="before" [width]="250"></dxo-search-panel>
    <dxo-scrolling mode="standard"></dxo-scrolling>
    <dxo-paging [enabled]="false"></dxo-paging>
    <dxo-editing mode="row" [allowAdding]="true" [allowUpdating]="true" [allowDeleting]="true" [useIcons]="true"
      refreshMode="repaint"></dxo-editing>
    <dxo-export [enabled]="true"></dxo-export>

    <div *dxTemplate="let data of 'refreshTemplate'">
      <div>
        <dx-button icon="refresh" (onClick)="refresh()"></dx-button>
      </div>
    </div>

    <dxi-column dataField="description" sortOrder="asc">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>

    <dxi-column dataField="order" [visible]="false">
    </dxi-column>

    <dxi-column dataField="marginTypeId" caption="Type" [width]="180">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxo-lookup [dataSource]="marginTypes" displayExpr="description" valueExpr="id">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="margin" caption='Mark-Up%' dataType="number" [format]="{ type: 'fixedPoint', precision: 4 }"
      width="100">
    </dxi-column>

    <dxi-column dataField="roundUpToNext" caption='Round up selling price to nearest' dataType="number" format="#,###"
      width="150">
    </dxi-column>

    <dxi-column *ngIf="centralCompanyMargins" dataField="centralCompanyId" dataType="number"
      caption="Central Company Margin" [width]="150" [editorOptions]="{ showClearButton: true }">
      <dxo-lookup [dataSource]="centralCompanyMargins" valueExpr="id" displayExpr="description">
      </dxo-lookup>
    </dxi-column>

    <dxi-column dataField="isActive" caption="Active?" dataType="boolean" width="100">
    </dxi-column>
  </dx-data-grid>

</div>
