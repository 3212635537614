import { NotificationService } from './../../services/notification.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { Subscription } from 'rxjs';
import { DxDataGridComponent } from 'devextreme-angular';
import { GlobalService } from '../../services/global.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { GridService } from '../../services/grid.service';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';

@Component({
  selector: 'js-unit-of-measure',
  templateUrl: './unit-of-measure.component.html',
  styleUrls: ['./unit-of-measure.component.scss']
})
export class UnitOfMeasureComponent implements OnInit, OnDestroy {

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: any = {};
  popupVisible = false;
  value: any[] = [];
  centralCompanyUnits: UnitOfMeasure[];

  constructor(
    private maintenanceService: MaintenanceService,
    private globalService: GlobalService,
    protected gridService: GridService,
    private notiService: NotificationService
  ) { }

  ngOnInit(): void {
    this.getCentralCompanyUnits();
    this.setUpDataSource();
  }

  refresh() {
    this.grid.instance.refresh();
  }

  setUpDataSource() {
    this.dataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.getUnitOfMeasures(false).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.addUnitOfMeasure(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.updateUnitOfMeasure(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.maintenanceService.deleteUnitOfMeasure(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      }
    });
  }

  ngOnDestroy() {
    this.maintenanceService.unitOfMeasures = null;
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  onInitNewRow(e) {
    e.data.isDoNotRecost = false;
  }

  getCentralCompanyUnits() {
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.CentralCompanyActive)) {
      this.subscriptions.push(
        this.maintenanceService.getCentralCompanyUnits().subscribe({
          next: (res) => {
            this.centralCompanyUnits = res;
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }
}
