import { GridService } from './../../services/grid.service';
import { UserService } from './../../services/felixApi/user.service';
import { UtilsService } from './../../services/utils.service';
import { CompanyService } from './../../services/felixApi/company.service';
import { EstimatingService } from './../../services/felixApi/estimating.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { PriceFileItem } from '../../dtos/price-file-item';
import { PriceFileItemTypeEnum } from '../../dtos/price-file-item-type.enum';
import { DxDataGridComponent } from 'devextreme-angular';
import { NotificationService } from '../../services/notification.service';
import { GlobalService } from '../../services/global.service';
import { PriceFileService } from '../../services/felixApi/price-file.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';
import { QtySizeControl } from '../../dtos/qty-size-control';
import { PriceFileItemVendorRate, PriceFileItemVendorRateId } from '../../dtos/price-file-item-vendor-rate';
import { VendorService } from '../../services/felixApi/vendor.service';
import { Vendor } from '../../dtos/vendor';
import { District } from '../../dtos/district';
import { saveAs } from 'file-saver';
import { formatDate } from 'devextreme/localization';
import { RecipeLine } from '../../dtos/recipe-line';
import { RecipeUsed } from '../../dtos/recipe';
import DataGrid, { Column } from 'devextreme/ui/data_grid';
import Guid from 'devextreme/core/guid';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UpdateConfigComponent } from '../../shared/update-config/update-config.component';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { CalcEstExtraComponent } from '../calc-est-extra/calc-est-extra.component';
import DataSource from 'devextreme/data/data_source';
import { SetPreferredVendorComponent } from '../set-preferred-vendor/set-preferred-vendor.component';
import { Phase } from '../../dtos/phase';
import { User } from '../../dtos/user';
import { SubRecipeComponent } from '../../recipes/sub-recipe/sub-recipe.component';

DataGrid.defaultOptions({
  options: {
    scrolling: {
      legacyMode: true
    }
  }
});

@Component({
  selector: 'js-price-file-items',
  templateUrl: './price-file-items.component.html',
  styleUrls: ['./price-file-items.component.scss']
})
export class PriceFileItemsComponent implements OnInit, OnDestroy {

  @ViewChild('priceFileItemGrid') dataGrid: DxDataGridComponent;

  subscriptions: Subscription[] = [];
  dataSource: any;
  priceFileItemTypeEnum = PriceFileItemTypeEnum;
  loading = true;
  popupVisible = false;
  resetPopupVisible = false;
  fileValue: any[] = [];
  deleteExisting = false;
  unitsOfMeasure: UnitOfMeasure[] = [{ id: null, description: '', costIsPer: null, isDoNotRecost: false, centralCompanyId: null }];
  qtySizeControls: QtySizeControl[] = [new QtySizeControl(null, '')];
  priceFileItemVendorRates: PriceFileItemVendorRate[];
  filteredPriceFileItems: PriceFileItemVendorRate[];
  tempPriceFileItems: PriceFileItemVendorRate[];
  foundItemMasterGroups: PriceFileItem[];
  addingRow: any;
  vendors: Vendor[];
  districts: District[];
  priceFileItemGroups: PriceFileItem[];
  editMode = 'row';
  blob: any;

  loadTypes = [
    { id: 4, description: 'Specific vendor using price file codes' },
    { id: 3, description: 'Specific vendor using product codes' },
    { id: 2, description: 'Preferred vendor price update' },
    { id: 1, description: 'Load price file items' }
  ];
  loadTypeId: number;
  districtId: number;
  vendorId: number; // for price file upload
  priceLoadDate: Date; // for price file upload

  searchModeOption = 'contains';
  searchExprOption: any = 'vendorName';
  searchTimeoutOption = 200;
  minSearchLengthOption = 0;
  preferredOption = 1;
  showFuturePrices = false;
  showPreviousPrices = false;
  showInactive = false;

  toolbarDistricts: District[] = [{ id: 0, description: 'All Books', districtParentId: null, orderNumber: 0, isActive: true, centralCompanyId: null }];
  shownDistrict = 0;

  toolOptions: string[];
  exportPopupVisible: boolean;
  activeOnly = true;
  downloading: boolean;
  preferredTypes = [
    { id: 1, description: 'Preferred Only' },
    { id: 2, description: 'Non-Preferred' },
    { id: 3, description: 'All' }
  ];
  recipeLines: RecipeLine[];
  currentRowDesc: any;
  recipesPopupVisible: boolean;
  recipesForItem: RecipeUsed[];
  priceFileWrite: boolean;
  newRowPosition = 'viewportTop';
  changes = [];
  editRowKey = null;
  updateDescription = false;
  updateUnits = false;
  currentPricesOnly = true;
  preferredVendorsOnly = false;
  dropDownOptions: object;
  costCentreDataSource: CustomStore;
  subGroupsForCostCentre: CustomStore;
  recipesDataSource: DataSource;
  confirmPopupVisible: boolean;
  dataAreReadyToBeSaved: boolean;
  phases: Phase[] = [{ id: 0, orderNo: 0, phaseCode: 'Default', description: 'Default', isShowOnPO: false, centralCompanyId: null }];
  users: User[];

  constructor(
    private priceFileService: PriceFileService,
    private maintenanceService: MaintenanceService,
    private estimatingService: EstimatingService,
    private vendorService: VendorService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    private authService: AuthService,
    private compService: CompanyService,
    private modalService: NgbModal,
    private utilsService: UtilsService,
    private userService: UserService,
    public gridService: GridService
  ) {
    this.getItemSubGroups = this.getItemSubGroups.bind(this);
    this.getItemVendors = this.getItemVendors.bind(this);
    this.getGroupTitle = this.getGroupTitle.bind(this);
    this.setPriceFileCodeValue = this.setPriceFileCodeValue.bind(this);
    this.resetSort = this.resetSort.bind(this);
    this.onToolOptionClick = this.onToolOptionClick.bind(this);
    this.clearStatePersistanceGo = this.clearStatePersistanceGo.bind(this);
    this.onRowPrepared = this.onRowPrepared.bind(this);
    this.showRecipes = this.showRecipes.bind(this);
    this.isRecipesIconVisible = this.isRecipesIconVisible.bind(this);
    this.getFilteredSubGroups = this.getFilteredSubGroups.bind(this);
    this.confirmClick = this.confirmClick.bind(this);
    this.openRecipe = this.openRecipe.bind(this);

    this.toolOptions = ['Reset Sort', 'Reset Layout', 'Set Preferred Vendor', 'Set Estimating Extra', 'Set Product Code Configuration'];
    this.dropDownOptions = { width: 500 };
  }


  ngOnInit(): void {
    this.getCompanyConfigurations();
    // temp lines to reset the state
    // if (this.authService.currentVersion === '3.3.2f') {
    //   localStorage.removeItem('resetPriceFileGrid');
    //   const reset = localStorage.getItem('resetPriceFileGrid2');
    //   if (!reset) {
    //     localStorage.removeItem('pricefilegrid');
    //     localStorage.setItem('resetPriceFileGrid2', 'true');
    //   }
    // }
  }

  getCompanyConfigurations() {
    // this will run on refesh twice - once without the user Id set so we ignore
    if (this.authService.getUserId()) {
      this.subscriptions.push(
        this.compService.getCompanyConfigurations().subscribe({
          next: (configs) => {
            this.globalService.companyConfigurations = configs;
            if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('PriceFile') === 'Write'
              || this.authService.getSelectionsPermissions('PriceFile') === 'Admin') {
              this.priceFileWrite = true;
            }

            if (this.globalService.getCompanyConfigValue(ConfigurationEnum.PriceFileLoadOptionForProductCodeIndependentOfVendor) === 1) {
              this.loadTypes.push({ id: 5, description: 'Load using product codes (independent of vendor)' });
            }

            this.readVendors(false);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.priceFileService.priceFileItemVendorRates = null;
    this.priceFileService.priceFileItems = null;
  }

  readVendors(useCache: boolean) {
    this.subscriptions.push(
      this.vendorService.getVendors(useCache, false).subscribe({
        next: (vendors) => {
          this.vendorService.vendors = vendors;
          this.vendors = this.vendorService.vendors;
          this.readDistricts(useCache);
        }, error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  readDistricts(useCache: boolean) {
    this.subscriptions.push(
      this.maintenanceService.getDistricts(useCache).subscribe({
        next: (districts) => {
          this.maintenanceService.districts = districts;
          this.districts = this.maintenanceService.districts;
          this.toolbarDistricts = this.toolbarDistricts.concat(this.districts);
          this.readUnitsOfMeasure(useCache);
        }, error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  readUnitsOfMeasure(useCache: boolean) {
    this.subscriptions.push(
      this.maintenanceService.getUnitOfMeasures(useCache).subscribe({
        next: (unitsOfMeasure) => {
          this.maintenanceService.unitOfMeasures = unitsOfMeasure;
          this.unitsOfMeasure = this.maintenanceService.unitOfMeasures;
          this.readQtySizeControls(useCache);
        }, error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  readQtySizeControls(useCache: boolean) {
    this.subscriptions.push(
      this.maintenanceService.getQtySizeControls(useCache).subscribe({
        next: (qtySizeControls) => {
          this.maintenanceService.qtySizeControls = qtySizeControls;
          this.qtySizeControls = this.maintenanceService.qtySizeControls;
          this.getAllPriceFileItemGroups(useCache);
        }, error: (err) => {
          this.loading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  getAllPriceFileItemGroups(useCache: boolean) {
    // read all priceFileItemGroups
    this.subscriptions.push(
      this.estimatingService.getPriceFileItemsData(useCache).subscribe({
        next: (priceFileItemGroups) => {
          this.priceFileItemGroups = priceFileItemGroups;
          this.foundItemMasterGroups = this.priceFileItemGroups.filter(i => i.priceFileItemParentId === null);
          this.costCentreDataSource = new CustomStore({
            key: 'id',
            loadMode: 'raw',
            load: () => this.foundItemMasterGroups
          });
          this.recipeLines = this.estimatingService.recipeLines;
          this.phases = this.phases.concat(this.maintenanceService.phases);
          this.users = this.userService.users;
          this.getAllPriceFileItemVendorRates(useCache);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  getAllPriceFileItemVendorRates(useCache: boolean) {
    // read all priceFileVendors
    this.subscriptions.push(
      this.priceFileService.getPriceFileItemVendorRates(useCache).subscribe({
        next: (priceFileItemVendorRates) => {
          this.priceFileItemVendorRates = priceFileItemVendorRates;

          this.priceFileItemVendorRates.forEach(priceFileItemVendorRate => {
            priceFileItemVendorRate.id = new PriceFileItemVendorRateId();
            priceFileItemVendorRate.id.priceFileGroupId = priceFileItemVendorRate.priceFileGroupId;
            priceFileItemVendorRate.id.priceFileItemParentId = priceFileItemVendorRate.priceFileItemParentId;
            priceFileItemVendorRate.id.priceFileItemId = priceFileItemVendorRate.priceFileItemId;
            priceFileItemVendorRate.id.priceFileBookId = priceFileItemVendorRate.priceFileBookId;
            priceFileItemVendorRate.id.priceFileVendorId = priceFileItemVendorRate.priceFileVendorId;
            priceFileItemVendorRate.id.priceFileRateId = priceFileItemVendorRate.priceFileRateId;
          });
          this.setUpDataSet();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  getItemSubGroups(options) {
    this.subGroupsForCostCentre = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.priceFileItemGroups.filter(i => i.priceFileItemParentId === options.data.priceFileGroupId)
    });

    return {
      store: this.priceFileItemGroups,
      filter: options.data ? ['priceFileItemParentId', '=', options.data.priceFileGroupId] : null
    };
  }

  getItemVendors(options) {
    let priceFileVendors = this.priceFileItemVendorRates.map(this.getJustVendor);
    if (options.data) {
      // get unique vendor list
      const vendors = priceFileVendors
        .filter(i => i.priceFileBookId === options.data.priceFileBookId);

      priceFileVendors = [];
      vendors.forEach(vendor => {
        const alreadyHave = priceFileVendors.filter(i => i.priceFileVendorId === vendor.priceFileVendorId);
        if (!alreadyHave || !alreadyHave[0]) {
          priceFileVendors.push(vendor);
        }
      });
    }
    return {
      store: priceFileVendors,
      filter: options.data ? ['priceFileBookId', '=', options.data.priceFileBookId] : null
    };
  }

  getJustVendor(v: PriceFileItemVendorRate, index, self) {
    return { priceFileBookId: v.priceFileBookId, priceFileVendorId: v.priceFileVendorId, vendorName: v.vendorName };
  }

  updatePriceFileItem(priceFileItemVendorRates: PriceFileItemVendorRate[]) {
    priceFileItemVendorRates.forEach(priceFileItemVendorRate => {
      let priceFileItems = this.priceFileItemVendorRates.filter(i => i.priceFileItemId === priceFileItemVendorRate.priceFileItemId);

      if (!priceFileItems || !priceFileItems[0]) {
        // we can't find it so it must be the one that was the blank row
        priceFileItems = this.priceFileItemVendorRates.filter(i => i.priceFileItemId === null
          && i.priceFileGroupId === priceFileItemVendorRate.priceFileGroupId
          && i.priceFileItemParentId === priceFileItemVendorRate.priceFileItemParentId);
      }

      if (priceFileItems && priceFileItems[0]) {
        priceFileItems.forEach(item => {

          // if we have a new row we update the id
          if (!item.priceFileItemId) {
            item.id = new PriceFileItemVendorRateId();
            item.id.priceFileGroupId = item.priceFileGroupId;
            item.id.priceFileItemParentId = item.priceFileItemParentId;
            item.id.priceFileItemId = priceFileItemVendorRate.priceFileItemId;
            item.id.priceFileBookId = priceFileItemVendorRate.priceFileBookId;
            item.id.priceFileVendorId = priceFileItemVendorRate.priceFileVendorId;
            item.id.priceFileRateId = priceFileItemVendorRate.priceFileRateId;

            item.priceFileItemId = priceFileItemVendorRate.priceFileItemId;
            item.priceFileBookId = priceFileItemVendorRate.priceFileBookId;
            item.priceFileVendorId = priceFileItemVendorRate.priceFileVendorId;
            item.priceFileRateId = priceFileItemVendorRate.priceFileRateId;
          } else {
            // we may have moved the item
            item.id.priceFileGroupId = priceFileItemVendorRate.priceFileGroupId;
            item.id.priceFileItemParentId = priceFileItemVendorRate.priceFileItemParentId;
            item.priceFileGroupId = priceFileItemVendorRate.priceFileGroupId;
            item.priceFileItemParentId = priceFileItemVendorRate.priceFileItemParentId;
          }

          // for the item we update it's values
          item.priceFileCode = priceFileItemVendorRate.priceFileCode;
          item.description = priceFileItemVendorRate.description;
          item.unitOfMeasureId = priceFileItemVendorRate.unitOfMeasureId;
          item.hasSizes = priceFileItemVendorRate.hasSizes;
          item.centralCompanyPriceFileItemId = priceFileItemVendorRate.centralCompanyPriceFileItemId;
          item.isActive = priceFileItemVendorRate.isActive;
          item.comment = priceFileItemVendorRate.comment;

          // update the book
          if (item.priceFileBookId === priceFileItemVendorRate.priceFileBookId) {
            item.districtId = priceFileItemVendorRate.districtId;
            item.preferredVendorId = priceFileItemVendorRate.preferredVendorId;
          }

          // update the vendor
          if (item.priceFileVendorId === priceFileItemVendorRate.priceFileVendorId) {
            item.priceFileVendorId = priceFileItemVendorRate.priceFileVendorId;
            item.vendorId = priceFileItemVendorRate.vendorId;
            item.vendorName = priceFileItemVendorRate.vendorName;
            item.productCode = priceFileItemVendorRate.productCode;
            item.qtySizeControlId = priceFileItemVendorRate.qtySizeControlId;
            item.vendorItemDescription = priceFileItemVendorRate.vendorItemDescription;
          }

          // update the rate
          if (item.priceFileRateId === priceFileItemVendorRate.priceFileRateId) {
            item.effectiveDate = priceFileItemVendorRate.effectiveDate;
            item.rate = priceFileItemVendorRate.rate;
            item.expiryDate = priceFileItemVendorRate.expiryDate;
            item.estimatingExtra = priceFileItemVendorRate.estimatingExtra;
            item.modifiedDate = priceFileItemVendorRate.modifiedDate;
            item.modifiedUserId = priceFileItemVendorRate.modifiedUserId;
          }
        });
      } else {
        this.notiService.showWarning('Could not find item to update');
      }
    });
  }

  insertPriceFileItem(priceFileItemVendorRates: PriceFileItemVendorRate[]) {
    priceFileItemVendorRates.forEach(priceFileItemVendorRate => {
      priceFileItemVendorRate.id = new PriceFileItemVendorRateId();
      priceFileItemVendorRate.id.priceFileGroupId = priceFileItemVendorRate.priceFileGroupId;
      priceFileItemVendorRate.id.priceFileItemParentId = priceFileItemVendorRate.priceFileItemParentId;
      priceFileItemVendorRate.id.priceFileItemId = priceFileItemVendorRate.priceFileItemId;
      priceFileItemVendorRate.id.priceFileBookId = priceFileItemVendorRate.priceFileBookId;
      priceFileItemVendorRate.id.priceFileVendorId = priceFileItemVendorRate.priceFileVendorId;
      priceFileItemVendorRate.id.priceFileRateId = priceFileItemVendorRate.priceFileRateId;

      this.priceFileItemVendorRates.push(priceFileItemVendorRate);

      // check if we have a blank line for this group and take it out as we don't need it now
      const blankItemRowIndex = this.priceFileItemVendorRates
        .findIndex(i => i.priceFileGroupId === priceFileItemVendorRate.priceFileGroupId
          && i.priceFileItemParentId === priceFileItemVendorRate.priceFileItemParentId
          && !i.priceFileItemId);

      if (blankItemRowIndex >= 0) {
        this.priceFileItemVendorRates.splice(blankItemRowIndex, 1);
      }
    });
  }

  deletePriceFileItemRate(id: number) {
    const itemsToDelete = this.priceFileItemVendorRates.filter(i => i.priceFileRateId === id);
    const priceFileItemParentId = itemsToDelete[0].priceFileItemParentId;
    const priceFileBookId = itemsToDelete[0].priceFileBookId;

    const rowIndex = this.priceFileItemVendorRates.findIndex(i => i.priceFileRateId === id);
    this.priceFileItemVendorRates.splice(rowIndex, 1);

    // if we have deleted the last one for the preferred and we only have one the backend will set a new preferred
    const remainingRecords = this.priceFileItemVendorRates.filter(i => i.priceFileBookId === priceFileBookId);
    if (remainingRecords.length === 1) {
      remainingRecords[0].preferredVendorId = remainingRecords[0].priceFileVendorId;
    }

    this.checkWeHaveDeletedAll(priceFileItemParentId);
  }

  deletePriceFileItemVendor(id: number) {
    const itemsToDelete = this.priceFileItemVendorRates.filter(i => i.priceFileVendorId === id);
    const priceFileItemParentId = itemsToDelete[0].priceFileItemParentId;

    itemsToDelete.forEach(itemToDelete => {
      const rowIndex = this.priceFileItemVendorRates.findIndex(i => i.priceFileVendorId === itemToDelete.priceFileVendorId);
      this.priceFileItemVendorRates.splice(rowIndex, 1);
    });

    this.checkWeHaveDeletedAll(priceFileItemParentId);
  }

  deletePriceFileItem(id: number) {
    const itemsToDelete = this.priceFileItemVendorRates.filter(i => i.priceFileItemId === id);
    const priceFileItemParentId = itemsToDelete[0].priceFileItemParentId;

    itemsToDelete.forEach(itemToDelete => {
      const rowIndex = this.priceFileItemVendorRates.findIndex(i => i.priceFileItemId === itemToDelete.priceFileItemId);
      this.priceFileItemVendorRates.splice(rowIndex, 1);
    });

    this.checkWeHaveDeletedAll(priceFileItemParentId);
  }

  checkWeHaveDeletedAll(priceFileItemParentId: number) {
    const haveAny = this.priceFileItemVendorRates.find(i => i.priceFileItemParentId === priceFileItemParentId);

    if (!haveAny) {
      const itemGroup = this.priceFileItemGroups.find(i => i.id === priceFileItemParentId);
      const costCentre = this.priceFileItemGroups.find(i => i.id === itemGroup.priceFileItemParentId);

      const priceFileItemVendorRate = new PriceFileItemVendorRate(costCentre.id, costCentre.orderNumber, itemGroup.id,
        itemGroup.orderNumber, null, null, null, null);

      this.priceFileItemVendorRates.push(priceFileItemVendorRate);
    }
  }

  filterPriceFileItems(): PriceFileItemVendorRate[] {
    const todaysDate = formatDate(new Date(), 'yyyy-MM-dd');

    if (this.shownDistrict !== 0) {
      this.filteredPriceFileItems = this.priceFileItemVendorRates.filter(i => i.districtId === this.shownDistrict);
    } else {
      this.filteredPriceFileItems = this.priceFileItemVendorRates;
    }

    if (this.preferredOption === 1) {
      this.filteredPriceFileItems = this.filteredPriceFileItems
        .filter(i => i.preferredVendorId === i.priceFileVendorId || !i.preferredVendorId);
    } else if (this.preferredOption === 2) {
      this.filteredPriceFileItems = this.filteredPriceFileItems
        .filter(i => i.preferredVendorId !== i.priceFileVendorId);
    }

    if (!this.showFuturePrices) {
      this.filteredPriceFileItems = this.filteredPriceFileItems
        .filter(i => !i.effectiveDate || i.effectiveDate.toString().substring(0, 10) <= todaysDate);
    }

    if (!this.showPreviousPrices) {
      this.tempPriceFileItems = [];

      const allPrevAndCurrent = this.filteredPriceFileItems
        .filter(i => !i.effectiveDate || i.effectiveDate.toString().substring(0, 10) <= todaysDate);

      this.filteredPriceFileItems.forEach(priceFileItem => {
        if (priceFileItem.effectiveDate) {
          const thisEffectiveDate = priceFileItem.effectiveDate.toString().substring(0, 10);
          const nextCurrentItem = allPrevAndCurrent
            .find(i => i.priceFileCode === priceFileItem.priceFileCode
              && i.districtId === priceFileItem.districtId
              && i.priceFileVendorId === priceFileItem.priceFileVendorId
              && (!i.effectiveDate || i.effectiveDate.toString().substring(0, 10) > thisEffectiveDate));

          if (!nextCurrentItem) {
            // we dont have a future current price so this must be a previous
            this.tempPriceFileItems.push(priceFileItem);
          }
        } else {
          this.tempPriceFileItems.push(priceFileItem);
        }
      });
      this.filteredPriceFileItems = this.tempPriceFileItems;
    }

    if (this.showInactive) {
      return this.filteredPriceFileItems;
    }

    // filter out inactive items
    this.filteredPriceFileItems = this.filteredPriceFileItems.filter(i => i.isActive || !i.priceFileItemId);

    const activeItems: PriceFileItemVendorRate[] = [];
    this.filteredPriceFileItems.forEach(priceFileItem => {
      if (!priceFileItem.priceFileItemId) {
        activeItems.push(priceFileItem);
      } else {
        // check the vendor
        const vendor = this.vendors.find(i => i.id === priceFileItem.vendorId);
        if (vendor && vendor.isActive) {
          activeItems.push(priceFileItem);
        }
      }
    });

    return activeItems;
  }

  setUpDataSet() {
    this.dataSource = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.filterPriceFileItems(),
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.priceFileService.addPriceFileItemVendorRate(values).subscribe({
              next: (res) => {
                this.insertPriceFileItem(res);
                this.dataAreReadyToBeSaved = false;
                return resolve(res[0]);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      update: async (key, values) => {
        if (!values.priceFileGroupId) {
          values.priceFileGroupId = key.priceFileGroupId;
        }
        if (!values.priceFileItemId) {
          values.priceFileItemId = key.priceFileItemId;
        }
        if (!values.priceFileItemParentId) {
          values.priceFileItemParentId = key.priceFileItemParentId;
        }
        values.priceFileBookId = key.priceFileBookId;
        values.priceFileVendorId = key.priceFileVendorId;
        values.priceFileRateId = key.priceFileRateId;
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.priceFileService.updatePriceFileItemVendorRate(values).subscribe({
              next: (res) => {
                this.updatePriceFileItem(res);
                this.dataAreReadyToBeSaved = false;
                return resolve(res[0]);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      remove: async (key) => {
        return new Promise((resolve, reject) => {
          if (key.priceFileRateId) {
            this.subscriptions.push(
              this.priceFileService.deletePriceFileRate(key.priceFileRateId).subscribe({
                next: () => {
                  this.deletePriceFileItemRate(key.priceFileRateId);
                  this.dataAreReadyToBeSaved = false;
                  return resolve();
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            );
          } else if (key.priceFileVendorId) {
            this.subscriptions.push(
              this.priceFileService.deletePriceFileVendor(encodeURIComponent(key.priceFileVendorId)).subscribe({
                next: () => {
                  this.deletePriceFileItemVendor(key.priceFileVendorId);
                  this.dataAreReadyToBeSaved = false;
                  return resolve();
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            );
          } else if (key.priceFileItemId) {
            this.subscriptions.push(
              this.priceFileService.deletePriceFileItem(encodeURIComponent(key.priceFileItemId)).subscribe({
                next: () => {
                  this.deletePriceFileItem(key.priceFileItemId);
                  this.dataAreReadyToBeSaved = false;
                  return resolve();
                }, error: (err) => {
                  return reject(this.globalService.returnError(err));
                }
              })
            );
          }
        });
      }
    });
    this.loading = false;
  }

  onToolbarPreparing(e, templateName: string) {
    if (this.priceFileWrite) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          widget: 'dxButton',
          options: {
            text: this.editMode === 'batch' ? 'Row Edit' : 'Batch Edit',
            onClick: this.batchMode.bind(this)
          }
        });
    }

    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'expand',
          onClick: this.expandAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          icon: 'collapse',
          onClick: this.collapseAll.bind(this),
          matTooltip: 'Collapse All Rows'
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        template: templateName
      });

    if (this.priceFileWrite) {
      e.toolbarOptions.items.unshift(
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            width: 40,
            icon: 'upload',
            onClick: this.loadFromExcel.bind(this)
          }
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            width: 40,
            icon: 'download',
            onClick: this.exportToExcel.bind(this)
          }
        });
    }

    e.toolbarOptions.items.unshift(
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxSelectBox',
        options: {
          width: 150,
          items: this.toolbarDistricts,
          displayExpr: 'description',
          valueExpr: 'id',
          value: 0,
          onValueChanged: this.onDistrictChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxSelectBox',
        options: {
          width: 150,
          items: this.preferredTypes,
          displayExpr: 'description',
          valueExpr: 'id',
          value: 1,
          onValueChanged: this.preferredOnlyChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Future Price',
          value: false,
          rtlEnabled: true,
          onValueChanged: this.showFuturePricesChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Previous Prices',
          value: false,
          rtlEnabled: true,
          onValueChanged: this.showPreviousPricesChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Inactive',
          value: false,
          rtlEnabled: true,
          onValueChanged: this.showInactiveChanged.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxButton',
        options: {
          width: 40,
          icon: 'refresh',
          onClick: this.refreshAll.bind(this)
        }
      });
  }

  batchMode() {
    if (this.editMode === 'batch') {
      this.editMode = 'row';
    } else {
      this.editMode = 'batch';
    }
  }

  loadFromExcel() {
    this.loadTypeId = null;
    this.districtId = null;
    this.vendorId = null;
    this.priceLoadDate = null;
    this.updateDescription = false;
    this.updateUnits = false;
    this.popupVisible = true;
  }

  exportToExcel() {
    this.downloading = false;
    this.exportPopupVisible = true;
  }

  exportToExcelGo() {
    this.downloading = true;
    this.notiService.showInfo('Please wait for download to complete');
    this.subscriptions.push(
      this.priceFileService.getPriceFileExcelDump(this.activeOnly, this.currentPricesOnly, this.preferredVendorsOnly).subscribe({
        next: (res) => {
          this.base64ToArrayBuffer(res.pdfReport);
          saveAs(this.blob, 'price-file.xls');
          this.exportPopupVisible = false;
        }, error: (err) => {
          this.downloading = false;
          this.notiService.notify(err);
        }
      })
    );
  }

  uploadClick() {
    // load the file
    if (this.fileValue && this.fileValue.length) {
      if (this.loadTypeId === 2 && !this.districtId) {
        this.notiService.showInfo('Please select a price book');
      } else {
        this.loading = true;
        this.popupVisible = false;
        const formData: FormData = new FormData();
        formData.append('image', this.fileValue[0], this.fileValue[0].name);

        this.subscriptions.push(
          this.priceFileService.postUploadtemplate(formData, this.deleteExisting, this.loadTypeId,
            this.districtId, this.vendorId, formatDate(this.priceLoadDate, 'yyyy-MM-dd'), this.updateDescription, this.updateUnits).subscribe({
              next: () => {
                this.refreshAll();
              }, error: (err) => {
                this.notiService.notify(err);
                this.fileValue = [];
                this.loading = false;
              }
            })
        );
      }
    }
  }

  addItem = (e) => {
    const key = new Guid().toString();
    this.changes = [{
      key,
      type: 'insert',
      insertAfterKey: e.row.key,
      data: {
        // group
        priceFileGroupId: e.row.data.priceFileGroupId,
        groupOrderNumber: e.row.data.groupOrderNumber,
        priceFileItemParentId: e.row.data.priceFileItemParentId,
        subGroupOrderNumber: e.row.data.subGroupOrderNumber,

        // item
        priceFileItemId: e.row.data.priceFileItemId,
        priceFileItemTypeId: e.row.data.priceFileItemTypeId,
        priceFileCode: e.row.data.priceFileCode,
        description: e.row.data.description,
        unitOfMeasureId: e.row.data.unitOfMeasureId,
        hasSizes: e.row.data.hasSizes,
        centralCompanyPriceFileItemId: e.row.data.centralCompanyPriceFileItemId,
        isActive: e.row.data.isActive,
        comment: e.row.data.comment,

        // book fields
        priceFileBookId: e.row.data.priceFileBookId,
        districtId: e.row.data.districtId,
        preferredVendorId: e.row.data.preferredVendorId,

        // vendor fields
        priceFileVendorId: e.row.data.priceFileVendorId,
        vendorId: e.row.data.vendorId,
        productCode: e.row.data.productCode,
        qtySizeControlId: e.row.data.qtySizeControlId,
        vendorItemDescription: e.row.data.vendorItemDescription,

        // rate fields
        // priceFileRateId: e.row.data.priceFileRateId,
        effectiveDate: e.row.data.effectiveDate,
        rate: e.row.data.rate,
        expiryDate: e.row.data.expiryDate,
        estimatingExtra: e.row.data.estimatingExtra
      }
    }];
    this.editRowKey = key;
  }

  calculateGroupValue(rowData) {
    return ('00000' + rowData.groupOrderNumber.toString()).slice(-5) + ';' + rowData.priceFileGroupId;
  }

  getGroupTitle(cellInfo) {
    const item = this.foundItemMasterGroups.filter(i => i.id === (+cellInfo.data.key.split(';')[1]));
    if (item && item[0]) {
      return item[0].priceFileCode ? item[0].priceFileCode + ' - ' + item[0].description : item[0].description;
    } else {
      return '';
    }
  }

  calculateSubGroupValue(rowData) {
    return ('00000' + rowData.subGroupOrderNumber.toString()).slice(-5) + ';' + rowData.priceFileItemParentId;
  }

  getSubGroupTitle(cellInfo) {
    const item = this.priceFileItemGroups.filter(i => i.id === (+cellInfo.data.key.split(';')[1]));
    if (item && item[0]) {
      return item[0].priceFileCode ? item[0].priceFileCode + ' - ' + item[0].description : item[0].description;
    } else {
      return '';
    }
  }

  setPriceFileCodeValue(rowData: any, value: any): void {
    // if we are using an existing price file code we cannot update the other item fields if we are adding
    if (!rowData.id || !rowData.id.priceFileGroupId) {
      // we are adding
      const foundCode = this.priceFileItemVendorRates.filter(i => i.priceFileCode === rowData.value);
      if (foundCode && foundCode[0]) {
        // we have another so item fields are not updateable.
      }
    }

    (<any>this).defaultSetCellValue(rowData, value);
  }

  onContentReady(e) {
    e.component.option('loadPanel.enabled', false);
  }

  clearStatePersistance() {
    this.resetPopupVisible = true;
  }

  clearStatePersistanceGo() {
    this.resetPopupVisible = false;
    this.loading = true;
    localStorage.removeItem('pricefilegrid');
    window.location.reload();
  }

  resetSort() {
    this.dataGrid.instance.clearSorting();
    this.dataGrid.instance.columnOption(5, 'sortOrder', 'asc');
    this.dataGrid.instance.columnOption(5, 'sortIndex', 0);
    this.dataGrid.instance.columnOption(12, 'sortOrder', 'asc');
    this.dataGrid.instance.columnOption(12, 'sortIndex', 1);
    this.dataGrid.instance.columnOption(15, 'sortOrder', 'asc');
    this.dataGrid.instance.columnOption(15, 'sortIndex', 2);
    this.dataGrid.instance.columnOption(19, 'sortOrder', 'asc');
    this.dataGrid.instance.columnOption(19, 'sortIndex', 3);
  }

  base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    if (this.exportToExcel) {
      this.blob = new Blob([bytes], { type: 'application/vnd.ms-excel' });
    } else {
      this.blob = new Blob([bytes], { type: 'application/pdf' });
    }
  }

  collapseAll() {
    this.dataGrid.instance.collapseAll();
  }

  expandAll() {
    this.dataGrid.instance.expandAll();
  }

  downloadSample() {
    this.notiService.showInfo('Please wait for download to complete');
    this.subscriptions.push(
      this.priceFileService.getSampleExcelUpload(this.loadTypeId).subscribe({
        next: (res) => {
          this.base64ToArrayBuffer(res.pdfReport);
          saveAs(this.blob, res.pdfReportName);
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  preferredOnlyChanged(e) {
    this.preferredOption = e.value;
    this.dataGrid.instance.refresh();
  }

  showFuturePricesChanged() {
    this.showFuturePrices = !this.showFuturePrices;
    this.dataGrid.instance.refresh();
  }

  showPreviousPricesChanged() {
    this.showPreviousPrices = !this.showPreviousPrices;
    this.dataGrid.instance.refresh();
  }

  showInactiveChanged() {
    this.showInactive = !this.showInactive;
    this.dataGrid.instance.refresh();
  }

  onDistrictChanged(e) {
    this.shownDistrict = e.value;
    this.dataGrid.instance.refresh();
  }

  refreshAll() {
    if (this.dataGrid?.instance?.hasEditData()) {
      this.dataGrid.instance.cancelEditData();
    }

    this.changes = [];
    this.loading = true;
    this.vendorService.vendors = [];
    this.maintenanceService.districts = [];
    this.maintenanceService.unitOfMeasures = [];
    this.maintenanceService.qtySizeControls = [];
    this.priceFileItemVendorRates = [];
    this.priceFileService.priceFileItemGroups = [];
    this.priceFileService.priceFileItemVendorRates = [];
    this.fileValue = [];
    this.showFuturePrices = false;
    this.showPreviousPrices = false;
    this.getCompanyConfigurations();
  }

  onToolOptionClick(e) {
    // console.log(e);
    if (e.itemData === 'Reset Sort') {
      this.resetSort();
    } else if (e.itemData === 'Reset Layout') {
      this.clearStatePersistance();
    } else if (e.itemData === 'Set Product Code Configuration') {
      this.checkConfig();
    } else if (e.itemData === 'Set Estimating Extra') {
      this.calcEstimatingExtraForDearest();
    } else if (e.itemData === 'Set Preferred Vendor') {
      this.setPreferredVendor();
    }
  }

  onRowPrepared(e) {
    if (e.rowType === 'data' && e.data.priceFileItemId) {
      // highlight if used in a recipe
      const recipeLine = this.recipeLines.find(i => i.priceFileItemId === e.data.priceFileItemId);
      if (recipeLine) {
        e.rowElement.style.backgroundColor = 'lightgoldenrodyellow';
        e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
      }
    }
  }

  isDeleteVisible(e) {
    return !e.row.isEditing && e.row.data.priceFileItemId;
  }

  isRecipesIconVisible(e) {
    if (e.row.data.priceFileItemId) {
      // highlight if used in a recipe
      const recipeLine = this.recipeLines.find(i => i.priceFileItemId === e.row.data.priceFileItemId);
      if (recipeLine) {
        return true;
      }
    }
    return false;
  }

  getRecipesForItem(priceFileItemId: number): RecipeUsed[] {
    const recipeLines = this.recipeLines.filter(i => i.priceFileItemId === priceFileItemId);
    this.recipesForItem = [];

    recipeLines.forEach(recipeLine => {
      const recipe = this.estimatingService.recipes.find(i => i.id === recipeLine.recipeId);

      if (recipe) {
        this.recipesForItem.push({
          recipeId: recipe.id,
          recipeLineId: recipeLine.id,
          recipeCode: recipe.recipeCode,
          description: recipe.description,
          quantity: recipeLine.quantity,
          isLocked: recipe.isLocked,
          isActive: recipe.isActive,
          phaseId: recipeLine.phaseId,
        });
      }
    });

    return this.recipesForItem;
  }

  showRecipes(e) {
    this.currentRowDesc = e.row.data.description;

    this.recipesDataSource = new DataSource({
      key: 'recipeLineId',
      load: () => this.getRecipesForItem(e.row.data.priceFileItemId),
      update: async (key, values) => {
        if (values.quantity !== undefined) {
          values['quantityString'] = values.quantity.toString();
        }
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.estimatingService.updateRecipeLine(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                const recipeLine = this.estimatingService.recipeLines.find(i => i.id === key);
                if (values.quantity !== undefined) {
                  recipeLine.quantity = values.quantity;
                  recipeLine.quantityString = values.quantity.toString();
                }
                if (values.phaseId !== undefined) {
                  recipeLine.phaseId = values.phaseId;
                }
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            })
          ));
      }
    });

    this.recipesPopupVisible = true;
  }

  isAddButtonVisible({ row }) {
    return !row.isEditing;
  }

  onEditorPreparing(e: any) {
    if (e.parentType === 'dataRow' && (e.dataField === 'description' || e.dataField === 'comment')) {
      e.editorName = 'dxTextArea';
    }

    if (e.dataField === 'priceFileGroupId' || e.dataField === 'vendorId' || e.dataField === 'preferredVendorId') {
      e.editorOptions.dropDownOptions = { minWidth: 350 };
    }
    if (e.dataField === 'qtySizeControlId' || e.dataField === 'priceFileItemParentId') {
      e.editorOptions.dropDownOptions = { minWidth: 220 };
    }
    if (e.dataField === 'unitOfMeasureId' || e.dataField === 'districtId') {
      e.editorOptions.dropDownOptions = { minWidth: 120 };
    }
    if (e.dataType === 'boolean') {
      e.editorOptions.dropDownOptions = { minWidth: 70 };
    }
  }

  onSaving(e) {
    if (!this.dataAreReadyToBeSaved) {
      let rateChanges = false;
      this.changes.forEach(element => {
        if (element.type === 'update' && (element.data.rate !== undefined || element.data.effectiveDate !== undefined)) {
          rateChanges = true;
        }
      });

      if (rateChanges) {
        e.cancel = true;
        this.confirmPopupVisible = true;
      }
    }
  }

  confirmClick() {
    this.confirmPopupVisible = false;
    this.dataAreReadyToBeSaved = true;
    this.dataGrid.instance.saveEditData();
  }

  confirmCancelled() {
    this.confirmPopupVisible = false;
  }

  checkConfig() {
    const modalRef = this.modalService.open(UpdateConfigComponent);
    modalRef.componentInstance.configId = ConfigurationEnum.PriceFileUpdateMatchingVendorAndProductCode;
    modalRef.componentInstance.configName = 'Update matching Vendor & ProductCode entries';

    modalRef.result.then(() => { }, () => { });
  }

  calcEstimatingExtraForDearest() {
    const modalRef = this.modalService.open(CalcEstExtraComponent);

    modalRef.result.then(() => { this.refreshAll() }, () => { });
  }

  setPreferredVendor() {
    const modalRef = this.modalService.open(SetPreferredVendorComponent);

    modalRef.result.then(() => { this.refreshAll() }, () => { });
  }

  onCostCentreSelectionChanged(selectedRowKeys, cellInfo, dropDownBoxComponent) {
    cellInfo.setValue(selectedRowKeys[0]);
    if (selectedRowKeys.length > 0) {
      dropDownBoxComponent.close();
    }
  }

  setMasterGroupValue(this: Column, newData: PriceFileItem, value: number, currentRowData: PriceFileItem) {
    newData.priceFileItemParentId = null;
    this.defaultSetCellValue(newData, value, currentRowData);
  }

  onSubGroupSelectionChanged(selectedRowKeys, cellInfo, dropDownBoxComponent) {
    cellInfo.setValue(selectedRowKeys[0]);
    if (selectedRowKeys.length > 0) {
      dropDownBoxComponent.close();
    }
  }

  getFilteredSubGroups(e) {
    const a = e;
    this.subGroupsForCostCentre = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: () => this.priceFileItemGroups.filter(i => i.priceFileItemParentId === e.data.priceFileGroupId)
    });
  }

  isEditVisible(e) {
    if (e.row.data.isLocked || e.row.isEditing) {
      return false;
    }
    return true;
  }

  isLockVisible(e) {
    if (e.row.data.isLocked) {
      return true;
    }
    return false;
  }

  isOpenVisible(e) {
    if (e.row.isEditing) {
      return false;
    }
    return true;
  }

  calculateRenegotiationDate = (rowData): Date => {
    if (rowData?.vendorId) {
      const vendor = this.vendors.find(i => i.id === rowData.vendorId);
      return this.utilsService.convertStringToDate(vendor?.renegotiationDate);
    } else {
      return null;
    }
  }

  openRecipe(e) {
    this.recipesPopupVisible = false;
    const modalRef = this.modalService.open(SubRecipeComponent, { windowClass: 'modal-1500' });
    modalRef.componentInstance.recipe = this.estimatingService.recipes.find(i => i.id === e.row.data.recipeId);

    modalRef.result.then(() => {
      // tell other component for the same recipe to refresh
      // this.globalService.recipeLinesUpdatedEmit(recipeItemId);
      this.recipesPopupVisible = true;
    }, () => {
      // this.globalService.recipeLinesUpdatedEmit(recipeItemId);
      this.recipesPopupVisible = true;
    });
  }
}
