import { VendorGroup } from './../../dtos/vendor-group';
import { VendorService } from './../../services/felixApi/vendor.service';
import { UserService } from './../../services/felixApi/user.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PriceFileService } from '../../services/felixApi/price-file.service';
import { DxTreeListComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { PriceFileItemTypeEnum } from '../../dtos/price-file-item-type.enum';
import { Subscription } from 'rxjs';
import { NotificationService } from '../../services/notification.service';
import { GlobalService } from '../../services/global.service';
import { MaintenanceService } from '../../services/felixApi/maintenance.service';
import { CostCentreFooter } from '../../dtos/cost-centre-footer';
import { CallUpDocsType } from '../../dtos/call-up-docs-type';
import { User } from '../../dtos/user';
import { ConfigurationEnum } from '../../dtos/configuration-enum';
import { UserTypeEnum } from '../../dtos/user-type.enum';
import { CompanyService } from '../../services/felixApi/company.service';
import { RoleTypeEnum } from '../../dtos/role-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpModalComponent } from '../../shared/help/help.component';

@Component({
  selector: 'js-price-file-item-groups',
  templateUrl: './price-file-item-groups.component.html',
  styleUrls: ['./price-file-item-groups.component.scss']
})
export class PriceFileItemGroupsComponent implements OnInit, OnDestroy {
  treeGrid: DxTreeListComponent;
  selectedRowKeys: any[] = [];
  treeListDataSource: any = {};
  priceFileItemTypeEnum = PriceFileItemTypeEnum;
  loading = false;
  subscriptions: Subscription[] = [];
  popupVisible = false;
  helpPopupVisible = false;
  updateOptionLists = false;

  @ViewChild('treeList') treeList: DxTreeListComponent;
  showCostCentreFooters: boolean;
  costCentreFooters: CostCentreFooter[];
  callUpDocsTypes: CallUpDocsType[];
  users: User[];
  purchaseOrderSystemActive: boolean;
  filteredUsers: User[];
  priceFileItemParentId: number;
  showInactive = false;
  vendorGroups: VendorGroup[];

  constructor(private priceFileService: PriceFileService,
    private notiService: NotificationService,
    private maintenanceService: MaintenanceService,
    private userService: UserService,
    private vendorService: VendorService,
    private companyService: CompanyService,
    private modalService: NgbModal,
    private globalService: GlobalService) {
    this.onReorder = this.onReorder.bind(this);
    this.initNewRow = this.initNewRow.bind(this);
    this.editorPreparing = this.editorPreparing.bind(this);
    this.onEditCancelling = this.onEditCancelling.bind(this);
    this.onEditStart = this.onEditStart.bind(this);
    this.calculateSiteManagerTitle = this.calculateSiteManagerTitle.bind(this);
    this.calculateHideInvoiceFromSupervisorColumnName = this.calculateHideInvoiceFromSupervisorColumnName.bind(this);
  }

  ngOnInit() {
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.PurchaseOrderSystemActive) === 1) {
      this.purchaseOrderSystemActive = true;
    }

    this.getData(true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getData(setUpDataSource: boolean) {
    this.subscriptions.push(
      this.priceFileService.getPriceFileGroupsData(true).subscribe({
        next: (costCentreFooters) => {
          this.costCentreFooters = costCentreFooters;
          this.callUpDocsTypes = this.maintenanceService.callUpDocsTypes;
          this.users = this.userService.users;
          this.vendorGroups = this.vendorService.vendorGroups;
          this.filteredUsers = this.userService.users.filter(i => i.isActive && (i.userTypeId === UserTypeEnum.Admin || i.userTypeId === UserTypeEnum.Office || i.isSuperUser));
          if (setUpDataSource) {
            this.setUpDataSource();
          }
        }, error: (err) => {
          this.notiService.notify(err);
        }
      })
    );
  }

  setUpDataSource() {
    this.treeListDataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.priceFileService.getPriceFileGroupsWithData().subscribe({
              next: (res) => {
                if (!this.showInactive) {
                  res = res.filter(i => i.isActive);
                }
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      insert: async (values) => {
        // values.priceFileItemTypeId = PriceFileItemTypeEnum.Group;
        // values.priceFileItemCode = '';
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.priceFileService.addPriceFileItem(values).subscribe({
              next: (res) => {
                this.priceFileItemParentId = null;
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.priceFileService.updatePriceFileItem(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.priceFileService.deletePriceFileItem(encodeURIComponent(key)).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      }
    });
  }

  cellPrepared(e) {
    if (e.column.command === 'edit') {
      const addLink = e.cellElement.querySelector('.dx-link-add');

      if (addLink) {
        addLink.remove();
      }
    }
    // this.showCodeField = e.row.level === 0;
  }

  editorPreparing(e) {
    if (e.dataField === 'priceFileItemParentId' && e.row.data.ID === 1) {
      e.editorOptions.disabled = true;
      e.editorOptions.value = null;
    }

    // disable fields for sub-groups
    if (e.row?.data?.priceFileItemParentId) {
      if (e.dataField !== 'description' && e.dataField !== 'priceFileCode' && e.dataField !== 'isActive') {
        e.editorOptions.disabled = true;
      }
    }
  }

  initNewRow = (e) => {
    e.data.priceFileItemParentId = this.priceFileItemParentId;
    e.data.priceFileItemTypeId = PriceFileItemTypeEnum.Group;
    e.data.priceFileItemCode = '';
    e.data.includeAddendum = false;
    e.data.showSiteManagerOnOrder = this.priceFileItemParentId ? false : true;
    e.data.isInvoiceManuallyAuthorised = false;
    e.data.isHideInvoiceFromSupervisor = false;
    e.data.isInvoiceAutoAuthorised = false;
    e.data.isActive = true;
    e.data.showCostCentreOnOrder = this.priceFileItemParentId ? false : true;
    e.data.doNotAllowCosts = false;
    e.data.isHideCostsFromTracking = false;
    e.data.excludeInWipUntilCompletion = false;
  }

  addGroup = (e) => { // need to declare funtions in this manner so they still have access to the component context (i.e. 'this')
    this.priceFileItemParentId = e.row.key; // can add in other fields here if coming back from a modal
    e.component.addRow(e.row.key);
  }

  onEditStart(e) {
    this.priceFileItemParentId = e.data.priceFileItemParentId;
  }

  onEditCancelling() {
    this.priceFileItemParentId = null;
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();

    let toNode = visibleRows[e.toIndex].data;
    // console.log('toIndex ' + e.toIndex);
    // console.log('toNode: ' + toNode.description);

    this.loading = true;

    if (e.dropInsideItem) {
      this.subscriptions.push(
        this.priceFileService.movePriceFileItem(e.itemData.id, toNode.id, 1).subscribe({
          next: () => {
            this.loading = false;
            e.component.refresh();
          }, error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );

    } else {
      let targetIndex = e.toIndex;
      let newPriceFileItemParentId = toNode.priceFileItemParentId;
      let newOrderNumber = toNode.orderNumber;

      if (e.toIndex > e.fromIndex) {
        targetIndex++;
        if (visibleRows[targetIndex]) {
          toNode = visibleRows[targetIndex].data;
          if (e.itemData.priceFileItemParentId !== toNode.priceFileItemParentId) {
            // || (visibleRows[targetIndex + 1] && visibleRows[targetIndex + 1].priceFileItemParentId !== toNode.priceFileItemParentId)) {
            toNode = visibleRows[targetIndex].data;
            newPriceFileItemParentId = toNode.priceFileItemParentId;
            newOrderNumber = toNode.orderNumber;
          }

        }
      }

      this.subscriptions.push(
        this.priceFileService.movePriceFileItem(e.itemData.id, newPriceFileItemParentId, newOrderNumber).subscribe({
          next: () => {
            this.loading = false;
            this.treeList.instance.refresh();
          }, error: (err) => {
            this.loading = false;
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  toggleHelp() {
    this.helpPopupVisible = true;
  }

  isAddIconVisible(e) {
    return !e.row.isEditing && !e.row.data.priceFileItemParentId;
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift(
      {
        location: 'before',
        widget: 'dxButton',
        options: {
          width: 190,
          type: 'default',
          text: 'Purchase Order Footers',
          onClick: this.openOrderFooters.bind(this)
        }
      },
      {
        location: 'after',
        locateInMenu: 'auto',
        widget: 'dxCheckBox',
        options: {
          text: 'Show Inactive',
          value: false,
          rtlEnabled: true,
          onValueChanged: this.showInactiveChanged.bind(this)
        }
      });
  }

  openOrderFooters() {
    this.showCostCentreFooters = true;
  }

  closeCostCentreFooters() {
    this.showCostCentreFooters = false;
    this.getData(false);
  }

  calculateFilterExpression(filterValue, selectedFilterOperation, target) {
    if (target === 'search' && typeof (filterValue) === 'string') {
      return [(this as any).dataField, 'contains', filterValue];
    }
    return function (data) {
      return ([]).indexOf(filterValue) !== -1;
    };
  }

  cellTemplate(container, options) {
    const noBreakSpace = '\u00A0',
      text = (options.value || []).map(element => {
        return options.column.lookup.calculateCellValue(element);
      }).join(', ');
    container.textContent = text || noBreakSpace;
    container.title = text;
  }

  showInactiveChanged() {
    this.showInactive = !this.showInactive;
    this.setUpDataSource();
  }

  calculateHideInvoiceFromSupervisorColumnName(){
    return "Hide Invoices from " + this.calculateSiteManagerTitle()
  }

  calculateSiteManagerTitle() {
    return this.companyService.companyRoles?.find(i => i.roleId === RoleTypeEnum.SiteManager)?.companyRoleDescription;
  }

  help(dataField: string) {
    const modalRef = this.modalService.open(HelpModalComponent,
      { windowClass: 'modal-lg', scrollable: true });
    modalRef.componentInstance.dataField = dataField;
    modalRef.componentInstance.wipReport = true;

    modalRef.result.then(() => { });
  }
}
