import { forkJoin, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { GlobalService } from '../global.service';
import { Company } from '../../dtos/company';
import { CompanyConfiguration } from '../../dtos/company-configuration';
import { HttpService } from '../http.service';
import { environment } from '../../../environments/environment';
import { AuthApiService } from './auth-api.service';
import { CompanyActivity } from '../../dtos/company-activity';
import { CompanyRole } from '../../dtos/companyRole';

@Injectable()
export class CompanyService {

  authCompanies: Company[];
  globalGSTRate: number;
  activities: CompanyActivity[] = [];
  currentCompanyForActivitiesId: string;
  companyRolesCompany: string;
  companyRoles: CompanyRole[];

  constructor(
    private http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService,
    private authApi: AuthApiService
  ) { }

  setCurrentCompanies(comps: Company[]) {
    this.authCompanies = comps;
  }

  getCurrentCompanies() {
    return this.authCompanies;
  }

  getCompanies() {
    return this.http.get<Company[]>(environment.apiUrl + '/companies', this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCompaniesByUserEmail(email: string) {
    return this.http.get<Company[]>(environment.apiUrl + '/email-addresses/' + email + '/authorised-companies',
      this.httpService.getHttpOptions());
  }

  getCompany(id) {
    return this.http.get<Company>(environment.apiUrl + '/companies/' + id, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  // updateComp(company: Company) {
  //   const url = environment.apiUrl + '/companies/' + company.id;
  //   return this.http.patch(url, JSON.stringify(company), this.httpService.getHttpOptions());
  // }

  // addComp(company: Company) {
  //   const url = environment.apiUrl + '/companies';
  //   return this.http.post(url, JSON.stringify(company), this.httpService.getHttpOptions());
  // }

  getCompanyActivities(activeOnly: boolean = true) {
    if (this.activities && this.activities.length && this.currentCompanyForActivitiesId === this.globalService.getCurrentCompanyId()) {
      return of(this.activities);
    } else {
      const url = environment.apiUrl + '/activities?activeOnly=' + activeOnly;
      return this.http.get<CompanyActivity[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.activities = res;
          this.currentCompanyForActivitiesId = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getCompanyConfigs() {
    return this.http.get<CompanyConfiguration[]>(environment.apiUrl + '/company-configurations',
      this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.globalService.companyConfigurations = res;
        }), catchError(this.handleError));
  }

  addCompanyConfigs(updateData: any) {
    return this.http.post<CompanyConfiguration>(environment.apiUrl + '/company/configuration', JSON.stringify(updateData),
      this.httpService.getHttpOptions()).pipe(catchError(this.handleError));


  }

  updateCompanyConfigs(configId: number, updateData: any) {
    return this.http.patch<CompanyConfiguration>(environment.apiUrl + '/company-configuration/' + configId, JSON.stringify(updateData),
      this.httpService.getHttpOptions()).pipe(catchError(this.handleError));
  }

  getCompanyConfigurations(): Observable<CompanyConfiguration[]> {
    return forkJoin(
      [
        this.getCompanyConfigs(),
        this.authApi.getAreaPermissions()
      ]
    )
      .pipe(map(
        ([configs]) => {
          return configs;
        }, (err) => {
          return this.globalService.returnError(err);
        }
      ));
  }

  getCurrentGST(): Observable<number> {
    if (this.globalGSTRate) {
      return of(this.globalGSTRate);
    } else {
      return this.http.get<number>(environment.apiUrl + '/gst/current', this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.globalGSTRate = res;
        }),
        catchError(this.handleError));
    }
  }

  getCompanyRoles(useCache: boolean): Observable<CompanyRole[]> {
    if (useCache && this.companyRolesCompany === this.globalService.getCurrentCompanyId()
      && this.companyRoles && this.companyRoles.length) {
      return of(this.companyRoles);
    } else {
      const url = environment.apiUrl + '/company-roles';
      return this.http.get<CompanyRole[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.companyRoles = res; this.companyRolesCompany = this.globalService.getCurrentCompanyId();
        }), catchError(this.handleError));
    }
  }

  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
