import { CostCentreApprover } from './../../dtos/cost-centre-approver';
import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../global.service';
import { Phase } from '../../dtos/phase';
import { UnitOfMeasure } from '../../dtos/unitOfMeasure';
import { QtySizeControl } from '../../dtos/qty-size-control';
import { District } from '../../dtos/district';
import { Catalogue } from '../../dtos/catalogue';
import { CostCentreFooter } from '../../dtos/cost-centre-footer';
import { CallUpDocsType } from '../../dtos/call-up-docs-type';
import { CostCentreDocsType } from '../../dtos/cost-centre-docs-type';
import { environment } from '../../../environments/environment';
import { HttpService } from '../http.service';
import { RecipePriceFileItem } from '../../dtos/recipe-pricefile-item';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  phases: Phase[];
  cachCompanyPhases: string;

  unitOfMeasures: UnitOfMeasure[];
  cachCompanyUnitOfMeasures: string;

  qtySizeControls: QtySizeControl[];
  cachCompanyQtySizeControls: string;

  catalogues: Catalogue[];

  districts: District[];
  currentDistrict: District;
  cachCompanyDistricts: string;
  costCentreFooters: CostCentreFooter[];
  cachCompanyCostCentreFooters: string;
  cachCompanyCallUpDocsTypes: string;
  callUpDocsTypes: CallUpDocsType[];
  cachCompanyCostCentreCallUpDocs: string;
  costCentreCallUpDocs: CostCentreDocsType[];
  cachCompanyCostCentreApprovers: string;
  costCentreApprovers: CostCentreApprover[];
  recipePriceFileItems: RecipePriceFileItem[];

  constructor(
    private _http: HttpClient,
    private httpService: HttpService,
    private globalService: GlobalService
  ) { }


  getCatalogues(): Observable<Catalogue[]> {
    const url = environment.apiUrl + '/catalogues';

    return this._http.get<Catalogue[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getDistricts(useCache: boolean = true): Observable<District[]> {
    const url = environment.apiUrl + '/districts';

    if (useCache && this.districts && this.districts.length && this.cachCompanyDistricts === this.globalService.getCurrentCompanyId()) {
      return of(this.districts);
    } else {
      return this._http.get<District[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.districts = res; this.cachCompanyDistricts = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  addDistrict(dataRecord: any): Observable<District> {
    const url = environment.apiUrl + '/districts';
    return this._http.post<District>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateDistrict(id: string, itm: any): Observable<District> {
    const url = environment.apiUrl + '/districts/' + id;
    return this._http.patch<District>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteDistrict(id: string) {
    const url = environment.apiUrl + '/districts/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }

  moveDistrict(id: number, parentId: number, orderNo: number) {
    let url = environment.apiUrl + '/districts/move/' + id;
    if (parent) {
      url += '?districtParentId=' + parentId + '&orderNumber=' + orderNo;
    } else {
      url += '?&orderNumber=' + orderNo;
    }

    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }

  movePhase(id: number, orderNo: number) {
    const url = environment.apiUrl + '/phases/move/' + id + '?&orderNo=' + orderNo;

    return this._http.patch(url, '', this.httpService.getHttpOptions());
  }

  getPhases(useCache: boolean = true): Observable<Phase[]> {
    const url = environment.apiUrl + '/phases';

    if (useCache && this.phases && this.phases.length && this.cachCompanyPhases === this.globalService.getCurrentCompanyId()) {
      return of(this.phases);
    } else {
      return this._http.get<Phase[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.phases = res; this.cachCompanyPhases = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  addPhase(dataRecord: any): Observable<Phase> {
    const url = environment.apiUrl + '/phases';
    return this._http.post<Phase>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updatePhase(id: string, itm: any) {
    const url = environment.apiUrl + '/phases/' + id;
    return this._http.patch(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deletePhase(id: string) {
    const url = environment.apiUrl + '/phases/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }



  getUnitOfMeasures(useCache: boolean = true): Observable<UnitOfMeasure[]> {
    const url = environment.apiUrl + '/company-units';

    if (useCache && this.unitOfMeasures && this.unitOfMeasures.length
      && this.cachCompanyUnitOfMeasures === this.globalService.getCurrentCompanyId()) {
      return of(this.unitOfMeasures);
    } else {
      return this._http.get<UnitOfMeasure[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.unitOfMeasures = res; this.cachCompanyUnitOfMeasures = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  addUnitOfMeasure(dataRecord: any): Observable<UnitOfMeasure> {
    const url = environment.apiUrl + '/company-units';
    return this._http.post<UnitOfMeasure>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateUnitOfMeasure(id: string, itm: any): Observable<UnitOfMeasure> {
    const url = environment.apiUrl + '/company-units/' + id;
    return this._http.patch<UnitOfMeasure>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteUnitOfMeasure(id: string) {
    const url = environment.apiUrl + '/company-units/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }


  // Qty Size Control
  getQtySizeControls(useCache: boolean = true): Observable<QtySizeControl[]> {
    const url = environment.apiUrl + '/qty-size-control';

    if (useCache && this.qtySizeControls && this.qtySizeControls.length
      && this.cachCompanyQtySizeControls === this.globalService.getCurrentCompanyId()) {
      return of(this.qtySizeControls);
    } else {
      return this._http.get<QtySizeControl[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.qtySizeControls = res; this.cachCompanyQtySizeControls = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  addQtySizeControl(dataRecord: any): Observable<QtySizeControl> {
    const url = environment.apiUrl + '/qty-size-control';
    return this._http.post<QtySizeControl>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateQtySizeControl(id: string, itm: any): Observable<QtySizeControl> {
    const url = environment.apiUrl + '/qty-size-control/' + id;
    return this._http.patch<QtySizeControl>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteQtySizeControl(id: string) {
    const url = environment.apiUrl + '/qty-size-control/' + id;
    return this._http.delete(url, this.httpService.getHttpOptions());
  }




  getCostCentreFooters(useCache: boolean): Observable<CostCentreFooter[]> {
    if (useCache && this.costCentreFooters && this.costCentreFooters.length
      && this.cachCompanyCostCentreFooters === this.globalService.getCurrentCompanyId()) {
      return of(this.costCentreFooters);
    } else {
      return this._http.get<CostCentreFooter[]>(environment.apiUrl +
        '/cost-centre-footers', this.httpService.getHttpOptions()).pipe(
          tap(res => {
            this.costCentreFooters = res; this.cachCompanyCostCentreFooters = this.globalService.getCurrentCompanyId();
          }),
          catchError(this.handleError));
    }
  }

  addCostCentreFooter(dataRecord: any): Observable<CostCentreFooter> {
    const url = environment.apiUrl + '/cost-centre-footers';
    return this._http.post<CostCentreFooter>(url, JSON.stringify(dataRecord), this.httpService.getHttpOptions());
  }

  updateCostCentreFooter(id: string, itm: any): Observable<CostCentreFooter> {
    const url = environment.apiUrl + '/cost-centre-footers/' + id;
    return this._http.patch<CostCentreFooter>(url, JSON.stringify(itm), this.httpService.getHttpOptions());
  }

  deleteCostCentreFooter(id: string): Observable<CostCentreFooter> {
    const url = environment.apiUrl + '/cost-centre-footers/' + id;
    return this._http.delete<CostCentreFooter>(url, this.httpService.getHttpOptions());
  }


  getCallUpDocsTypes(useCache: boolean): Observable<CallUpDocsType[]> {
    if (useCache && this.callUpDocsTypes && this.callUpDocsTypes.length
      && this.cachCompanyCallUpDocsTypes === this.globalService.getCurrentCompanyId()) {
      return of(this.costCentreFooters);
    } else {
      const url = environment.apiUrl + '/call-up-docs-types/';
      return this._http.get<CallUpDocsType[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.callUpDocsTypes = res;
          this.cachCompanyCallUpDocsTypes = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }


  getCostCentreCallUpDocs(useCache: boolean): Observable<CostCentreDocsType[]> {
    if (useCache && this.costCentreCallUpDocs && this.costCentreCallUpDocs.length
      && this.cachCompanyCostCentreCallUpDocs === this.globalService.getCurrentCompanyId()) {
      return of(this.costCentreCallUpDocs);
    } else {
      const url = environment.apiUrl + '/cost-centre-docs-types/';
      return this._http.get<CostCentreDocsType[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.costCentreCallUpDocs = res;
          this.cachCompanyCostCentreCallUpDocs = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }


  getCostCentreApprovers(useCache: boolean): Observable<CostCentreApprover[]> {
    if (useCache && this.costCentreCallUpDocs && this.costCentreCallUpDocs.length
      && this.cachCompanyCostCentreApprovers === this.globalService.getCurrentCompanyId()) {
      return of(this.costCentreApprovers);
    } else {
      const url = environment.apiUrl + '/cost-centre-approvers/';
      return this._http.get<CostCentreApprover[]>(url, this.httpService.getHttpOptions()).pipe(
        tap(res => {
          this.costCentreApprovers = res;
          this.cachCompanyCostCentreApprovers = this.globalService.getCurrentCompanyId();
        }),
        catchError(this.handleError));
    }
  }

  getCentralCompanyDistricts(): Observable<District[]> {
    const url = environment.apiUrl + '/districts/central-company';

    return this._http.get<District[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCentralCompanyPhases(): Observable<Phase[]> {
    const url = environment.apiUrl + '/phases/central-company';

    return this._http.get<Phase[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCentralCompanyUnits(): Observable<UnitOfMeasure[]> {
    const url = environment.apiUrl + '/company-units/central-company';

    return this._http.get<UnitOfMeasure[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }

  getCentralCompanyQtySizeControls(): Observable<QtySizeControl[]> {
    const url = environment.apiUrl + '/qty-size-control/central-company';

    return this._http.get<QtySizeControl[]>(url, this.httpService.getHttpOptions()).pipe(
      catchError(this.handleError));
  }


  private handleError(err: HttpErrorResponse) {
    console.log(JSON.stringify(err));
    return observableThrowError(err);
  }
}
