import { CompanyService } from './../services/felixApi/company.service';
import { AuthService } from './../services/auth.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EstimatingService } from '../services/felixApi/estimating.service';
import { DxTreeListComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { RecipeTypeEnum } from '../dtos/recipe-type.enum';
import { Subscription } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { Recipe } from '../dtos/recipe';
import { GlobalService } from '../services/global.service';
import { MarginTypeEnum } from '../dtos/margin-type.enum';
import { PriceFileService } from '../services/felixApi/price-file.service';
import { PriceFileItemTypeEnum } from '../dtos/price-file-item-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecipeSearchComponent } from './recipe-search/recipe-search.component';
import { MaintenanceService } from '../services/felixApi/maintenance.service';
import { GlobalVariablesService } from '../services/global-variables.service';
import { RecipeToolboxComponent } from './recipe-toolbox/recipe-toolbox.component';
import { formatDate } from 'devextreme/localization';
import { PriceFileItemVendorRate } from '../dtos/price-file-item-vendor-rate';
import { ConfigurationEnum } from '../dtos/configuration-enum';

@Component({
  selector: 'js-recipe-classes',
  templateUrl: './recipe-classes.component.html',
  styleUrls: ['./recipe-classes.component.scss']
})
export class RecipeClassesComponent implements OnInit, OnDestroy {

  @ViewChild('treeList') treeList: DxTreeListComponent;

  treeGrid: DxTreeListComponent;
  selectedRowKeys: any[] = [];
  treeListDataSource: any = {};
  recipeTypeEnum = RecipeTypeEnum;
  loading = true;
  subscriptions: Subscription[] = [];
  helpPopupVisible = false;
  recipeClasses: Recipe[];
  updateOptionLists = false;
  marginTypeEnum = MarginTypeEnum;
  priceFileItemTypeEnum = PriceFileItemTypeEnum;
  costsIncEstimatingExtra = true;
  recipesAdmin = false;
  recipesWrite = false;
  refreshRecipeComponent = true;
  globalSearchText: string;
  centralCompanyClasses: Recipe[];
  editType = 'row';

  constructor(public estimatingService: EstimatingService,
    private priceFileService: PriceFileService,
    private authService: AuthService,
    private notiService: NotificationService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private maintenanceService: MaintenanceService,
    private compService: CompanyService,
    public globalVariablesService: GlobalVariablesService) {
    this.onReorder = this.onReorder.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onIncEstimatingExtraChanged = this.onIncEstimatingExtraChanged.bind(this);
  }

  ngOnInit() {
    // set default date
    this.estimatingService.costingDate = new Date();
    this.costsIncEstimatingExtra = this.priceFileService.costsIncEstimatingExtra;
    this.globalVariablesService.classesLoadingSpinnerVisible = false; // ensure the spinner is off

    this.estimatingService.currentCostingDateString = formatDate(this.estimatingService.costingDate, 'yyyy-MM-dd');

    this.getCompanyConfigurations();
  }

  getCompanyConfigurations() {
    // this will run on refesh twice - once without the user Id set so we ignore
    if (this.authService.getUserId()) {
      this.subscriptions.push(
        this.compService.getCompanyConfigurations().subscribe({
          next: (configs) => {
            this.globalService.companyConfigurations = configs;

            if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Recipes') === 'Write') {
              this.recipesWrite = true;
            }

            if (this.authService.isAdminOrSuper() || this.authService.getSelectionsPermissions('Recipes') === 'Admin') {
              this.recipesWrite = true;
              this.recipesAdmin = true;
            }
            this.getCentralCompanyClasses();
            this.refreshRecipes(false);
          },
          error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    });
    this.clearCacheData();
  }

  refreshRecipes(useCache: boolean) {
    this.clearCacheData();
    this.getRecipeData(useCache);
  }

  clearCacheData() {
    this.treeListDataSource = null;
    this.recipeClasses = [];
    this.loading = true;
    this.maintenanceService.currentDistrict = null; // ensure recipe lines are recosted
    this.priceFileService.priceFileItemGroups = [];
    this.priceFileService.priceFileItems = [];
    this.priceFileService.recipeAndItems = [];
    this.priceFileService.priceFileItemVendorRates = [];
    this.estimatingService.recipeGroups = [];
    this.estimatingService.globalRecipeSearchString = '';
    this.globalSearchText = '';
    this.selectedRowKeys = [];
  }

  getRecipeData(useCache: boolean) {
    // read all priceFileItemGroups
    this.subscriptions.push(
      this.estimatingService.getRecipeData(useCache).subscribe({
        next: () => {
          this.getAllPriceFileItems(useCache);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  getAllPriceFileItems(useCache: boolean) {
    // read all priceFileItemGroups
    this.subscriptions.push(
      this.priceFileService.getPriceFileItems(useCache).subscribe({
        next: () => {
          this.getAllPriceFileItemVendorRates(useCache);
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  getAllPriceFileItemVendorRates(useCache: boolean) {
    // read all priceFileVendors
    this.subscriptions.push(
      this.priceFileService.getPriceFileItemVendorRates(useCache).subscribe({
        next: () => {
          // sort it - code (for lookups) then dates descending so we can get the first (when we are getting the rate)
          this.priceFileService.priceFileItemVendorRates
            .sort(this.globalService.sortBy('priceFileCode', this.globalService.sortBy('effectiveDate', false, false)));

          const filteredItems = this.priceFileService.priceFileItemVendorRates
            .filter(i => i.isActive && i.effectiveDate && i.preferredVendorId === i.priceFileVendorId && i.effectiveDate.toString().slice(0, 10) <= this.estimatingService.currentCostingDateString);

          // use map for faster lookup
          this.priceFileService.itemsForRecipesAtDate = new Map<number, PriceFileItemVendorRate[]>();

          filteredItems.forEach(item => {
            if (!this.priceFileService.itemsForRecipesAtDate.has(item.priceFileItemId)) {
              // get the items for all price books
              const items = filteredItems.filter(i => i.priceFileItemId === item.priceFileItemId);
              if (items && items.length > 0) {
                this.priceFileService.itemsForRecipesAtDate.set(item.priceFileItemId, items);
              }
            }
          });

          this.setUpDataSource();
        },
        error: (err) => {
          this.notiService.notify(err);
          this.loading = false;
        }
      })
    );
  }

  setUpDataSource() {
    this.loading = false;
    this.treeListDataSource = new DataSource({
      key: 'id',
      load: async () => {
        return new Promise((resolve, reject) =>
          this.subscriptions = this.subscriptions.concat(
            this.estimatingService.getRecipeGroups().subscribe({
              next: (res) => {
                if (!this.recipesAdmin) {
                  res = res.filter(i => !i.isAdminOnly);
                }
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      insert: async (values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.estimatingService.addRecipe(values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      update: async (key, values) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.estimatingService.updateRecipe(encodeURIComponent(key), values).subscribe({
              next: (res) => {
                return resolve(res);
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      },
      remove: async (key) => {
        return new Promise((resolve, reject) =>
          this.subscriptions.push(
            this.estimatingService.deleteRecipe(encodeURIComponent(key), false).subscribe({
              next: () => {
                return resolve();
              }, error: (err) => {
                return reject(this.globalService.returnError(err));
              }
            }))
        );
      }
    });
  }

  cellPrepared(e) {
    if (e.column.command === 'edit') {
      const addLink = e.cellElement.querySelector('.dx-link-add');

      if (addLink) {
        addLink.remove();
      }
    }
  }

  editorPreparing(e) {
    if (e.dataField === 'recipeParentId' && e.row.data.ID === 1) {
      e.editorOptions.disabled = true;
      e.editorOptions.value = null;
    }
  }

  initNewRow = (e) => {
      e.data.recipeTypeId = RecipeTypeEnum.Group;
      e.data.isAdminOnly = false;
  }

  addClass = (e) => {
    e.component.addRow(e.row.key);
  }

  openToolBox() {
    // this.showToolbox = true;
    const modalRef = this.modalService.open(RecipeToolboxComponent);

    modalRef.result.then(() => {
      this.refreshRecipes(true);
    }, () => {
    });
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();

    let toNode = visibleRows[e.toIndex].data;

    if (e.dropInsideItem) {
      this.subscriptions.push(
        this.estimatingService.moveRecipe(e.itemData.id, toNode.id, 1).subscribe({
          next: () => {
            this.estimatingService.recipeGroups = [];
            e.component.refresh();
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );

    } else {
      let targetIndex = e.toIndex;
      let newRecipeParentId = toNode.recipeParentId;
      let newOrderNumber = toNode.orderNumber;

      if (e.toIndex > e.fromIndex) {
        targetIndex++;
        if (visibleRows[targetIndex]) {
          toNode = visibleRows[targetIndex].data;
          if (e.itemData.recipeParentId !== toNode.recipeParentId) {
            toNode = visibleRows[targetIndex].data;
            newRecipeParentId = toNode.recipeParentId;
            newOrderNumber = toNode.orderNumber;
          }

        }
      }

      this.subscriptions.push(
        this.estimatingService.moveRecipe(e.itemData.id, newRecipeParentId, newOrderNumber).subscribe({
          next: () => {
            this.estimatingService.recipeGroups = [];
            e.component.refresh();
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  toggleHelp() {
    this.helpPopupVisible = true;
  }

  onCostingDateChanged(e) {
    // recalc rates
    if (e.value !== this.estimatingService.costingDate) {
      this.estimatingService.costingDate = e.value;

      this.estimatingService.currentCostingDateString = formatDate(this.estimatingService.costingDate, 'yyyy-MM-dd');

      this.selectedRowKeys = [];
      this.maintenanceService.currentDistrict = null; // ensure recipe lines are recosted

      // force recost
      this.estimatingService.recipes.forEach(recipe => {
        recipe.rate = null;
      });
      this.refreshRecipes(true);
    }
  }

  onIncEstimatingExtraChanged(e) {
    this.costsIncEstimatingExtra = e.value;
    this.priceFileService.costsIncEstimatingExtra = e.value;
    this.estimatingService.recipes = [];
    this.loading = true;
    this.getRecipeData(true);
  }

  globalSearchChanged(data: any) {
    this.estimatingService.globalRecipeSearchString = data.value;
    this.treeList?.instance.refresh();
    this.selectedRowKeys = [];
  }

  onAdd(e) {
    if (!e.dropInsideItem) {
      this.notiService.showInfo('Must select a class to "drop" onto');
    } else {
      const visibleRows = e.component.getVisibleRows();
      let toNode = visibleRows[e.toIndex].data;
      this.globalVariablesService.classesLoadingSpinnerVisible = true;
      // console.log(e);

      this.subscriptions.push(
        this.estimatingService.moveRecipe(e.itemData.id, toNode.id, 1).subscribe({
          next: () => {
            this.globalVariablesService.classesLoadingSpinnerVisible = false;
            const thisRecipe = this.estimatingService.recipes.find(i => i.id === e.itemData.id);
            thisRecipe.recipeParentId = toNode.id;
            this.selectedRowKeys[0] = toNode.id;
          }, error: (err) => {
            this.notiService.notify(err);
            this.globalVariablesService.classesLoadingSpinnerVisible = false;
          }
        })
      );
    }
  }

  advancedSearch() {
    const modalRef = this.modalService.open(RecipeSearchComponent, { windowClass: 'modal-1000' });

    modalRef.result.then(selectedRecipe => {
      if (selectedRecipe.recipeTypeId === this.recipeTypeEnum.Group) {
        this.selectedRowKeys[0] = selectedRecipe.id;
        this.treeList.instance.navigateToRow(selectedRecipe.id);
      } else {
        // recipe so find parent
        this.refreshFromFind(selectedRecipe);
      }
    }, () => {
    });
  }

  refreshFromFind(selectedRecipe) {
    const parentRecipe = this.estimatingService.recipeGroups.find(i => i.id === selectedRecipe.recipeParentId);
    this.estimatingService.globalRecipeSearchString = selectedRecipe.recipeCode;
    if (this.selectedRowKeys[0] !== parentRecipe.id) {
      this.selectedRowKeys[0] = parentRecipe.id;
      this.treeList.instance.navigateToRow(parentRecipe.id);
    } else {
      this.refreshRecipeComponent = !this.refreshRecipeComponent;
    }
  }

  onDragChange(e) {
    const visibleRows = e.component.getVisibleRows();
    const sourceNode = e.component.getNodeByKey(e.itemData.id);
    if (sourceNode) {
      let targetNode = visibleRows[e.toIndex]?.node;

      while (targetNode && targetNode.data) {
        if (targetNode.data.id === sourceNode.data.id) {
          e.cancel = true;
          break;
        }
        targetNode = targetNode.parent;
      }
    }
  }

  getCentralCompanyClasses() {
    if (this.globalService.getCompanyConfigValue(ConfigurationEnum.CentralCompanyActive)) {
      this.subscriptions.push(
        this.estimatingService.getCentralCompanyClasses(false).subscribe({
          next: (res) => {
            this.centralCompanyClasses = res;
            if (this.recipesAdmin) {
              this.editType = 'form';
            }
          }, error: (err) => {
            this.notiService.notify(err);
          }
        })
      );
    }
  }

  setCentralCompanyId(rowData, value) {
    rowData.centralCompanyId = value;
    if (value) {
      rowData.isAdminOnly = true;
    }
  }

  onRowPrepared(e) {
    if (e.rowType === 'data' && e.data.centralCompanyId) {
      e.rowElement.style.fontWeight = 'bold';
    }
  }
}
